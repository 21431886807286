<script>
	import { getContext, onMount } from 'svelte';
	import { debounce } from '@Utils/Utils.js';
	import DraggableComponent from '@Components/DraggableComponent.svelte';
	import ResizeableComponent from '@Components/ResizeableComponent.svelte';
	import AsyncHoleRenderer from '@Renderer/AsyncHoleRenderer.js';
	import RenderSettings from './RenderSettings.svelte';
	import { getRenderProperties, setRenderProperties } from '@Utils/Utils.js';

	let showAdvanced = false;
	let showIcons = localStorage.getItem('showShadowsOnPreview') === 'true';
	let showShadows = localStorage.getItem('showIconsOnPreview') === 'true';
	let renderProperties = getRenderProperties({
		width: import.meta.env.VITE_RENDER_WIDTH / 3,
		height: import.meta.env.VITE_RENDER_HEIGHT / 3,
		backgroundColor: 'white',
		includeIcons: showIcons,
		shadowOpacity: showShadows ? 0.25 : 0
	});
	$: showShadows, updateShadow();
	$: showIcons, toggleShowIcons();
	$: renderProperties;
	const { getEditorCanvas } = getContext('editor');
	const editorCanvas = getEditorCanvas();
	let renderElement;
	let timer;
	let updates = [];
	let isVisible = false;
	let holeRenderer = new AsyncHoleRenderer();
	let selectedData;
	let focusedData = null;

	function update() {}
	function toggleShowIcons() {
		localStorage.setItem('showIconsOnPreview', showIcons);
		renderProperties.includeIcons = showIcons;
		updateImageProperties();
	}
	editorCanvas.on('featureRemoved', (e) => {
		updateImage(e, 'delete');
	});
	editorCanvas.on('featureUpdated', (e) => {
		if (e.get('objectType') === 'frame') {
			renderImage();
		} else {
			updateImage(e, 'update');
		}
	});
	function updateShadow() {
		localStorage.setItem('showShadowsOnPreview', showShadows);
		renderProperties.shadowOpacity = showShadows ? 0.25 : 0;
		renderImage(true);
	}
	editorCanvas.on('featureAdded', (e) => {
		updateImage(e, 'add');
	});
	editorCanvas.on('frameFocused', function (data) {
		focusedData = data;
		isVisible = focusedData !== null;
	});
	async function updateImageProperties() {
		if (!isVisible) {
			return;
		}
		clearTimeout(timer);
		timer = setTimeout(async () => {
			if (selectedData) {
				console.time('updateProperties');
				try {
					let result = await holeRenderer.updateProperties(renderProperties);
					let context = renderElement.getContext('2d');
					renderElement.width = result.image.width;
					renderElement.height = result.image.height;
					context.drawImage(result.image, 0, 0);
					renderElement.style.opacity = 1;
				} catch (e) {
					console.error(e);
				}
				console.timeEnd('updateProperties');
			}
		}, 200);
	}
	async function updateImage(feature, action) {
		if (!isVisible) {
			return;
		}
		let intersecting = editorCanvas.getFeaturesInExtent(feature.getGeometry().getExtent());
		intersecting = intersecting.filter((f) => {
			if (f.get('uid') === feature.get('uid')) {
				return false;
			}
			if (f.get('objectType') === 'frame') {
				return false;
			}
			if (f.get('elementName') === 'focus') {
				return false;
			}
			if (f.get('hidden') === true) {
				return false;
			}
			if (f.getGeometry().getType() !== 'Polygon') {
				return false;
			}
			if (f.get('holeId') && f.get('holeId') !== focusedData.feature.get('holeId')) {
				return false;
			}
			return true;
		});
		updates = updates.filter((u) => u.uid !== feature.get('uid'));
		updates.push({
			uid: feature.get('uid'),
			feature: editorCanvas.convertFeatureToGeoJSON(feature),
			action: action
		});
		for (let i of intersecting) {
			updates.push({
				uid: i.get('uid'),
				feature: editorCanvas.convertFeatureToGeoJSON(i),
				action: 'update'
			});
		}
		clearTimeout(timer);
		timer = setTimeout(async () => {
			if (selectedData && updates.length > 0) {
				console.time('updateImage');
				let completed = [...updates];
				try {
					let result = await holeRenderer.update(updates, renderProperties);
					updates = updates.filter((u) => !completed.includes(u));
					let context = renderElement.getContext('2d');
					renderElement.width = result.image.width;
					renderElement.height = result.image.height;
					context.drawImage(result.image, 0, 0);
					renderElement.style.opacity = 1;
				} catch (e) {
					console.error(e);
				}
				console.timeEnd('updateImage');
			}
		}, 50);
	}
	async function renderImage(reset) {
		if (!isVisible) {
			return;
		}
		clearTimeout(timer);
		timer = setTimeout(async () => {
			if (selectedData) {
				let holeId = selectedData.feature.get('holeId');
				renderElement.style.opacity = 0.5;
				let data = editorCanvas.getRenderData(holeId);
				try {
					let result = await holeRenderer.render(data, renderProperties, reset);
					let context = renderElement.getContext('2d');
					renderElement.width = result.image.width;
					renderElement.height = result.image.height;
					context.drawImage(result.image, 0, 0);
					renderElement.style.opacity = 1;
				} catch (e) {
					console.error(e);
				}
			}
		}, 100);
	}
	onMount(() => {
		update();
	});
	const onFrameFocused = (data) => {
		selectedData = data;
		showAdvanced = import.meta.env.VITE_ADVANCED_TOOLING_ENABLED === 'true' && localStorage.getItem('showAdvancedOptions') === 'true';
		if (data) {
			renderElement.style.opacity = 1;
			renderImage();
		} else {
			const context = renderElement.getContext('2d');
			context.clearRect(0, 0, renderElement.width, renderElement.height);
			renderElement.style.opacity = 0;
		}
	};
	editorCanvas.on('frameFocused', onFrameFocused);
</script>

<DraggableComponent xAlignment="right" bind:visible={isVisible}>
	<div class="flex flex-col">
		<!--div class="flex flex-row w-full justify-between items-center toolbar">
			<span tooltip="Will be visible in the app regardless of what you choose here.">Show icons</span>
			<label class="switch">
				<input type="checkbox" checked={showIcons} on:change={(e) => (showIcons = e.target.checked)} />
				<span class="slider round"></span>
			</label>
		</div-->
		<div class="flex flex-row w-full justify-between items-center toolbar">
			<span tooltip="Turning on shadows might be slow depending on your computer.<br /><br /><strong>OBSERVE!</strong><br/>The shadows will always show in the final version<br /> regladless of what you choose here">Show shadows (slow)</span>
			<label class="switch">
				<input type="checkbox" checked={showShadows} on:change={(e) => (showShadows = e.target.checked)} />
				<span class="slider round"></span>
			</label>
		</div>
	</div>
	<ResizeableComponent xAlignment="none" aspectRatio="0.5">
		<canvas id="render" class="w-full h-full bg-black" style="pointer-events:none" bind:this={renderElement} />
	</ResizeableComponent>
	<!-- {#if showAdvanced}
		<RenderSettings bind:properties={renderProperties} disabled={false} />
	{/if} -->
</DraggableComponent>

<style lang="postcss">
	.toolbar {
		font-size: 14px;
		padding: 5px 10px;
	}
</style>
