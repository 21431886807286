import { Circle, Fill, Stroke, Style, Icon } from 'ol/style.js';
import { MultiPoint } from 'ol/geom';
import { getElementsArray } from '@Utils/Elements.js';
import { GeometryUtils, getIconUrl, getDirectionalIconList, getPlaceableIconList } from '@Utils/Utils.js';

let geometryCache = {};
let opacityFill = '30';
let opacityStroke = '80';
export function getGeometryCache(feature, type) {
	let id = feature.ol_uid + "-" + type;
	if (feature.get('clearCache')) {
		delete geometryCache[id];
		feature.unset('clearCache');
	}

	if (!geometryCache[id] || geometryCache[id].revision !== feature.revision_) {
		let geometry
		if (type === 'Polygon' || type === 'LineString') {
			if (feature.get('rounded')) {
				geometry = GeometryUtils.getCSplineGeometry(feature)
			} else {
				geometry = feature.getGeometry();
			}
		} else if (type == 'MultiPoint') {
			let type = feature.getGeometry().getType();
			if (type === 'Polygon') {
				geometry = new MultiPoint(feature.getGeometry().getCoordinates()[0]);
			} else if (type === 'LineString') {
				geometry = new MultiPoint(feature.getGeometry().getCoordinates());
			} else {
				geometry = feature.getGeometry();
			}
		}
		geometryCache[id] = {
			revision: feature.revision_,
			geometry: geometry
		}
	}
	return geometryCache[id].geometry;
}
function createIconStyle(element, selected, resolution, icon, directional, directionalIcon) {
	let styles = []
	if (directional) {
		styles.push(new Style({
			image: new Icon({
				anchor: [element.mapIconOffsetX ?? 0.5, element.mapIconOffsetY ?? 0.5],
				anchorXUnits: 'fraction',
				anchorYUnits: 'fraction',
				rotateWithView: true,
				src: getIconUrl(directionalIcon ?? "DirectionalChevronRounded"),
				opacity: selected ? 1 : 0.5
			})
		}))
	}
	let i = new Icon({
		anchor: [element.mapIconOffsetX ?? 0.5, element.mapIconOffsetY ?? 0.5],
		anchorXUnits: 'fraction',
		anchorYUnits: 'fraction',
		rotateWithView: true,
		src: getIconUrl(icon ?? element.icon),
		opacity: selected ? 1 : 0.5
	})
	if (directional) {
		i.noRotation = true
	}
	styles.push(
		new Style({
			image: i
		})
	)

	return styles
}
function createFocusStyle(element, selected, resolution) {
	let styles = [
		new Style({
			stroke: new Stroke({
				color: element.color,
				width: selected ? 3 : 2,
				lineDash: [0.5, 5]
			}),
			geometry: function (feature) {
				return getGeometryCache(feature, 'Polygon');
			}
		})
	];
	if (!selected) {
		return styles;
	}
	styles.push(
		new Style({
			geometry: function (feature) {
				return getGeometryCache(feature, 'MultiPoint');
			},
			image: new Circle({
				radius: 3,
				fill: new Fill({
					color: element.color + opacityFill
				}),
				stroke: new Stroke({
					color: 'rgba(255,255,255,0.5)',
					width: 2
				})
			})
		})
	);
	styles.push(
		new Style({
			stroke: new Stroke({
				color: 'rgba(255,255,255,0.5)',
				width: 1
			}),
			geometry: function (feature) {
				return feature.getGeometry();
			}
		})
	);
	return styles
}
function createPolygonStyle(element, selected, resolution) {
	let styles = []
	styles.push(new Style({
		fill: new Fill({
			color: element.color + opacityFill
		}),
		stroke: new Stroke({
			color: selected ? 'rgba(255,255,255,0)' : 'rgba(255,255,255, 0.5)',
			width: selected ? 0 : 1
		}),
		geometry: function (feature) {
			return getGeometryCache(feature, 'Polygon');
		}
	}))
	if (!selected) {
		return styles;
	}
	styles.push(
		new Style({
			geometry: function (feature) {
				return getGeometryCache(feature, 'MultiPoint');
			},
			image: new Circle({
				radius: 3,
				fill: new Fill({
					color: element.color + opacityFill
				}),
				stroke: new Stroke({
					color: 'rgba(255,255,255,0.5)',
					width: 2
				})
			})
		})
	);
	styles.push(
		new Style({
			stroke: new Stroke({
				color: 'rgba(255,255,255,0.5)',
				width: 1
			}),
			geometry: function (feature) {
				return feature.getGeometry();
			}
		})
	);
	return styles;
}
export function createElementStyles(selected, resolution) {
	let elementStyles = {};
	for (let e of getElementsArray()) {
		if (e.editStyle == 'icon') {
			let icons = getPlaceableIconList()
			let directionalIcons = getDirectionalIconList()
			for (let icon of icons) {
				elementStyles[e.name + "-" + icon] = createIconStyle(e, selected, resolution, icon)
				for (let directionalIcon of directionalIcons) {
					elementStyles[e.name + "-" + icon + "-directional-" + directionalIcon] = createIconStyle(e, selected, resolution, icon, true)
				}
			}
			elementStyles[e.name] = createIconStyle(e, selected, resolution)
		} else if (e.tooltype == 'Polygon') {
			if (e.name == 'focus') {
				elementStyles[e.name] = createFocusStyle(e, selected, resolution)
			} else {
				elementStyles[e.name] = createPolygonStyle(e, selected, resolution)
			}
		}
	}
	return elementStyles;
}