import TileImage from 'ol/source/TileImage.js';
import TileLayer from 'ol/layer/WebGLTile.js';
//import { Tile as TileLayer } from 'ol/layer.js';
import { getBottomLeft, getTopRight } from 'ol/extent.js';
import { toLonLat } from 'ol/proj.js';

export class AzureMapsTileLayer extends TileLayer {
    timer;
    constructor(options) {
        super(options);
        let _this = this;
        this.on('postrender', function (evt) {
            let extent = evt.frameState.extent;
            let zoom = evt.frameState.viewState.zoom;
            if (JSON.stringify(_this.extent) === JSON.stringify(extent) && _this.zoom === zoom) {
                return;
            }
            _this.extent = extent;
            _this.zoom = zoom;
            if (_this.timer) {
                clearTimeout(_this.timer);
            }
            _this.timer = setTimeout(() => {
                let source = _this.getSource();
                if (source instanceof AzureMapsTile) {
                    _this.getSource().updateAttributions(evt.frameState.extent, evt.frameState.viewState.zoom);
                }
            }, 100);
        });
    }
}
export class AzureMapsTile extends TileImage {
    constructor(options) {

        if (!options) {
            options = {};
        }
        if (!options.tileSize) {
            options.tileSize = 256;
        }
        if (!options.key) {
            throw new Error('Azure Maps subscription key is required');
        }
        options.crossOrigin = 'http://localhost:5173';
        super(options)
        this.key = options.key;
        this.setTileUrlFunction(function (coordinate) {
            return `https://us.atlas.microsoft.com/map/tile?api-version=2022-08-01&tilesetId=microsoft.imagery&zoom=${coordinate[0]}&x=${coordinate[1]}&y=${coordinate[2]}&tileSize=${options.tileSize}&subscription-key=${options.key}`;
        });

    }
    async getAttribution(key, options) {
        let url = `https://us.atlas.microsoft.com/map/attribution?tilesetId=microsoft.imagery&api-version=2022-08-01&subscription-key=${key}&zoom=${options.zoom}&bounds=${options.bounds}`;
        let requestOptions = {
            method: 'GET'
        };
        return await this.request(url, requestOptions);
    }
    async updateAttributions(extent, zoom) {
        let southwestCorner = toLonLat(getBottomLeft(extent))
        let northeastCorner = toLonLat(getTopRight(extent))

        let [res, attributionError] = await this.getAttribution(this.key, {
            zoom: parseInt(zoom, 10),
            bounds: `${southwestCorner[0]}, ${southwestCorner[1]}, ${northeastCorner[0]}, ${northeastCorner[1]}`
        });

        let attribution = '';
        if (attributionError) {
            console.error('Error fetching viewbox and attribution');
            attribution = `Microsoft © ${new Date().getFullYear()}`;
        } else {
            attribution = res.copyrights.join(',');
        }
        this.viewport = res;
        this.setAttributions(attribution);
    }
    async request(url, requestOptions) {
        let res = null;
        let err = null;
        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            res = await response.json();
        } catch (error) {
            err = error;
            if (error instanceof SyntaxError) {
                console.error('There was a SyntaxError', error);
            } else {
                console.error('There was an error', error);
            }
        }
        return [res, err];
    }
}