import TileImage from 'ol/source/TileImage.js';
import TileLayer from 'ol/layer/WebGLTile.js';
//import { Tile as TileLayer } from 'ol/layer.js';
import { get as getProjection, transformExtent } from 'ol/proj';
import { extentFromProjection } from 'ol/tilegrid';
export const GoogleMapTypes = ['satellite', 'roadmap', 'terrain'];

// https://developers.google.com/maps/documentation/tile/policies#map-tiles-api
// https://about.google/brand-resource-center/products-and-services/geo-guidelines/#required-attribution

export class GoogleMapTileLayer extends TileLayer {
	timer;
	constructor(options) {
		super(options);
		let _this = this;
		this.on('postrender', function (evt) {
			let extent = evt.frameState.extent;
			let zoom = evt.frameState.viewState.zoom;
			if (JSON.stringify(_this.extent) === JSON.stringify(extent) && _this.zoom === zoom) {
				return;
			}
			_this.extent = extent;
			_this.zoom = zoom;
			if (_this.timer) {
				clearTimeout(_this.timer);
			}
			_this.timer = setTimeout(() => {
				let source = _this.getSource();
				if (source instanceof GoogleMapTile) {
					_this.getSource().updateAttributions(evt.frameState.extent, evt.frameState.viewState.zoom);
				}
			}, 100);
		});
	}
}
export class GoogleMapTile extends TileImage {
	ok = false;
	mapType = 'satellite';
	language = 'en-US';
	region = 'US';
	constructor(options) {
		const hidpi = options.hidpi !== undefined ? options.hidpi : false;
		super({
			cacheSize: options.cacheSize,
			// crossOrigin: 'anonymous',
			// interpolate: options.interpolate,
			opaque: true,
			projection: getProjection('EPSG:3857'),
			//reprojectionErrorThreshold: options.reprojectionErrorThreshold,
			state: 'loading',
			// tileLoadFunction: options.tileLoadFunction,
			tilePixelRatio: hidpi ? 2 : 1,
			//wrapX: options.wrapX !== undefined ? options.wrapX : true,
			transition: options.transition
			//zDirection: options.zDirection,
		});
		this.hidpi = hidpi;
		if (options.mapType !== undefined && GoogleMapTypes.includes(options.mapType)) {
			this.mapType = options.mapType;
		}
		if (options.language !== undefined) {
			this.language = options.language;
		}
		if (options.region !== undefined) {
			this.region = options.region;
		}
		if (options.apiKey !== undefined) {
			this.apiKey = options.apiKey;
		}
		if (options.scale !== undefined) {
			this.scale = options.scale;
		}
		if (options.imageFormat !== undefined) {
			this.imageFormat = options.imageFormat;
		}
		if (options.layerTypes !== undefined) {
			this.layerTypes = options.layerTypes;
		}
		if (options.styles !== undefined) {
			this.styles = options.styles;
		}
		if (options.overlay !== undefined) {
			this.overlay = options.overlay;
		}
		this.setAttributions(`Google © ${new Date().getFullYear()} - <a href='https://cloud.google.com/maps-platform/terms/'>Terms of Service</a>`);
		let session = localStorage.getItem('google_tileSession');
		if (session) {
			session = JSON.parse(session);
			if (session.expiry * 1000 < Date.now()) {
				this.refresh();
			} else {
				console.log('loaded session from local storage');
				this.currenSession = session;
				this.updateTileFunction(session);
			}
		} else {
			this.refresh();
		}
	}
	async refresh() {
		if (!this.apiKey) {
			this.setState('error');
			return;
		}
		let [res, sessionError] = await this.getSession();

		if (sessionError) {
			this.setState('error');
		} else {
			this.currenSession = res;
			localStorage.setItem('google_tileSession', JSON.stringify(res));
			this.updateTileFunction(res);
			setTimeout(
				() => {
					this.refresh();
				},
				res.expiry * 1000 - Date.now()
			);
		}
	}
	async updateAttributions(extent, zoom) {
		const sourceProjection = this.getProjection();
		let viewBox = transformExtent(extent, sourceProjection, 'EPSG:4326');
		let [res, attributionError] = await this.getAttribution(this.currenSession.session, this.apiKey, {
			zoom: parseInt(zoom, 10),
			north: viewBox[3],
			south: viewBox[1],
			east: viewBox[2],
			west: viewBox[0]
		});
		let attribution = '';
		if (attributionError) {
			console.error('Error fetching viewbox and attribution');
			attribution = `Google © ${new Date().getFullYear()} - <a href='https://cloud.google.com/maps-platform/terms/'>Terms of Service</a>`;
		} else {
			attribution = res.copyright + " - <a href='https://cloud.google.com/maps-platform/terms/'>Terms of Service</a>";
		}
		this.viewport = res;
		this.setAttributions(attribution);
	}
	getViewBox() {
		const sourceProjection = this.getProjection();
		const extent = extentFromProjection(sourceProjection);
		return transformExtent(extent, sourceProjection, 'EPSG:4326');
	}
	updateTileFunction(sessionData) {
		let apiKey = this.apiKey;
		this.setTileUrlFunction(function (coordinate) {
			return `https://tile.googleapis.com/v1/2dtiles/${coordinate[0]}/${coordinate[1]}/${coordinate[2]}?session=${sessionData.session}&key=${apiKey}`;
		});
		this.setState('ready');
	}
	setMapType(mapType) {
		if (mapType !== undefined && GoogleMapTypes.includes(mapType) && mapType != this.mapType) {
			this.mapType = mapType;
			this.refresh();
		}
	}
	setLanguage(language) {
		if (language !== undefined && language != this.language) {
			this.language = language;
			this.refresh();
		}
	}
	setRegion(region) {
		if (region !== undefined && region != this.region) {
			this.region = region;
			this.refresh();
		}
	}
	setScale(scale) {
		if (scale !== undefined && scale != this.scale) {
			this.scale = scale;
			this.refresh();
		}
	}
	setImageFormat(imageFormat) {
		if (imageFormat !== undefined && imageFormat != this.imageFormat) {
			this.imageFormat = imageFormat;
			this.refresh();
		}
	}
	setLayerTypes(layerTypes) {
		if (layerTypes !== undefined && layerTypes != this.layerTypes) {
			this.layerTypes = layerTypes;
			this.refresh();
		}
	}
	setStyles(styles) {
		if (styles !== undefined && styles != this.styles) {
			this.styles = styles;
			this.refresh();
		}
	}
	setOverlay(overlay) {
		if (overlay !== undefined && overlay != this.overlay) {
			this.overlay = overlay;
			this.refresh();
		}
	}
	async getSession() {
		let url = 'https://tile.googleapis.com/v1/createSession?key=' + this.apiKey;
		let requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		};
		requestOptions.body = JSON.stringify({
			mapType: this.mapType,
			language: this.language,
			region: this.region,
			highDpi: this.hidpi,
			scale: this.scale,
			imageFormat: this.imageFormat,
			layerTypes: this.layerTypes,
			styles: this.styles,
			overlay: this.overlay
		});
		return await this.request(url, requestOptions);
	}
	async getAttribution(sessionKey, apiKey, options) {
		let url = `https://tile.googleapis.com/tile/v1/viewport?session=${sessionKey}&key=${apiKey}&zoom=${options.zoom}&north=${options.north}&south=${options.south}&east=${options.east}&west=${options.west}`;
		let requestOptions = {
			method: 'GET'
		};
		return await this.request(url, requestOptions);
	}
	async request(url, requestOptions) {
		let res = null;
		let err = null;
		try {
			const response = await fetch(url, requestOptions);
			if (!response.ok) {
				throw new Error(`${response.status} ${response.statusText}`);
			}
			res = await response.json();
		} catch (error) {
			err = error;
			if (error instanceof SyntaxError) {
				console.error('There was a SyntaxError', error);
			} else {
				console.error('There was an error', error);
			}
		}
		return [res, err];
	}
}
