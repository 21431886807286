import { Circle as CircleStyle, Fill, Stroke, Style, Icon, Text } from 'ol/style.js';
import { MultiPoint } from 'ol/geom.js';
import { VectorLayer } from './RendererLayer.js';
import { GeometryUtils, Utils, PointUtils, getIconUrl } from '@Utils/Utils.js';

let frameDirectionArrowUrl = getIconUrl('FrameDirectionArrow');

const style = new Style({
	geometry: function (feature) {
		const modifyGeometry = feature.get('modifyGeometry');
		return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
	},
	fill: new Fill({
		color: 'rgba(255, 255, 255, 0)'
	}),
	stroke: new Stroke({
		color: 'white',
		width: 2,
		lineDash: [0.1, 5]
	}),
	image: new CircleStyle({
		radius: 7,
		fill: new Fill({
			color: '#ffcc33'
		})
	}),
	zIndex: 999
});
const unMappedStyle = new Style({
	geometry: function (feature) {
		const modifyGeometry = feature.get('modifyGeometry');
		return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
	},
	fill: new Fill({
		color: 'rgba(225, 147, 147, 0.5)'
	}),
	stroke: new Stroke({
		color: '#E19393',
		width: 2,
		lineDash: [0.1, 5]
	}),
	image: new CircleStyle({
		radius: 7,
		fill: new Fill({
			color: '#ffcc33'
		})
	}),
	zIndex: 999
});
const unMappedSelectedStyle = new Style({
	geometry: function (feature) {
		const modifyGeometry = feature.get('modifyGeometry');
		return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
	},
	fill: new Fill({
		color: 'rgba(225, 111, 147, 0.5)'
	}),
	stroke: new Stroke({
		color: 'rgba(225, 111, 147, 1)',
		width: 2,
		lineDash: [0.1, 5]
	}),
	image: new CircleStyle({
		radius: 7,
		fill: new Fill({
			color: '#ffcc33'
		})
	}),
	zIndex: 999
});
const selectedStyle = new Style({
	geometry: function (feature) {
		const modifyGeometry = feature.get('modifyGeometry');
		return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
	},
	fill: new Fill({
		color: 'rgba(240, 210, 27, 0.2)'
	}),
	stroke: new Stroke({
		color: 'yellow',
		width: 2,
		lineDash: [0.1, 5]
	}),
	image: new CircleStyle({
		radius: 7,
		fill: new Fill({
			color: '#ffcc33'
		})
	}),
	zIndex: 999
});
const focusedStyle = new Style({
	geometry: function (feature) {
		const modifyGeometry = feature.get('modifyGeometry');
		return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
	},
	fill: new Fill({
		color: 'rgba(255, 255, 255, 0)'
	}),
	stroke: new Stroke({
		color: 'orange',
		width: 2,
		lineDash: [0.1, 5]
	}),
	image: new CircleStyle({
		radius: 7,
		fill: new Fill({
			color: '#ffcc33'
		})
	}),
	zIndex: 999
});
const labelStyle = new Style({
	text: new Text({
		font: '13px Calibri,sans-serif',
		fill: new Fill({
			color: '#000'
		}),
		stroke: new Stroke({
			color: '#fff',
			width: 4
		})
	})
});
function getStyle(feature) {
	if (feature.get('selected')) {
		if (!feature.get('holeId')) {
			return unMappedSelectedStyle;
		}
		return selectedStyle;
	} else if (feature.get('focused')) {
		return focusedStyle;
	} else {
		if (!feature.get('holeId')) {
			return unMappedStyle;
		}
		return style;
	}
}
export class FrameLayer extends VectorLayer {
	map;
	focusedHoleId;
	constructor(map, source) {
		super({
			source: source
		});
		this.map = map;
		this.setEditStyle();
	}
	setOutlineStyle() {
		this.setStyle(this.outlineStyle.bind(this));
	}
	setEditStyle() {
		this.setStyle(this.editStyle.bind(this));
	}
	outlineStyle(feature) {
		if (!feature) {
			return new Style(null);
		}
		if (this.focusedHoleId && feature.get('holeId') != this.focusedHoleId) {
			return null;
		}
		if (feature.get('hidden') === true) {
			return null;
		}
		let styles = [getStyle(feature)];

		const modifyGeometry = feature.get('modifyGeometry');
		const geometry = modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
		const result = GeometryUtils.calculateCenter(geometry);
		const center = result.center;
		if (center) {
			const coordinates = result.coordinates.reverse();
			if (coordinates.length == 8) {
				let angle = Utils.degreesToRadians(90) - Math.atan2(coordinates[1][1] - coordinates[0][1], coordinates[1][0] - coordinates[0][0]);
				let centerEnd = coordinates.filter(function (coordinate, index) {
					return index == 5;
				});
				styles.push(
					new Style({
						geometry: new MultiPoint(centerEnd),
						image: new Icon({
							scale: 0.5,
							rotation: angle,
							displacement: [15, 0],
							src: frameDirectionArrowUrl
						})
					})
				);
			} else if (coordinates.length == 4) {
				let angle = Utils.degreesToRadians(90) - Math.atan2(coordinates[1][1] - coordinates[0][1], coordinates[1][0] - coordinates[0][0]);
				let centerEnd = PointUtils.getMidpoint(coordinates[2], coordinates[3]);
				styles.push(
					new Style({
						geometry: new MultiPoint([centerEnd]),
						image: new Icon({
							scale: 0.5,
							rotation: angle,
							displacement: [15, 0],
							src: frameDirectionArrowUrl
						})
					})
				);
			}
		}
		if (!feature.get('focused')) {
			styles.push(
				new Style({
					zIndex: 9999,
					text: new Text({
						text: feature.get('displayName') || 'HOLE NOT\nASSIGNED',
						font: '13px "Tietoevry sans 1",sans-serif',
						fill: new Fill({
							color: '#000'
						}),
						stroke: new Stroke({
							color: '#fff',
							width: 4
						})
					})
				})
			);
		}
		return styles;
	}
	editStyle(feature) {
		if (!feature) {
			return new Style(null);
		}
		if (feature.get('hidden') === true) {
			return null;
		}
		if (!feature.get('selected')) {
			return this.outlineStyle(feature);
		}
		const styles = [getStyle(feature)];
		const modifyGeometry = feature.get('modifyGeometry');
		const geometry = modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
		const result = GeometryUtils.calculateCenter(geometry);
		const center = result.center;
		if (center) {
			const coordinates = result.coordinates.reverse();
			if (coordinates.length == 8) {
				let angle = Utils.degreesToRadians(90) - Math.atan2(coordinates[1][1] - coordinates[0][1], coordinates[1][0] - coordinates[0][0]);
				let startPoints = coordinates.filter(function (coordinate, index) {
					return index == 0 || index == 2;
				});
				let others = coordinates.filter(function (coordinate, index) {
					return index % 2 != 0;
				});
				let centerEnd = coordinates.filter(function (coordinate, index) {
					return index == 5;
				});
				styles.push(
					new Style({
						geometry: new MultiPoint(others),
						image: new CircleStyle({
							radius: 6,
							fill: new Fill({
								color: 'white'
							})
						})
					})
				);
				styles.push(
					new Style({
						geometry: new MultiPoint(startPoints),
						image: new CircleStyle({
							radius: 6,
							fill: new Fill({
								color: 'blue'
							}),
							stroke: new Stroke({
								color: 'white',
								width: 2
							})
						})
					})
				);
				styles.push(
					new Style({
						geometry: new MultiPoint(centerEnd),
						image: new Icon({
							scale: 0.5,
							rotation: angle,
							displacement: [15, 0],
							src: frameDirectionArrowUrl
						})
					})
				);
				let endPoints = coordinates.filter(function (coordinate, index) {
					return index == 4 || index == 6;
				});
				styles.push(
					new Style({
						geometry: new MultiPoint(endPoints),
						image: new CircleStyle({
							radius: 6,
							fill: new Fill({
								color: 'green'
							}),
							stroke: new Stroke({
								color: 'white',
								width: 2
							})
						})
					})
				);
			} else {
				if (coordinates.length == 4) {
					let angle = Utils.degreesToRadians(90) - Math.atan2(coordinates[1][1] - coordinates[0][1], coordinates[1][0] - coordinates[0][0]);
					let centerEnd = PointUtils.getMidpoint(coordinates[2], coordinates[3]);
					styles.push(
						new Style({
							geometry: new MultiPoint([centerEnd]),
							image: new Icon({
								scale: 0.5,
								rotation: angle,
								displacement: [15, 0],
								src: frameDirectionArrowUrl
							})
						})
					);
				}
				styles.push(
					new Style({
						geometry: new MultiPoint(coordinates),
						image: new CircleStyle({
							radius: 6,
							fill: new Fill({
								color: 'white'
							}),
							stroke: new Stroke({
								color: 'white',
								width: 2
							})
						})
					})
				);
			}
		}
		if (!feature.get('focused')) {
			styles.push(
				new Style({
					zIndex: 9999,
					text: new Text({
						text: feature.get('displayName') || 'HOLE NOT\nASSIGNED',
						font: '13px "Tietoevry sans 1",sans-serif',
						fill: new Fill({
							color: '#000'
						}),
						stroke: new Stroke({
							color: '#fff',
							width: 4
						})
					})
				})
			);
		}
		return styles;
	}
}
