import Draw from 'ol/interaction/Draw.js';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js';
import { LineString, Polygon } from 'ol/geom.js';
import { getArea, getLength } from 'ol/sphere.js';
import { unByKey } from 'ol/Observable.js';

const style = new Style({
	fill: new Fill({
		color: 'rgba(255, 255, 255, 0.2)'
	}),
	stroke: new Stroke({
		color: 'rgba(255, 255, 255, 1)',
		lineDash: [5, 5],
		width: 2
	}),
	image: new CircleStyle({
		radius: 1,
		stroke: new Stroke({
			color: 'rgba(255, 255, 255, 0.7)'
		}),
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.2)'
		})
	})
});
export class MeasureTool extends Draw {
	sketch;
	listener;
	constructor(source, type, cb) {
		if (type === undefined) type = 'LineString';
		super({
			source: source,
			type: type,
			style: style
		});
		const _this = this;
		this.on('drawstart', function (evt) {
			_this.sketch = evt.feature;
			let tooltipCoord = evt.coordinate;

			_this.listener = _this.sketch.getGeometry().on('change', function (evt) {
				const geom = evt.target;
				let output;
				if (geom instanceof Polygon) {
					output = MeasureTool.formatArea(geom);
					tooltipCoord = geom.getInteriorPoint().getCoordinates();
				} else if (geom instanceof LineString) {
					output = MeasureTool.formatLength(geom);
					tooltipCoord = geom.getLastCoordinate();
				}
				cb('update', output, tooltipCoord);
			});
		});

		this.on('drawend', function () {
			_this.sketch = null;
			//createMeasureTooltip();
			unByKey(_this.listener);
			cb('end', null, null);
		});
	}
	static formatLength(line) {
		const length = getLength(line);
		let output;
		if (length > 1000) {
			output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
		} else {
			output = Math.round(length * 100) / 100 + ' ' + 'm';
		}
		return {
			x: length,
			type: 'Length',
			formatted: output
		};
	}
	static formatArea(polygon) {
		const area = getArea(polygon);
		let output;
		if (area > 10000) {
			output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
		} else {
			output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
		}
		return {
			x: area,
			type: 'Area',
			formatted: output
		};
	}
}
