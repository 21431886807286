<script>
	import { getContext, onMount } from 'svelte';
	import { deconstructHoleId } from '@Utils/DataController.js';
	import { fade } from 'svelte/transition';
	import { getIconHtml } from '@Utils/Utils.js';
	const { getEditorCanvas, getController } = getContext('editor');
	const editorCanvas = getEditorCanvas();
	const controller = getController();
	let selectedGroup = null;
	let groupsListHidden = true;
	let groupListElement;
	let barelement;
	$: groups = controller.data.groups;
	$: focusedFeature = null;
	$: selectedFeature = null;
	$: isDarkMode = window.isDarkMode;
	$: selectedGroup;
	$: groupsListHidden;

	function sortingByName(a, b) {
		const nameA = parseInt(a.holeNumber, 10); // ignore upper and lowercase
		const nameB = parseInt(b.holeNumber, 10); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	}

	window.addEventListener('colorSchemeChanged', (e) => {
		isDarkMode = e.isDarkMode;
	});
	const onFrameSelected = (feature) => {
		if (feature) {
			let props = deconstructHoleId(feature.get('holeId'));
			if (props.holeGroupId != selectGroup.holeGroupId) {
				selectedGroup = groups.find((g) => g.id === props.holeGroupId);
			}
			selectedFeature = feature;
		} else {
			selectedFeature = null;
		}
	};

	const onFrameFocused = (frame) => {
		if (frame) {
			focusedFeature = frame.feature;
		} else {
			focusedFeature = null;
		}
	};
	controller.on('alertsUpdated', function () {
		groups = controller.data.groups;
		updateSelected();
	});
	controller.on('fetchCompleted', function () {
		groups = controller.data.groups;
		updateSelected();
	});
	editorCanvas.on('frameSelected', onFrameSelected);
	editorCanvas.on('frameFocused', onFrameFocused);

	function equal(hole, feature) {
		return feature && feature.get('holeId') === hole.holeId;
	}
	const hasFrame = (hole) => editorCanvas.getFrameFeature(hole.holeId);
	function holeToolTip(hole) {
		if (!hasAnyTees(hole)) {
			return 'This hole has no tees. Make sure to add the hole to a loop in Golfoffice.';
		}
		if (!hasFrame(hole)) {
			return 'Click to add hole outline';
		}
		if (hole.alerts && hole.alerts.length > 0) {
			let arr = [];
			for (let a of hole.alerts) {
				arr.push(a.label);
			}
			return 'Warnings<hr>' + arr.join('<br />');
		}
		return '';
	}
	function hasAnyTees(hole) {
		let tees = controller.data.holeTees[hole.id];
		return tees && tees.length > 0;
	}
	function holeToolTipBackground(hole) {
		if (!hasFrame(hole)) {
			return null;
		}
		if (hole.alerts && hole.alerts.length > 0) {
			return 'rgba(90,63,0,0.7)';
		}
		return null;
	}
	function holeStatus(hole, focusedFeature) {
		if (equal(hole, focusedFeature)) {
			return 'selected';
		}
		if (!hole.mapInfo.hasOutline) {
			return 'unmapped';
		}
		if (hole.alerts && hole.alerts.length > 0) {
			return 'unfinished';
		}
		return 'ok';
	}
	function groupMouseOver(e) {
		if (editorCanvas.currentTool) {
			return;
		}
		groupsListHidden = false;
	}
	function groupMouseOut(e) {
		groupsListHidden = true;
	}
	function selectGroup(group) {
		groupsListHidden = true;
		selectedGroup = group;
	}
	function updateSelected() {
		if (groups && groups.length > 0) {
			if (!selectedGroup) {
				selectedGroup = groups[0];
			} else {
				selectedGroup = groups.find((g) => g.id === selectedGroup.id);
			}
		}
	}
	onMount(() => {
		updateSelected();
	});
</script>

<div bind:this={barelement} class="holebar rounded-xl box-shadow text-center box p-1" class:hidden={groups.length < 1}>
	<div class="flex text-xs flex-row gap-1">
		<div class="flex items-start justify-center">
			<button
				tooltip={'Click to view all holes'}
				class="btn btn-sm h-8 w-28 all-holes"
				class:btn-solid-blue={focusedFeature}
				class:btn-solid-white={!focusedFeature && isDarkMode}
				class:btn-outline-blue={!focusedFeature && !isDarkMode}
				id={'All holes'}
				selected={!focusedFeature}
				on:click={() => {
					editorCanvas.unfocus();
					editorCanvas.fitAllFrames();
				}}>All holes</button
			>
		</div>
		<div class="flex text-xs flex-row gap-1">
			{#if selectedGroup}
				<div class="groups relative flex grow items-center pl-2 pr-2 text-sm" on:mouseover={groupMouseOver} role="none" on:mouseout={groupMouseOut} on:blur={groupMouseOut} on:focus={groupMouseOver}>
					<div in:fade class="whitespace-nowrap">{selectedGroup.name}</div>
					{#if groups.length > 1}
						<div bind:this={groupListElement} class="groups-list box box-shadow dark:bg-bigsteel-800" class:hidden={groupsListHidden}>
							{#each groups as group}
								<div class="text-md p-2 flex grow items-center w-full item" role="button" on:keydown on:keypress tabindex="0" class:selected={group.id === selectedGroup.id} on:click={() => selectGroup(group)}>{group.name}</div>
							{/each}
						</div>
					{/if}
				</div>
				<div class="flex text-xs flex-row gap-1 items-center text-center bg-white dark:bg-transparent">
					{#each selectedGroup.holes.sort(sortingByName) as hole}
						<button
							tooltip={holeToolTip(hole)}
							tooltip-background={holeToolTipBackground(hole)}
							class="btn btn-sm h-8 w-8 hole-btn"
							class:btn-solid-lightpeach={holeStatus(hole, focusedFeature) === 'unfinished'}
							class:btn-solid-lightred={holeStatus(hole, focusedFeature) === 'unmapped'}
							class:btn-solid-green={holeStatus(hole, focusedFeature) === 'ok'}
							class:btn-solid-blue={holeStatus(hole, focusedFeature) === 'selected'}
							class:highlighted={!focusedFeature && equal(hole, selectedFeature)}
							class:selected={equal(hole, focusedFeature)}
							disabled={equal(hole, focusedFeature) || !hasAnyTees(hole)}
							class:no-tees={!hasAnyTees(hole)}
							on:click={() => {
								let frame = editorCanvas.getFrameFeature(hole.holeId);
								if (frame) {
									let r = barelement.getBoundingClientRect();
									editorCanvas.viewPadding[2] = document.body.clientHeight - r.y + 20;
									if (equal(hole, focusedFeature)) {
										editorCanvas.fitFeature(frame, false, true);
									} else {
										editorCanvas.focus(frame);
									}
								} else {
									editorCanvas.unfocus();
									editorCanvas.rotate();
									editorCanvas.activateTool('addFrame', {
										holeId: hole.holeId
									});
								}
							}}>{hole.holeNumber}</button
						>
					{/each}
				</div>
			{/if}
		</div>
	</div>
</div>

<style lang="postcss">
	.holebar {
		left: 50%;
		transform: translateX(-50%);
		z-index: 50;
		bottom: 10px;
		position: absolute;
	}
	.groups {
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 10px;
	}
	.groups-list {
		position: absolute;
		bottom: 32px;
		z-index: 100;
		width: 200px;
		transform: translate(-50%);
		left: 50%;
		cursor: pointer;
		overflow: hidden;
		border-radius: 7px;
	}
	.groups-list .item {
	}
	.groups-list .item:hover {
		background-color: theme('colors.blue.100');
	}
	.groups-list .item.selected {
		background-color: theme('colors.blue.500');
		color: white;
	}
	.groups-list .item:hover:not(.selected) {
	}

	.dark .groups-list .item:hover {
		background-color: theme('colors.bigsteel.700/70');
	}
	.dark .groups-list .item:hover:not(.selected) {
	}
	.hole-btn {
		border-radius: 7px;
	}
	.hole-btn:disabled {
		opacity: 1;
	}
	.no-tees {
		user-select: none;
		pointer-events: all;
		opacity: 0.5 !important;
	}
	.hole-btn.selected {
		transform: translateY(-2px);
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
	}
	.hole-btn.highlighted {
		transform: translateY(-2px);
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
	}
	.all-holes {
		border-radius: 7px;
	}
	/* :global(.dark) .hole-btn.selected {
		outline: 3px solid theme('colors.bigsteel.700/70');
	}
	:global(.dark) .hole-btn.highlighted {
		outline: 3px solid theme('colors.bigsteel.700/70');
	} */
</style>
