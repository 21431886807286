<script>
	export let title = '';
	export let position = 'bottom';
	export let id = '';
	export let anchor;
	export let root;
	export let behaviour = 'auto';
	$: styles = getStyle();
	$: classes = getClasses();
	function toggle(evt) {
		styles = getStyle();
		classes = getClasses();
	}
	function getStyle() {
		let el = document.getElementById(anchor);
		if (!el) {
			return '';
		} else {
			let bbox = el.getBoundingClientRect();
			if (position === 'top') {
				return `bottom: ${bbox.top}px; left: ${bbox.left + bbox.width / 2}px;`;
			} else if (position === 'bottom') {
				return `top: ${bbox.top + bbox.height}px; left: ${bbox.left + bbox.width / 2}px;`;
			} else if (position === 'left') {
				return `top: ${bbox.top + bbox.height / 2}px; left: ${bbox.left}px;`;
			} else if (position === 'right') {
				return `top: ${bbox.top + bbox.height / 2}px; left: ${bbox.left + bbox.width}px;`;
			}
		}
	}
	function getClasses() {
		return ['te-popover', 'te-popover-' + position, 'te-popover-abs'].join(' ');
	}
</script>

<div bind:this={root} {id} on:toggle={toggle} class={classes} style={styles} popover={behaviour}>
	<div class="te-popover-wrapper">
		<div class="te-popover-title">{title}</div>
		<div class="te-popover-content">
			<slot />
		</div>
	</div>
</div>

<style lang="postcss">
	.te-popover {
		font-family: 'Tietoevry sans 1';
		z-index: 700;
		background-color: transparent;
		padding: 0;
		overflow: visible;
		position: absolute;
		inset: unset;
	}
	.te-popover-wrapper {
		min-width: 160px;
		max-width: 260px;
		gap: 8px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		padding: 16px;
		background-color: #fff;
		box-shadow: 0px 6px 12px 0px rgba(16, 52, 82, 0.4);
		border-radius: var(--Border-radius-Border-radius-S, 6px);
		text-align: left;
	}
	.te-popover-wrapper::after {
		content: '';
		position: absolute;
		border-width: 10px;
		border-style: solid;
	}
	.te-popover-title {
		font-size: 18px;
		font-style: normal;
		font-weight: 200;
		line-height: 24px;
		color: var(--steel);
	}
	.te-popover-content {
		font-size: 14px;
		font-style: normal;
		line-height: 20px;
		color: var(--steel);
	}
	.te-popover.te-popover-top {
		bottom: 100%;
		left: 50%;
		margin-bottom: 20px;
		transform: translateX(-50%);
	}
	.te-popover.te-popover-top .te-popover-wrapper {
		transform-origin: bottom center;
	}
	.te-popover.te-popover-top .te-popover-wrapper::after {
		top: 100%;
		left: 50%;
		margin-left: -10px;
		border-color: white transparent transparent transparent;
	}
	.te-popover.te-popover-top.te-popover-abs {
		bottom: unset;
		left: unset;
	}
	.te-popover.te-popover-bottom {
		top: 100%;
		left: 50%;
		margin-top: 20px;
		transform: translateX(-50%);
	}
	.te-popover.te-popover-bottom .te-popover-wrapper {
		transform-origin: top center;
	}
	.te-popover.te-popover-bottom .te-popover-wrapper::after {
		bottom: 100%;
		left: 50%;
		margin-left: -10px;
		border-color: transparent transparent white transparent;
	}
	.te-popover.te-popover-bottom.te-popover-abs {
		top: unset;
		left: unset;
	}
	.te-popover.te-popover-left {
		right: 100%;
		top: 50%;
		margin-right: 20px;
		transform: translateY(-50%);
	}
	.te-popover.te-popover-left .te-popover-wrapper {
		transform-origin: center right;
	}
	.te-popover.te-popover-left .te-popover-wrapper::after {
		top: 50%;
		left: 100%;
		margin-top: -10px;
		border-color: transparent transparent transparent white;
	}
	.te-popover.te-popover-left.te-popover-abs {
		right: unset;
		top: unset;
		transform: translateY(-50%) translateX(-100%);
		margin-left: -20px;
	}
	.te-popover.te-popover-right {
		left: 100%;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}
	.te-popover.te-popover-right .te-popover-wrapper {
		transform-origin: center left;
	}
	.te-popover.te-popover-right.te-popover-wrapper::after {
		top: 50%;
		right: 100%;
		margin-top: -10px;
		border-color: transparent white transparent transparent;
	}
	.te-popover.te-popover-left.te-popover-abs {
		left: unset;
		top: unset;
	}
</style>
