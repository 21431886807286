import { nanoid } from 'nanoid';

let tokenPromise = null;
async function requestToken(url) {

	if (tokenPromise) {
		return tokenPromise;
	}
	if (window === window.parent) {
		return new Promise((resolve, reject) => {
			if (window === window.parent) {
				let e = new Error('Not in golfoffice')
				e.status = 401
				reject(e);
				return
			}
		})
	}
	let id = nanoid()
	window.parent.postMessage({ action: 'getToken', id: id },
		url
	);
	tokenPromise = new Promise((resolve, reject) => {
		let func = (e) => {
			if (e.data.action === "setToken" && id === e.data.id) {
				tokenPromise = null
				window.removeEventListener('message', func);
				if (!e.data.token) {
					reject();
				} else {
					resolve(e.data.token);
				}
			}
		};
		window.addEventListener('message', func);
	});
	return tokenPromise
}
const serialize = function (obj) {
	let str = [];
	for (let p in obj)
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
		}
	return str.join('&');
};

export const codes = {
	error: -1,
	idle: 0,
	loading: 1,
	hasUnsavedData: 2,
	savingToStorage: 3
};
const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();
function base64ToBytes(base64) {
	const binString = atob(base64);
	return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

function bytesToBase64(bytes) {
	const binString = String.fromCodePoint(...bytes);
	return btoa(binString);
}
export function constructHoleId(groupShortName, holeGroupId, holeNumber, holeId) {
	let data = {
		g: groupShortName,
		gId: holeGroupId,
		n: holeNumber,
		hId: holeId
	};
	let str = JSON.stringify(data);
	return bytesToBase64(textEncoder.encode(str));
}
export function deconstructHoleId(holeId) {
	if (!holeId) {
		console.warn('undefined holeid')
		console.trace()
		return null;
	}
	let str = textDecoder.decode(base64ToBytes(holeId));
	let obj = JSON.parse(str);
	return {
		groupShortName: obj.g,
		holeGroupId: obj.gId,
		holeNumber: obj.n,
		holeId: obj.hId,
		displayName: obj.g + obj.n
	};
}

export class DataController {
	editorCanvas;
	url;
	token;
	timer;
	lastSurroundingsChange = null;
	lastSurroundingsCommit = null;
	lastSurroundingsRequest = null;
	lastSurroungingsError = null;
	lastHoleChange = {};
	throttleStore = {};
	unauthorized = false;
	lastHoleCommit = {};
	lastHoleRequest = {};
	lastHoleError = null;
	initalFetchCompleted = false;
	isUpdatingHole = {};
	timeoutLimit = 3000;
	listeners = {};
	status = {
		loading: false,
		code: 0,
		message: 'Saved'
	};
	data = {
		holes: [],
		groups: [],
		frames: [],
		holeTees: {},
		tees: []
	};
	constructor(editorCanvas, url, token, options) {
		if (options) {
			if (options.timeoutLimit) {
				this.timeoutLimit = options.timeoutLimit;
			}
			if (options.storageDataKeyPrefix) {
				storageDataKeyPrefix = options.storageDataKeyPrefix;
			}
		}
		this.frontendUrl = options.golfOfficeFrontendURL;
		this.googleKey = options.googleKey;
		this.azureMapsKey = options.azureMapsKey;
		this.editorCanvas = editorCanvas;
		this.url = url;
		this.token = token;
	}
	on(event, cb) {
		if (!this.listeners[event]) {
			this.listeners[event] = [];
		}
		this.listeners[event].push(cb);
	}
	off(event, cb) {
		if (!this.listeners[event]) {
			return;
		}
		this.listeners[event] = this.listeners[event].filter((c) => c != cb);
	}
	async triggerError(err) {
		err.id = nanoid();
		let e = new Event('error')
		e.data = err
		window.dispatchEvent(e)
	}
	trigger(event, val1, val2, val3) {
		if (this.haltEvents && event !== 'authorizationStatus') {
			return;
		}
		if (!this.listeners[event]) {
			return;
		}
		for (let l of this.listeners[event]) {
			l(val1, val2, val3);
		}
	}
	start() {
		if (!this.editorCanvas) {
			return;
		}
		let _this = this;
		this.editorCanvas.on('featureUpdated', function (feature, evt) {
			_this.updateTriggered(null, feature, evt);
		});
		this.editorCanvas.on('sourceUpdated', function (source, feature, evt) {
			_this.updateTriggered(source, feature, evt);
		});
		this.fetchData();
	}
	updateStatus(loading, message, code, error) {
		this.status = {
			loading: loading,
			message: message,
			code: code,
			error: error,
		};
		this.trigger('statusUpdated', this.status);
	}
	async fetchData() {
		this.updateStatus(true, 'Loading', codes.loading);
		let hadErrors = false
		let surroundingsUrl = this.getUrl('mapping/getcoursesurroundings');
		let holesUrl = this.getUrl('courseguide/getholes');
		let allTeesUrl = this.getUrl('courseguide/getalltees');
		let loosUrl = this.getUrl('courseguide/getloops');
		let clubUrl = this.getUrl('clubs/club');
		this.haltEvents = true;
		let data = {
			holes: [],
			groups: [],
			frames: [],
			holeTees: {},
			tees: []
		};
		let [loops, loopsError] = await this.get(loosUrl);
		let [allTees, allTeesError] = await this.get(allTeesUrl);
		let [club, clubError] = await this.get(clubUrl);
		let [surroundings, surroundingsError] = await this.get(surroundingsUrl);
		if (surroundingsError) {
			hadErrors = true
			this.triggerError({
				type: 'fetchSurroundings',
				message: 'Error fetching surroundings',
				error: surroundingsError
			});
			this.updateStatus(false, 'Error', codes.error, surroundingsError);
		} else {
			if (surroundings.result && surroundings.result !== '') {
				let s = JSON.parse(surroundings.result);
				data.surroundings = s.json;
				this.lastSurroundingsChange = s.date;
				this.lastSurroundingsCommit = s.date;
			} else {
				data.surroundings = surroundings.result;
			}
		}
		if (allTeesError) {
			hadErrors = true
			this.triggerError({
				type: 'fetchAllTees',
				message: 'Error fetching tees',
				error: allTeesError
			});
			this.updateStatus(false, 'Error', codes.error, allTeesError);
		} else {
			this.teeMap = {};
			for (let t of allTees.result) {
				this.teeMap[t.gitId] = t.color;
				this.teeMap[t.color] = t.gitId
			}
		}
		if (loopsError) {
			hadErrors = true
			this.triggerError({
				type: 'fetchLoops',
				message: 'Error fetching loops',
				error: loopsError
			});
			this.updateStatus(false, 'Error', codes.error, loopsError);
		} else {
			data.loops = loops.result;
			let tees = [];
			let holeTees = {};
			for (let l of loops.result) {
				for (let t of l.tees) {
					t.elementId = this.teeMap[t.externalTeeId];
					if (tees.find((i) => i.externalTeeId == t.externalTeeId)) {
						continue;
					}
					tees.push(t);
				}
				for (let h of l.loopHoles) {
					if (h.physicalHoleId !== 0) {
						if (!holeTees[h.physicalHoleId]) {
							holeTees[h.physicalHoleId] = [...l.tees];
						} else {
							for (let t of l.tees) {
								t.elementId = this.teeMap[t.externalTeeId];
								if (holeTees[h.physicalHoleId].find((i) => i.externalTeeId == t.externalTeeId)) {
									continue;
								}
								holeTees[h.physicalHoleId].push(t);
							}
						}
					}
				}
			}
			data.holeTees = holeTees;
			data.tees = tees;
		}
		if (clubError) {
			hadErrors = true
			this.triggerError({
				type: 'fetchClub',
				message: 'Error fetching club',
				error: clubError
			});
			this.updateStatus(false, 'Error', codes.error, clubError);
		} else {
			data.club = club.result;
			data.clubCoordinates = await this.getClubCoordinates(data.club);
		}
		let [res, err] = await this.get(holesUrl);
		if (err) {
			hadErrors = true
			this.triggerError({
				type: 'fetchHoles',
				message: 'Error fetching holes',
				error: err
			});
			this.updateStatus(false, 'Error', codes.error, err);
		} else if (res && res.result) {
			const d = await this.parseCoursedataResult(res.result, data.tees);
			data.frames = d.frames;
			data.groups = d.groups;
			data.holes = d.holes;
		}
		this.data = data;
		this.editorCanvas.loadJson(data);
		if (data.frames.length < 1 && (data.surroundings === '' || data.surroundings === null || data.surroundings === undefined)) {
			if (data.clubCoordinates && data.clubCoordinates.longitude && data.clubCoordinates.latitude) {
				this.editorCanvas.panTo([data.clubCoordinates.longitude, data.clubCoordinates.latitude], 15, false);
			}
		}
		this.haltEvents = false;
		for (let h of data.holes) {
			this.analyze(h.holeId, null, this.data.holeTees);
		}
		this.initalFetchCompleted = true;
		this.trigger('alertsUpdated', this.data.holes);
		this.trigger('fetchCompleted', this.data);
		if (hadErrors) {
			this.updateStatus(false, 'Error', codes.error);
		} else {
			this.updateStatus(false, 'Idle', codes.idle);
		}
	}
	getCodes() {
		return codes;
	}
	getHoleId(source, feature) {
		if (feature && feature.get('holeId')) {
			return feature.get('holeId');
		}
		if (source && source.get('holeId')) {
			return source.get('holeId');
		}
		return null;
	}
	updateTriggered(source, feature, evt) {
		if (this.haltEvents) {
			return;
		}
		let _this = this;
		let holeId = this.getHoleId(source, feature);
		if (holeId && (evt.type == 'addfeature' || evt.type == 'removefeature')) {
			this.analyze(holeId, null, this.data.holeTees);
			this.trigger('alertsUpdated', this.data.holes);
		}
		if (feature.get('objectType') == 'frame') {
			if (!holeId) {
				return;
			}
			this.lastHoleChange[holeId] = Date.now();
			this.throttleUpdates(function () {
				_this.save();
			});
			this.updateStatus(false, 'Unsaved', codes.hasUnsavedData);
		} else if (holeId) {
			this.lastHoleChange[holeId] = Date.now();
			this.throttleUpdates(function () {
				_this.save();
			});
			this.updateStatus(false, 'Unsaved', codes.hasUnsavedData);
		} else {
			this.lastSurroundingsChange = Date.now();
			this.updateIntersectingFrames(feature);
			this.throttleUpdates(function () {
				_this.save();
			});
			this.updateStatus(false, 'Unsaved', codes.hasUnsavedData);
		}
	}
	throttleUpdates(func) {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.timer = setTimeout(func, this.timeoutLimit);
	}
	updateIntersectingFrames(feature) {
		if (this.throttleStore[feature.ol_uid]) {
			clearTimeout(this.throttleStore[feature.ol_uid]);
		}
		let _this = this;
		this.throttleStore[feature.ol_uid] = setTimeout(() => {
			_this.editorCanvas.getFramesInExtent(feature.getGeometry().getExtent()).forEach((f) => {
				let holeId = f.get('holeId');
				if (holeId) {
					let hole = _this.data.holes.find((h) => h.holeId === holeId);
					if (hole) {
						hole.needsRendering = true;
					}
					_this.lastHoleChange[holeId] = Date.now();
					_this.throttleUpdates(function () {
						_this.save();
					});
				}
			});
		}, 50);
	}
	async save() {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.updateStatus(true, 'Saving', codes.savingToStorage);
		await this.saveHoles();
		await this.saveSurroundings();
		if (this.hasUnsavedData()) {
			this.updateStatus(false, 'Unsaved', codes.hasUnsavedData);
		} else {
			if (this.lastHoleError || this.lastSurroungingsError) {
				this.updateStatus(false, 'Error', codes.error);
			} else {
				this.updateStatus(false, 'Saved', codes.idle);
			}
		}
	}
	async saveHoles() {
		for (let holeId in this.lastHoleChange) {
			if (this.shouldUpdateHole(holeId)) {
				await this.saveHole(holeId);
			}
		}
	}
	async saveHole(holeId) {
		this.lastHoleCommit[holeId] = Date.now();
		let hole = this.data.holes.find((h) => h.holeId === holeId);
		let geometry = this.editorCanvas.getFrame(holeId);
		let props = deconstructHoleId(holeId);
		let body = {
			holeGroupId: props.holeGroupId,
			holeId: props.holeId
		};
		if (!geometry) {
			body.geometry = '';
			body.surroundings = '';
			body.greenPoints = '';
			body.teePoints = '';
		} else {
			if (hole.geometry !== geometry || hole.needsRendering) {
				body.geometry = JSON.stringify({
					date: Date.now(),
					json: geometry
				});
			}
			let surroundings = this.editorCanvas.getFrameSurroundings(holeId);
			if (surroundings !== hole.surroundings) {
				body.surroundings = JSON.stringify({
					date: Date.now(),
					json: surroundings
				});
			}
			let miscData = this.editorCanvas.getMiscDataForHole(holeId);
			if (miscData) {
				let tees = []
				let greenPoints = {}
				for (let t in miscData.tee) {
					if (miscData.tee[t]) {
						tees.push({
							color: t,
							externalId: this.teeMap[t],
							position: {
								longitude: miscData.tee[t].coordinates[0],
								latitude: miscData.tee[t].coordinates[1]
							}
						})
					}
				}
				for (let p in miscData.green) {
					if (miscData.green[p]) {
						greenPoints[p + '_green'] = {
							longitude: miscData.green[p].coordinates[0],
							latitude: miscData.green[p].coordinates[1]
						}
					}
				}
				body.greenPoints = JSON.stringify(greenPoints);
				body.teePoints = JSON.stringify(tees);
			} else {
				body.greenPoints = '';
				body.teePoints = '';
			}
		}
		let url = this.getUrl('mapping/updatehole');
		let [res, err] = await this.put(url, body);
		if (!err) {
			this.lastHoleRequest[holeId] = Date.now();
			let h = this.data.holes.find((h) => h.id == props.holeId);
			if (h) {
				h.surroundings = body.surroundings;
				h.greenPoints = body.greenPoints;
				h.teePoints = body.teePoints;
				h.hasChange = true;
				this.analyze(holeId, props, this.data.holeTees);
				this.trigger('alertsUpdated', this.data.holes);
			}
			this.lastHoleError = null;
		} else {
			err.holeId = holeId;
			this.triggerError({
				type: 'saveHole',
				message: 'Error saving hole',
				error: err
			});
			this.lastHoleError = err;
		}
	}
	async saveSurroundings() {
		if (!this.shouldUpdateSurroundings()) {
			return;
		}
		this.lastSurroundingsCommit = Date.now();
		let data = this.editorCanvas.getSurroundings();
		let url = this.getUrl('mapping/updatecoursesurroundings');

		let body = JSON.stringify({
			date: Date.now(),
			json: data
		})
		let [res, err] = await this.put(
			url,
			body
		);
		if (!err) {
			this.lastSurroundingsRequest = Date.now();
			this.lastSurroungingsError = null;
		} else {
			this.triggerError({
				type: 'saveSurroundings',
				message: 'Error saving surroundings',
				error: err
			});
			this.lastSurroungingsError = err;
		}
	}
	analyze(holeId, props, teesMap) {
		if (!props) {
			props = deconstructHoleId(holeId);
		}
		let arr = [];
		let info = this.editorCanvas.getAnalyticsForHole(holeId);
		if (!info || !info.hasGreen) {
			arr.push({
				topic: 'map',
				property: 'surroundings',
				elementName: 'green',
				label: 'Missing a green',
				icon: 'tietoevry-icons-alert'
			});
		}
		if (!info || !info.hasTee) {
			arr.push({
				topic: 'map',
				property: 'surroundings',
				elementName: 'tee',
				label: 'Missing a tee cut',
				icon: 'tietoevry-icons-alert'
			});
		}
		if (!info || !info.green.center) {
			arr.push({
				topic: 'map',
				property: 'greenPoints',
				key: 'center',
				elementName: 'greencenter',
				label: 'Missing center green',
				icon: 'tietoevry-icons-alert'
			});
		}
		if (!info || !info.green.front) {
			arr.push({
				topic: 'map',
				property: 'greenPoints',
				elementName: 'greenfront',
				key: 'front',
				label: 'Missing front green',
				icon: 'tietoevry-icons-alert'
			});
		}
		if (!info || !info.green.back) {
			arr.push({
				topic: 'map',
				property: 'greenPoints',
				key: 'back',
				elementName: 'greenback',
				label: 'Missing back green',
				icon: 'tietoevry-icons-alert'
			});
		}
		let tees = teesMap[props.holeId];
		if (tees) {
			for (let t of tees) {
				if (!info || !info.tee[t.elementId]) {
					arr.push({
						topic: 'map',
						property: 'teePoints',
						key: t.elementId,
						elementName: 'tee' + t.elementId,
						label: `Missing a tee point for ${t.teeAlias}`,
						icon: 'tietoevry-icons-alert'
					});
				}
			}
		}
		for (let h in this.data.holes) {
			if (this.data.holes[h].holeId === holeId) {
				this.data.holes[h].alerts = arr;
				this.data.holes[h].mapInfo = info;
				break;
			}
		}
	}
	hasUnsavedData() {
		if (this.shouldUpdateSurroundings()) {
			return true;
		}
		for (let holeId in this.lastHoleChange) {
			if (this.shouldUpdateHole(holeId)) {
				return true;
			}
		}
		return false;
	}
	holeRenderStatus(holeId) {
		let hole = this.data.holes.find((h) => h.holeId === holeId);
		if (!hole) {
			return {
				status: 'error',
				tooltip: 'No hole found'
			};
		}
		if (hole.holeImageData && hole.holeImageData.created) {
			if (this.lastHoleCommit[holeId] > hole.holeImageData.created) {
				return {
					status: 'waiting',
					tooltip: 'Waiting to render new image'
				};
			}
			return {
				status: 'done',
				tooltip: 'No changes detected'
			};
		}
		if (hole.mapInfo.hasOutline && hole.mapInfo.hasGreen && hole.mapInfo.hasTee) {
			return {
				status: 'waiting',
				tooltip: 'Waiting to render'
			};
		}
		return {
			status: 'invalid',
			tooltip: 'Missing data required for rendering the image.<br />Check if the hole has a tee, green and outline.'
		};
	}
	shouldUpdateHole(holeId) {
		let lastChanged = this.lastHoleChange[holeId];
		if (!lastChanged) {
			return false;
		}
		let lastCommit = this.lastHoleCommit[holeId];
		if (!lastCommit) {
			return true;
		}
		if (lastChanged > lastCommit) {
			return true;
		}
		return false;
	}
	shouldUpdateSurroundings() {
		if (!this.lastSurroundingsChange) {
			return false;
		}
		if (!this.lastSurroundingsCommit) {
			return true;
		}
		if (this.lastSurroundingsChange > this.lastSurroundingsCommit) {
			return true;
		}
		return false;
	}
	async parseCoursedataResult(result, tees) {
		let holes = [];
		let frames = [];
		for (let group of result) {
			group.holeGroupId = group.id;
			for (let hole of group.holes) {
				let holeId = constructHoleId(group.shortName, group.id, hole.holeNumber, hole.id);
				hole.holeId = holeId;
				hole.holeGroupId = group.id;
				hole.displayName = group.shortName + hole.holeNumber;
				let changesToHole = null;
				if (hole.holeImageData && hole.holeImageData.created === undefined && hole.holeImageData !== '') {
					hole.holeImageData = JSON.parse(hole.holeImageData);
					changesToHole = hole.holeImageData.created;
				}
				if (hole.geometry && hole.geometry !== '') {
					let geometry = JSON.parse(hole.geometry);
					if (geometry && geometry.date) {
						hole.geometry = geometry.json;
						if (geometry.date > changesToHole) {
							changesToHole = geometry.date;
						}
					}
				}
				if (hole.surroundings && hole.surroundings !== '') {
					let surroundings = JSON.parse(hole.surroundings);
					if (surroundings && surroundings.date) {
						hole.surroundings = surroundings.json;
						if (surroundings.date > changesToHole) {
							changesToHole = surroundings.date;
						}
					}
				}
				if (hole.geometry && hole.geometry !== '') {
					frames.push({
						holeId: holeId,
						displayName: group.shortName + hole.holeNumber,
						geometry: hole.geometry,
						surroundings: hole.surroundings
					});
				}
				if (changesToHole) {
					this.lastHoleChange[holeId] = changesToHole;
					this.lastHoleCommit[holeId] = changesToHole;
				}
				holes.push(hole);
			}
		}
		return {
			frames: frames,
			groups: result,
			holes: holes
		};
	}
	setUnaturhorized(b) {
		this.unauthorized = b;
		this.trigger('authorizationStatus', b);
		// if(b) {
		// 	this.triggerError({
		// 		type: 'unauthorized',
		// 		message: 'Unauthorized to use tool, try logging in again',
		// 	});
		// }
	}
	async put(url, data) {
		return await this.request('PUT', url, data);
	}
	async post(url, data) {
		return await this.request('POST', url, data);
	}
	async get(url) {
		return await this.request('GET', url, null);
	}
	async delete(url) {
		return await this.request('DELETE', url, null);
	}
	async getClubCoordinates(club) {
		if (club.latitude && club.longitude) {
			return {
				longitude: club.longitude,
				latitude: club.latitude
			};
		}
		if (!this.azureMapsKey) {
			return null
		}
		let params = {
			"subscription-key": this.azureMapsKey,
			"api-version": "1.0",
			query: club.name,
		};
		let url = `https://atlas.microsoft.com/search/fuzzy/json?${serialize(params)}`
		try {
			const response = await fetch(url);
			if (!response.ok) {
				return null
			}
			let res = await response.json();
			if (!res.results || res.results.length < 1) {
				return null
			}
			if (!res.results[0].position) {
				return null
			}
			return {
				longitude: res.results[0].position.lon,
				latitude: res.results[0].position.lat
			};
		} catch (err) {
			console.error(err)
			return null
		}
	}
	async request(method, url, data, count) {
		if (!count) {
			count = 0;
		}
		if (!url) {
			console.error('No url set');
			return [null, new Error('No api url set')];
		}
		if (!method) {
			method = 'GET';
		}
		let requestOptions = {
			method: method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + this.token
			}
		};
		if (data) {
			requestOptions.body = JSON.stringify(data);
		}
		let res = null;
		let err = null;
		console.info(method, url);
		try {
			const response = await fetch(url, requestOptions);
			if (!response.ok) {
				if (response.status === 401) {
					if (count < 1) {
						let token = await requestToken(this.frontendUrl);
						if (!token) {
							this.setUnaturhorized(true);
						} else {
							this.token = token;
							return await this.request(method, url, data, count + 1);
						}
					} else {
						this.setUnaturhorized(true);
					}
				}
				let e = new Error(`${response.status} ${response.statusText}`);
				e.status = 401
			}
			res = await response.json();
			this.setUnaturhorized(false);
		} catch (error) {
			err = error;
			if (error.status === 401) {
				this.setUnaturhorized(true);
			}
			if (error instanceof SyntaxError) {
				console.error('There was a SyntaxError', error);
			} else {
				console.error('There was an error', error);
			}
		}
		return [res, err];
	}
	getUrl(path) {
		return [this.url, path].join('/');
	}
	async postImage(holeId, image, data) {
		let hole = this.data.holes.find((h) => h.holeId === holeId);
		if (!hole) {
			console.error('No hole found');
			return [null, new Error('No hole found')];
		}
		let url = this.getUrl(`mapping/hole/${hole.id}/dataExport`);
		const method = 'POST';
		try {
			let ext = image.type.split('/')[1];
			let imageName = `image-${holeId}.${ext}`;
			const formData = new FormData();
			const imageFile = new File([image], imageName, { type: image.type });

			const stringData = JSON.stringify(data);
			formData.set('image', imageFile, imageName);
			formData.set('imageData', stringData);
			const response = await fetch(url, {
				method: method,
				headers: {
					Authorization: 'Bearer ' + this.token
				},
				body: formData
			});
			if (!response.ok) {
				if (response.status === 401) {
					if (count < 1) {
						let token = await requestToken(this.frontendUrl);
						if (!token) {
							this.setUnaturhorized(true);
						} else {
							this.token = token;
							return await this.request(method, url, data, count + 1);
						}
					} else {
						this.setUnaturhorized(true);
					}
				}
				throw new Error(`${response.status} ${response.statusText}`);
			}
			let res = await response.json();
			this.setUnaturhorized(false);
			if (hole) {
				hole.holeImageData = data;
				hole.image = image;
			}
			return [res, null];
		} catch (error) {
			console.error(error);
			return [null, error];
		}
	}
}