import { VectorLayer } from './RendererLayer.js';
import { Style } from 'ol/style.js';
import { Circle, Fill, Stroke } from 'ol/style.js';
import { createElementStyles, getGeometryCache } from './ElementStyles.js';
import { TextureProvider } from '@Renderer/Texture.js';
import { getElement } from '@Utils/Elements.js';
import { Utils } from '@Utils/Utils.js';

let lineSelectedStyle = new Style({
	geometry: function (feature) {
		return getGeometryCache(feature, 'MultiPoint');
	},
	image: new Circle({
		radius: 3,
		fill: new Fill({
			color: "#ffffff"
		})
	})
})
let overLappingStyle = new Style({
	fill: new Fill({
		color: 'rgba(255,0,0,0.5)'
	})
})
let intersectingStyle = new Style({
	fill: new Fill({
		color: 'rgba(255,128,0,0.5)'
	})
})
let treeCache = {}
let lineCache = {}
export class ElementsLayer extends VectorLayer {
	map;
	useTextures = true;
	constructor(map, source) {
		super({
			source: source,
		});
		this.map = map;
		let res = map.getView().getResolution();
		let _this = this;
		this.elementStyles = createElementStyles(false, res);
		this.elementStylesSelected = createElementStyles(true, res);
		this.setStyle(function (feature, res) {
			return _this.getStyle(feature, res)
		});
	}
	getLineStringStyle(element, feature, resolution) {
		let selected = feature.get('selected');
		let opacity = '80';
		let size = (feature.get('lineWidth') ?? element.defaultValues.lineWidth) / resolution
		let id = `${size}-${element.name}`
		if (feature.get('clearCache')) {
			delete lineCache[id];
			feature.unset('clearCache');
		}
		if (!lineCache[id]) {
			lineCache[id] = new Style({
				stroke: new Stroke({
					color: element.color + opacity,
					width: size
				}),
				geometry: function (f) {
					return getGeometryCache(f, 'LineString');
				}
			})
		}

		if (selected) {
			return [lineSelectedStyle, new Style({
				stroke: new Stroke({
					color: 'rgba(230, 202, 17, 0.7)',
					width: size
				}),
				geometry: function (f) {
					return getGeometryCache(f, 'LineString');
				}
			})]
		} else {
			return [lineCache[id]]
		}
	}
	getTreeStyle(element, feature, resolution) {
		let selected = feature.get('selected');
		let opacity = '80';
		let texSize = parseInt(TextureProvider.defaultSize.tree / resolution, 10);
		let size = parseInt((texSize * (feature.get('textureSize') ?? 100)) / 100, 10);
		let id = size
		if (!treeCache[id]) {
			treeCache[id] =
				new Style({
					image: new Circle({
						radius: size / 2,
						fill: new Fill({
							color: element.color + opacity
						}),
						stroke: new Stroke({
							color: 'rgba(0,0,0,0.2)',
							width: 2
						})
					})
				})
		}
		if (selected) {
			return [new Style({
				image: new Circle({
					radius: size / 2,
					fill: new Fill({
						color: element.color + opacity
					}),
					stroke: new Stroke({
						color: 'rgba(255,255,255,0.5)',
						width: 2
					})
				})
			})]
		} else {
			return [treeCache[id]]
		}
	}
	getStyle(feature, resolution) {
		let _this = this;
		let name = feature.get('elementName')
		let el = getElement(name);
		let styleName = name
		if (name === "icon" && feature.get('iconName')) {
			styleName = "icon-" + feature.get('iconName')
		}
		if (feature.get('directional')) {
			styleName = styleName + '-directional-' + (feature.get('directionalIcon') ?? el.defaultValues.directionalIcon)
		}

		let order = feature.get('order');
		let hidden = feature.get('hidden');
		let selected = feature.get('selected');
		if (hidden) {
			return new Style(null);
		}
		let style = []
		if (el.tooltype === 'LineString') {
			style = this.getLineStringStyle(el, feature, resolution)
		} else if (el.texture == 'tree') {
			style = this.getTreeStyle(el, feature, resolution)
		} else if (selected) {
			style = _this.elementStylesSelected[styleName];
		} else {
			style = _this.elementStyles[styleName];
		}
		if (feature.get('overlapping')) {
			style = style.concat(overLappingStyle)
		} else if (feature.get('intersecting')) {
			style = style.concat(intersectingStyle)
		}
		if (!style) {
			console.warn('Style not found for element', styleName)
			return [new Style({
				image: new Circle({
					radius: 20,
					fill: new Fill({
						color: "#ff0000"
					})
				})
			})]
		}
		if (order) {
			let r = this.map.getView().getRotation()
			for (let s of style) {
				s.setZIndex(order);
				let i = s.getImage()
				if (i) {
					if (el.editStyle === 'icon') {
						let rotation = 0
						if (i.noRotation !== true) {
							rotation = Utils.degreesToRadians(feature.get('rotation') ?? 0)
						}
						i.setRotation(rotation - r)
					}
					if (el.renderStyle == 'icon') {
						let scaleWithResolution = feature.get('scaleWithResolution') ?? el.defaultValues.scaleWithResolution ?? false;
						let res = scaleWithResolution ? resolution : 1
						let scale = (feature.get('scale') ?? el.defaultValues.scale ?? 1)
						i.setScale(1 / res * scale)
					}
				}
			}
		}
		return style;
	}
}