import Flatten from "@flatten-js/core"
import { Feature } from 'ol';
import Polygon from 'ol/geom/Polygon';
import { GeometryUtils } from '@Utils/Utils';
import { nanoid } from 'nanoid';

export class UnionTool {
	features = []
	constructor(onCompleted) {
		this.onCompleted = onCompleted
	}
	select(feature) {
		let elementType = null
		if (feature.getGeometry().getType() !== 'Polygon') {
			window.dispatchEvent(new CustomEvent('warning', { detail: { message: 'Can only combine polygons', details: 'Union tool' } }))
			return
		}
		let e = feature.get('elementName')
		if (e === 'green' || e === 'focus' || e === 'tee') {
			window.dispatchEvent(new CustomEvent('warning', { detail: { message: `Cannot combine elements of type ${e}`, details: 'Union tool' } }))
			return
		}
		if (this.features.length === 1) {
			if (this.features[0] === feature) {
				window.dispatchEvent(new CustomEvent('warning', { detail: { message: 'Cannot select the same feature twice', details: 'Union tool' } }))
				return
			}
			if (!GeometryUtils.doesPolygonsIntersect(this.features[0], feature)) {
				window.dispatchEvent(new CustomEvent('warning', { detail: { message: 'Features do not intersect', details: 'Union tool' } }))
				return
			}
			elementType = this.features[0].get('elementName')
		} else {
			elementType = feature.get('elementName')
		}
		if (elementType !== feature.get('elementName')) {
			window.dispatchEvent(new CustomEvent('warning', { detail: { message: 'Features must be of the same type', details: 'Union tool' } }))
			return
		}

		feature.set('selected', true)
		this.features.push(feature)
		if (this.features.length === 2) {
			let n = this.flatten(this.features)
			this.onCompleted(n, [...this.features])
			this.features = []
		}
	}
	cancel() {
		for (let f of this.features) {
			f.set('selected', false)
		}
		this.features = []
	}
	flatten(features) {
		let f1 = features[0]
		let f2 = features[1]
		let points1 = []
		let points2 = []
		for (let c of f1.getGeometry().getCoordinates()[0]) {
			points1.push(new Flatten.Point(c[0], c[1]))
		}
		for (let c of f2.getGeometry().getCoordinates()[0]) {
			points2.push(new Flatten.Point(c[0], c[1]))
		}

		const p1 = new Flatten.Polygon()
		const face1 = p1.addFace(points1)
		face1.reverse()
		if (face1.orientation() !== Flatten.ORIENTATION.CCW) {
			face1.reverse()
		}

		const p2 = new Flatten.Polygon()
		const face2 = p2.addFace(points2)
		face2.reverse()
		if (face2.orientation() !== Flatten.ORIENTATION.CCW) {
			face2.reverse()
		}
		const p3 = Flatten.BooleanOperations.unify(p1, p2);

		const face = [...p3.faces][0]
		const vertices = [...face.edges].map(edge => edge.start)

		let coordinates = []
		for (let v of vertices) {
			coordinates.push([v.x, v.y])
		}
		coordinates = GeometryUtils.closePath(coordinates)
		let feature = new Feature(new Polygon([coordinates]))
		let props = f1.getProperties()
		feature.set('elementName', props.elementName)
		feature.set('uid', nanoid())
		feature.set('order', props.order)
		if (props.holeId) {
			feature.set('holeId', props.holeId)
		}
		if (props.placeabove !== undefined) {
			feature.set('placeabove', props.placeabove)
		}
		if (props.rounded !== undefined) {
			feature.set('rounded', props.rounded)
		}
		if (props.normalize !== undefined) {
			feature.set('normalize', props.normalize)
		}
		if (props.pointsPerSeg !== undefined) {
			feature.set('pointsPerSeg', props.pointsPerSeg)
		}
		if (props.tension !== undefined) {
			feature.set('tension', props.tension)
		}
		console.warn('fetch all valid properties and merge')
		return feature
	}
}