<script>
	export let isLoading = true;
	export let hideWhenIdle = false;
	export let color = '#280071';
</script>

<div class="loader-container">
	<span class="loader" class:loading={isLoading} class:hide={hideWhenIdle} style="background-color: {color};"></span>
</div>

<style lang="postcss">
	.loader-container {
		transition: all 0.3s ease-in-out;
		display: flex;
		justify-content: center;
		justify-items: center;
	}
	.loader {
		transition: all 0.3s ease-in-out;
		margin: auto;
		width: 15px;
		height: 15px;
		border-radius: 50%;

		box-sizing: border-box;
	}
	.hide {
		visibility: hidden;
	}
	.loading {
		animation: animloader 1s ease-in-out infinite;
	}

	@keyframes animloader {
		0%,
		100% {
			transform: scale(1);
			opacity: 1;
		}
		50% {
			transform: scale(0.1);
			opacity: 0.1;
		}
	}
</style>
