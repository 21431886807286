export class ColorSchemeManager {
	isDarkMode
	colorScheme
	useLocalStorage = true
	constructor(useClasses, defaultScheme, useLocalStorage) {
		this.useClasses = useClasses ?? true
		this.useLocalStorage = useLocalStorage ?? true
		let _this = this;
		if (this.useLocalStorage && window.localStorage.theme !== undefined) {
			this.colorScheme = window.localStorage.theme === 'dark' ? 'dark' : 'light'
			this.isDarkMode = this.colorScheme === 'dark'
		} else {
			this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
			this.colorScheme = defaultScheme ?? (this.isDarkMode ? 'dark' : 'light')
		}
		const query = window.matchMedia('(prefers-color-scheme: dark)');
		query.addEventListener('change', (event) => {
			if (!useClasses) {
				_this.updateScheme(event.matches)
			}
		});
		if (useClasses) {
			window.addEventListener("storage", (event) => {
				if (event.key === 'theme') {
					_this.updateScheme(event.newValue === 'dark', event.newValue)
				}
			});
		}
		this.updateScheme(this.isDarkMode, true)
	}
	updateScheme(isDarkMode, force) {
		if (isDarkMode === this.isDarkMode && !force) {
			return
		}
		window.isDarkMode = isDarkMode
		window.colorScheme = this.colorScheme
		this.colorScheme = isDarkMode ? 'dark' : 'light'
		this.isDarkMode = isDarkMode
		if (this.useLocalStorage) {
			localStorage.setItem('theme', this.colorScheme)
		}
		this.triggerColorSchemeChanged(this.colorScheme, isDarkMode)
		if (this.useClasses) {
			if (isDarkMode) {
				document.documentElement.classList.add('dark');
			} else {
				document.documentElement.classList.remove('dark');
			}
		}
	}
	triggerColorSchemeChanged(colorScheme, isDarkMode) {
		let e = new Event('colorSchemeChanged')
		e.colorScheme = colorScheme
		e.isDarkMode = isDarkMode
		window.dispatchEvent(e);
	}
}