<script>
	import LoadingIndicator from '@Components/LoadingIndicator.svelte';
	import { onMount, getContext } from 'svelte';
	import AsyncHoleRenderer from '@Renderer/AsyncHoleRenderer.js';
	const { getController, getEditorCanvas, getConfig } = getContext('editor');
	import { deconstructHoleId } from '@Utils/DataController.js';
	export let visible = false;
	import { getRenderProperties,getIconHtml } from '@Utils/Utils.js';

	let controller = getController();
	let holeRenderer = new AsyncHoleRenderer();
	let config = getConfig();
	let editorCanvas = getEditorCanvas();
	$: objects = [];
	$: status = 'idle';
	$: isDarkMode = window.isDarkMode;
	$: renderButtonDisabled = true;
	$: cancelled = false;
	$: resetButtonDisabled = false;
	let isSupported = false;
	let convertionCanvas;
	let convertionCanvasContext;
	if (typeof OffscreenCanvas !== 'undefined' || window.chrome === undefined) {
		isSupported = true;
		convertionCanvas = new OffscreenCanvas(import.meta.env.VITE_RENDER_WIDTH, import.meta.env.VITE_RENDER_HEIGHT);
		convertionCanvasContext = convertionCanvas.getContext('bitmaprenderer');
	}
	let renderProperties = getRenderProperties({
		width: import.meta.env.VITE_RENDER_WIDTH,
		height: import.meta.env.VITE_RENDER_HEIGHT,
		backgroundColor: 'white',
		noise: true
	});

	window.addEventListener('colorSchemeChanged', (e) => {
		isDarkMode = e.isDarkMode;
	});
	function setStatus(s) {
		status = s;
		if (status !== 'preparing' && status !== 'rendering') {
			resetButtonDisabled = false;
		} else {
			resetButtonDisabled = true;
		}
		if (status === 'waiting') {
			renderButtonDisabled = false;
		} else {
			renderButtonDisabled = true;
		}
	}
	function getColor(status) {
		if (status == 'error') {
			return '#F9423A';
		}
		if (status == 'waiting') {
			return '#E48B23';
		}
		if (status == 'rendering') {
			return '#B1B5CE';
		}
		if (status == 'saving') {
			return '#B1B5CE';
		}
		if (status == 'done') {
			return '#26D07C';
		}
		if (status == 'invalid') {
			return '#9c8e7f';
		}
	}
	function close() {
		if (status === 'rendering') {
			if (confirm('Are you sure you want to cancel the rendering?')) {
				cancelRendering();
				visible = false;
			}
		} else {
			visible = false;
		}
	}
	function triggerUpdate() {
		objects = [...objects];
	}
	function isLoading(status) {
		return status !== 'waiting' && status !== 'error' && status !== 'done' && status !== 'invalid';
	}
	function isRendering(status) {
		return status === 'rendering'
	}
	async function renderHole(object) {
		let data = editorCanvas.getRenderData(object.hole.holeId);
		let result = await holeRenderer.render(data, renderProperties);
		if(!visible) {
			throw 'cancelled';
		}
		object.renderingResult = result;
		convertionCanvasContext.transferFromImageBitmap(result.image);
		const blob = await convertionCanvas.convertToBlob({ type: import.meta.env.VITE_RENDER_IMAGE_TYPE ?? 'image/jpeg', quality: import.meta.env.VITE_RENDER_IMAGE_QUALITY ?? 0.7 });
		if(!visible) {
			throw 'cancelled';
		}
		let bitMap = await createImageBitmap(blob);
		if(!visible) {
			throw 'cancelled';
		}
		object.blob = blob;
		object.canvas.getContext('2d').clearRect(0, 0, object.canvas.width, object.canvas.height);
		if(!visible) {
			throw 'cancelled';
		}
		object.canvas.getContext('2d').drawImage(bitMap, 0, 0, object.canvas.width, object.canvas.height);
		if(!visible) {
			throw 'cancelled';
		}
	}
	async function cancelRendering() {
		holeRenderer.cancel();
		cancelled = true;
	}
	async function startRenderingQueue() {
		if (cancelled) {
			cancelled = false
			setStatus('waiting');
			return;
		}
		setStatus('rendering');
		let o = objects.find((o) => o.status === 'waiting');
		if (!o) {
			setStatus('finished');
			return;
		}
		o.status = 'rendering';
		triggerUpdate();
		try {
			await renderHole(o);
			o.status = 'saving';
			o.tooltip = 'Saving to server';
			triggerUpdate();
			let [res, err] = await controller.postImage(o.hole.holeId, o.blob, o.renderingResult.data);
			if (!err) {
				o.status = 'done';
				o.tooltip = '';
				o.hole.hasChange = false;
			} else {
				if (e === 'cancelled') {
					o.status = 'waiting';
					o.tooltip = 'Cancelled';
				} else {
					o.status = 'error';
					o.error = e;
					let event = new Event('error');
					event.data = {
						type: 'renderError',
						message: 'Error rendering hole',
						error: e
					};
					window.dispatchEvent(event);
				}
			}
		} catch (e) {
			if (e === 'cancelled') {
				o.status = 'waiting';
				o.tooltip = 'Cancelled';
			} else {
				o.status = 'error';
				o.error = e;
				let event = new Event('error');
				event.data = {
					type: 'renderError',
					message: 'Error rendering hole',
					error: e
				};
				window.dispatchEvent(event);
			}
		}
		triggerUpdate();
		startRenderingQueue();
	}
	function sortingByName(a, b) {
		const nameA = parseInt(a.displayName, 10); // ignore upper and lowercase
		const nameB = parseInt(b.displayName, 10); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	}
	async function resetCompleted() {
		for (let o of objects) {
			if (o.status === 'done') {
				o.status = 'waiting';
				o.tooltip = 'Waiting for render';
			}
		}
		objects = [...objects];
		if (objects.find((o) => o.status === 'waiting')) {
			setStatus('waiting');
		} else {
			setStatus('nochanges');
		}
	}
	async function prepare() {
		if (status !== 'idle') {
			return;
		}
		renderProperties = getRenderProperties({
			width: import.meta.env.VITE_RENDER_WIDTH,
			height: import.meta.env.VITE_RENDER_HEIGHT,
			backgroundColor: 'white',
			noise: true
		});
		renderProperties.noise = true;
		setStatus('preparing');
		/*await new Promise((r) => setTimeout(r, 10000));*/
		await controller.save();
		let arr = [];
		for (let hole of controller.data.holes.sort(sortingByName)) {
			let status = controller.holeRenderStatus(hole.holeId);
			let o = {
				hole: hole,
				displayName: deconstructHoleId(hole.holeId).displayName,
				canvas: null,
				status: status.status,
				tooltip: status.tooltip
			};
			arr.push(o);
		}
		objects = arr;
		if (objects.find((o) => o.status === 'waiting')) {
			setStatus('waiting');
		} else {
			setStatus('nochanges');
		}
		setTimeout(() => {
			for (let o of arr) {
				if (o.hole.image && o.hole.image.type) {
					createImageBitmap(o.hole.image)
						.then((bitMap) => {
							if (o.canvas) {
								o.canvas.getContext('2d').clearRect(0, 0, o.canvas.width, o.canvas.height);
								o.canvas.getContext('2d').drawImage(bitMap, 0, 0, o.canvas.width, o.canvas.height);
							}
						})
						.catch((e) => {
							console.error(e);
						});
				} else if (o.hole.holeImageUrl) {
					let image = new Image();
					image.onload = () => {
						if (o.canvas) {
							o.canvas.getContext('2d').clearRect(0, 0, o.canvas.width, o.canvas.height);
							o.canvas.getContext('2d').drawImage(image, 0, 0, o.canvas.width, o.canvas.height);
						}
					};
					image.src = o.hole.holeImageUrl;
				}
			}
		}, 100);
	}
	$: {
		if (visible) {
			prepare();
		}
	}
	onMount(() => {
		if (visible) {
			prepare();
		}
	});
</script>

{#if visible}
	<div class="render-progress">
		<div class="overlay box" class:unsupported={!isSupported}>
			<!--LoadingIndicator isLoading={false} hideWhenIdle={true} /-->
			<div class="content">
				<div class="header bg-white dark:bg-bigsteel-700/30">
					<div class="markup" style="width: 50%">
						{#if cancelled}
							<h1>Cancelled!</h1>
							<p><em>Rendering cancelled</em></p>
							<p>Waiting for renderer to respond</p>
						{:else if !isSupported}
							<h1>Unsupported browser</h1>
							<p>We require <a href="https://www.google.com/chrome/">Google chrome</a> or <a href="https://www.microsoft.com/edge">Microsoft Edge</a> to render your images.</p>
							<p>Feel free to go back to golfoffice or continue editing.</p>
						{:else if status === 'finished'}
							<h1>Finished!</h1>
							<p>The mapping tool has completed rendering and saving your holes.</p>
							<p>Feel free to go back to golfoffice or continue editing.</p>
						{:else if status === 'rendering'}
							<h1>Rendering and saving</h1>
							<p>The mapping tool is now rendering your images and saving them to golfoffice.</p>
							<p><em>Do not close your browser</em> or the process will be terminated</p>
						{:else if status === 'waiting' || status === 'preparing'}
							<h1>Rendering your holes</h1>
							<p>When you feel ready you can render images for your holes.</p>
							<p>Remember that it might take some time and if you close your browser before the render has completed you will need to render again.</p>
						{:else if status === 'nochanges'}
							<h1>Nothing to render</h1>
							<p>You don't have any changes to render.</p>
							<p>Feel free to go back to golfoffice or continue editing.</p>
						{/if}
					</div>
					<button class="close-button" on:click={close}>{@html getIconHtml('Close', 'dark:bg-white')}</button>
				</div>
				{#if isSupported}
					{#if objects.length < 1 && status === 'preparing'}
						<div class="preparing">
							<h1>Preparing</h1>
							<br />
							<LoadingIndicator isLoading={true} color="#E48B23" />
						</div>
					{:else}
						<div class="grid">
							{#each objects as object}
								<div class="item status-{object.status} bg-white dark:bg-bigsteel-800">
									<canvas class="image" width="111" height={111 * (import.meta.env.VITE_RENDER_HEIGHT / import.meta.env.VITE_RENDER_WIDTH)} bind:this={object.canvas} />
									<div class="footer">
										Hole <span>{object.displayName}</span>
									</div>
									<div class="footer" tooltip={object.tooltip}>
										{object.status}
										<LoadingIndicator isLoading={isLoading(object.status)} color={getColor(object.status)} />
									</div>
								</div>
							{/each}
						</div>
					{/if}
				{/if}
				<div class="buttons bg-white dark:bg-bigsteel-700/30">
					<button
						class:btn-solid-white={isDarkMode}
						class:btn-outline-blue={!isDarkMode}
						class="btn btn-sm"
						disabled={isRendering(status)}
						on:click={() => {
							visible = false;
						}}>Continue editing</button
					>
					<button
						class:btn-solid-white={isDarkMode}
						class:btn-outline-blue={!isDarkMode}
						class="btn btn-sm"
						disabled={isRendering(status)}
						on:click={() => {
							window.parent.postMessage(
								{
									action: 'closeMap'
								},
								config.golfOfficeFrontendURL
							);
						}}>Back to golfoffice</button
					>
					<button
						class:btn-solid-white={isDarkMode}
						class:btn-outline-blue={!isDarkMode}
						class="btn btn-sm"
						disabled={resetButtonDisabled}
						tooltip="Reset status and re-render all valid holes."
						on:click={() => {
							resetCompleted();
						}}>Mark for rendering</button
					>
					<div class="spacer"></div>
					{#if status === 'rendering' && cancelled == false}
						<button
							class:btn-solid-red={isDarkMode}
							class:btn-outline-red={!isDarkMode}
							class="btn btn-sm"
							tooltip="Cancel the current rendering queue. Completed images has been saved."
							on:click={() => {
								cancelRendering();
							}}>Cancel render</button
						>
					{/if}
					<button
						disabled={renderButtonDisabled}
						class="btn btn-sm btn-solid-blue"
						on:click={() => {
							renderButtonDisabled = true;
							cancelled = false;
							startRenderingQueue();
						}}>Start rendering</button
					>
				</div>
			</div>
		</div>
	</div>
{/if}

<style lang="postcss">
	.render-progress {
		font-family: 'Tietoevry sans 1';
		position: absolute;
		z-index: 100;
		top: 0px;
		left: 0px;
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		justify-content: center;
		justify-items: center;
	}
	.overlay {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		margin: 40px;
		box-sizing: border-box;
		border-radius: 16px;
		height: 100%;
	}
	.unsupported {
		width: 500px;
		margin: auto;
	}
	.header {
		padding: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding-bottom: 10px;
	}
	.spacer {
		flex: 1;
	}
	.content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		flex: 1;
		gap: 0px;
		overflow-y: auto;
	}
	.buttons {
		display: flex;
		flex-direction: row;
		gap: 10px;
		justify-content: flex-end;
		padding: 20px;
	}
	.markup {
		flex: 1;
	}
	a {
		color: rgba(24, 97, 242, 1);
		font-weight: 600;
	}
	.grid {
		box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		padding: 20px;
		display: grid;
		overflow-y: auto;
		justify-items: center;
		grid-template-columns: repeat(9, 1fr);
		gap: 30px;
		flex: 1;
	}
	.preparing {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		flex: 1;
	}
	.item {
		height: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		padding: 10px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	}
	.name {
		font-size: 14px;
		font-weight: 600;
		margin-top: 4px;
		text-align: left;
		width: 100%;
	}
	.item .image {
		pointer-events: none;
		transition: background-color 0.15s ease-in-out;
		flex: 1;
		opacity: 0.5;
		background-color: rgb(228, 139, 35, 0.5);
	}
	.status-done .image {
		opacity: 1;
		background-color: rgba(0, 0, 0, 0.2);
	}
	.status-invalid .image {
		background-color: rgb(156, 142, 127, 0.2);
	}
	.status-saving .image {
		background-color: rgb(177, 181, 206, 0.2);
	}
	.item .footer {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 10px;
		justify-content: space-between;
		font-size: 14px;
		text-transform: capitalize;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		padding: 5px 0px;
	}
	.item .footer:first-of-type {
		border-top: none;
	}
	.item .footer:last-of-type {
		padding-bottom: 0px;
	}

	@media (max-width: 1550px) {
		.grid {
			grid-template-columns: repeat(6, 1fr);
		}
	}
	@media (max-width: 1300px) {
		.grid {
			grid-template-columns: repeat(5, 1fr);
		}
	}
	@media (max-width: 1000px) {
		.grid {
			grid-template-columns: repeat(4, 1fr);
		}
	}
</style>
