import Elements from '@Assets/Elements.json';

const dictionary = {};
const elementsArray = Elements;

for (let e of Elements) {
	if (!e.defaultValues) {
		e.defaultValues = {};
	}
	dictionary[e.name] = e;
}
export function getElementsArray() {
	return elementsArray;
}
export function getElementsDictionary() {
	return dictionary;
}
export function getElement(name) {
	return dictionary[name];
}