import { Utils } from '@Utils/Utils.js';
import { SVGPathDataParser, SVGPathData } from 'svg-pathdata';
import { createCanvas } from '@Utils/Utils.js';

import { Timer } from '@Utils/Timer.js';
let timer = new Timer(true)


export function getContext(canvas) {
	return canvas.getContext('2d', {
		desynchronized: true,
		preserveDrawingBuffer: true,
		willReadFrequently :true
	});
}
function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
export function isClockwise(coordinates) {
	let sum = 0
	for (let i = 0; i < coordinates.length; i++) {
		let current = coordinates[i];
		let next = coordinates[(i + 1) % coordinates.length];
		sum += (next[0] - current[0]) * (next[1] + current[1]);
	}
	return sum > 0;
}

export async function getCenter(coordinates) {
	let center = coordinates.reduce(
		function (x, y) {
			return [x[0] + y[0] / coordinates.length, x[1] + y[1] / coordinates.length];
		},
		[0, 0]
	);
	return center;
}
export function stringify(coordinates, type) {
	if (coordinates[0] && coordinates[0][2]) {
		if (type === undefined) {
			type = 'Polygon';
		}
		if (type == 'Polygon') {
			return coordinates.map((c, i) => (i ? `${c[2]}${c[0]},${c[1]}` : `${c[2]}${c[0]},${c[1]}`)).join(' ') + 'Z';
		} else {
			return coordinates.map((c, i) => `${c[2]}${c[0]},${c[1]}`).join(' ');
		}
	}
	if (type === undefined) {
		type = 'Polygon';
	}
	if (type == 'Polygon') {
		return coordinates.map((c, i) => (i ? `${c[0]} ${c[1]}` : `M${c[0]} ${c[1]}`)).join(' ') + 'Z';
	} else {
		return coordinates.map((c, i) => `${c[0]},${c[1]}`).join(' ');
	}
}
export function createRectanle(width, height) {
	return [
		[0, 0],
		[width, 0],
		[width, height],
		[0, height],
		[0, 0]
	];
}
export async function translate(coordinates, x, y) {
	let coords = [];
	for (let i in coordinates) {
		if (coordinates[i][2] === undefined) {
			coords.push([coordinates[i][0] + x, coordinates[i][1] + y]);
		} else {
			coords.push([coordinates[i][0] + x, coordinates[i][1] + y, coordinates[i][2]]);
		}
	}
	return coords;
}
export async function getBoundingBox(coordinates) {
	let maxY = Number.MIN_SAFE_INTEGER;
	let maxX = Number.MIN_SAFE_INTEGER;
	let minY = Number.MAX_SAFE_INTEGER;
	let minX = Number.MAX_SAFE_INTEGER;
	for (let c of coordinates) {
		if (c[0] > maxX) {
			maxX = c[0];
		}
		if (c[0] < minX) {
			minX = c[0];
		}
		if (c[1] > maxY) {
			maxY = c[1];
		}
		if (c[1] < minY) {
			minY = c[1];
		}
	}
	return {
		maxY: maxY,
		maxX: maxX,
		minY: minY,
		minX: minX,
		width: Math.ceil(maxX - minX),
		height: Math.ceil(maxY - minY)
	};
}
export async function processSVGShape(properties) {
	// timer.time(`processCoordinates-${properties.name}`)
	let padding = properties.padding === undefined ? 0 : properties.padding;
	let convexPadding = properties.convexPadding === undefined ? true : properties.convexPadding;
	let paddingGradient = properties.paddingGradient === undefined ? false : properties.paddingGradient;
	let paddingGradientRotation = properties.paddingGradientRotation === undefined ? 0 : properties.paddingGradientRotation;
	let paddingGradientOpacity = properties.paddingGradientOpacity === undefined ? 0 : properties.paddingGradientOpacity;
	let paddingGradientOffset = properties.paddingGradientOffset === undefined ? 0 : properties.paddingGradientOffset;
	let insetGradient = properties.insetGradient === undefined ? false : properties.insetGradient;
	let insetGradientRotation = properties.insetGradientRotation === undefined ? 0 : properties.insetGradientRotation;
	let insetGradientOpacity = properties.insetGradientOpacity === undefined ? 0 : properties.insetGradientOpacity;
	let insetGradientOffset = properties.insetGradientOffset === undefined ? 0 : properties.insetGradientOffset;
	let inset = properties.inset === undefined ? 0 : properties.inset;
	let convexInset = properties.convexInset === undefined ? true : properties.convexInset;

	let smoothness = properties.smoothness === undefined ? 0 : properties.smoothness;


	let strength = properties.strength === undefined ? 300 : properties.strength;
	let paddingInPixels = properties.paddingInPixels === undefined ? false : properties.paddingInPixels;
	let insetInPixels = properties.insetInPixels === undefined ? false : properties.insetInPixels;

	let resolution = properties.resolution === undefined ? 1 : properties.resolution;
	let bbox = properties.bbox;
	let width = bbox.width;
	let height = bbox.height;
	let size = width > height ? height : width;
	let paddingWidth = padding;
	let insetWidth = inset;

	if (!paddingInPixels) {
		paddingWidth = Math.ceil(size * (padding / 100));
	} else {
		paddingWidth = padding / resolution;
		if (paddingWidth > size) {
			paddingWidth = size;
		}
	}
	if (!insetInPixels) {
		insetWidth = Math.ceil(size * (inset / 100));
	} else {
		insetWidth = inset / resolution;
		if (insetWidth > width) {
			insetWidth = width;
		}
	}
	smoothness = clamp(Math.ceil(((paddingWidth + insetWidth) * smoothness) / 100), 0, 10);
	let blurPadding = smoothness * 10;
	let blur = smoothness;
	let res = {
		bbox: bbox,
		originalProperties: properties,
		padding: padding,
		paddingWidth: paddingWidth,
		convexPadding: convexPadding,
		paddingGradientRotation: paddingGradientRotation,
		paddingGradientOpacity: paddingGradientOpacity,
		paddingGradientOffset: paddingGradientOffset,
		paddingGradient: paddingGradient,
		insetGradient: insetGradient,
		insetGradientRotation: insetGradientRotation,
		insetGradientOpacity: insetGradientOpacity,
		insetGradientOffset: insetGradientOffset,
		insetWidth: insetWidth,
		textureOpacity: properties.textureOpacity ?? 1,
		convexInset: convexInset,
		inset: inset,
		enhanceDepthInset: properties.enhanceDepthInset,
		initialWidth: width,
		initialHeight: height,
		width: width + paddingWidth + blurPadding,
		height: height + paddingWidth + blurPadding,
		blur: blur,
		path: properties.path,
		color: properties.color,
		pathString: properties.pathString,
		name: properties.name,
		smoothElevation: properties.smoothElevation,
		enhanceDepth: properties.enhanceDepth,
		strength: strength,
		x: bbox.minX - (paddingWidth / 2 + blurPadding / 2),
		y: bbox.minY - (paddingWidth / 2 + blurPadding / 2),
		translatedX: paddingWidth / 2 + blurPadding / 2,
		translatedY: paddingWidth / 2 + blurPadding / 2
	};
	// timer.timeEnd(`processCoordinates-${properties.name}`)
	return res;
}
export async function processCoordinates(coordinates, properties) {
	// timer.time(`processCoordinates-${properties.name}`)
	let padding = properties.padding === undefined ? 0 : properties.padding;
	let inset = properties.inset === undefined ? 0 : properties.inset;
	let smoothness = properties.smoothness === undefined ? 0 : properties.smoothness;
	let convexPadding = properties.convexPadding === undefined ? true : properties.convexPadding;
	let convexInset = properties.convexInset === undefined ? true : properties.convexInset;
	let flatSurfaceEdgeSmoothing = properties.flatSurfaceEdgeSmoothing === undefined ? 0 : properties.flatSurfaceEdgeSmoothing;
	let paddingGradient = properties.paddingGradient === undefined ? false : properties.paddingGradient;
	let paddingGradientRotation = properties.paddingGradientRotation === undefined ? 0 : properties.paddingGradientRotation;
	let paddingGradientOpacity = properties.paddingGradientOpacity === undefined ? 0 : properties.paddingGradientOpacity;
	let paddingGradientOffset = properties.paddingGradientOffset === undefined ? 0 : properties.paddingGradientOffset;
	let insetGradient = properties.insetGradient === undefined ? false : properties.insetGradient;
	let insetGradientRotation = properties.insetGradientRotation === undefined ? 0 : properties.insetGradientRotation;
	let insetGradientOpacity = properties.insetGradientOpacity === undefined ? 0 : properties.insetGradientOpacity;
	let insetGradientOffset = properties.insetGradientOffset === undefined ? 0 : properties.insetGradientOffset;
	let strength = properties.strength === undefined ? 300 : properties.strength;
	let paddingInPixels = properties.paddingInPixels === undefined ? false : properties.paddingInPixels;
	let insetInPixels = properties.insetInPixels === undefined ? false : properties.insetInPixels;
	let scatterInset = properties.scatterInset === undefined ? 0 : properties.scatterInset;
	let scatterPadding = properties.scatterPadding === undefined ? 0 : properties.scatterPadding;
	let scatterInsetInPixels = properties.scatterInsetInPixels === undefined ? false : properties.scatterInsetInPixels;
	let scatterPaddingInPixels = properties.scatterPaddingInPixels === undefined ? false : properties.scatterPaddingInPixels;
	let resolution = properties.resolution === undefined ? 1 : properties.resolution;
	let bbox = properties.bbox === undefined ? await getBoundingBox(coordinates) : properties.bbox;
	let clampedCoordinates = properties.clampedCoordinates === undefined ? await translate(coordinates, -bbox.minX, -bbox.minY) : coordinates;
	let width = bbox.width;
	let height = bbox.height;
	let size = width > height ? height : width;
	let paddingWidth = padding;
	let insetWidth = inset;
	let scatterPaddingWidth = scatterPadding;
	let scatterInsetWidth = scatterInset;
	let flatSurfaceEdgeSmoothingWidth = (flatSurfaceEdgeSmoothing / 100) * 300 * properties.outputScale
	if (!scatterPaddingInPixels) {
		scatterPaddingWidth = Math.ceil(size * (scatterPadding / 100));
	} else {
		scatterPaddingWidth = scatterPadding / resolution;
		if (scatterPaddingWidth > size) {
			scatterPaddingWidth = size;
		}
	}
	if (!scatterInsetInPixels) {
		scatterInsetWidth = Math.ceil(size * (scatterInset / 100));
	} else {
		scatterInsetWidth = scatterInset / resolution;
		if (scatterInsetWidth > width) {
			scatterInsetWidth = width;
		}
	}

	if (!paddingInPixels) {
		paddingWidth = Math.ceil(size * (padding / 100));
	} else {
		paddingWidth = padding / resolution;
		if (paddingWidth > size) {
			paddingWidth = size;
		}
	}
	if (!insetInPixels) {
		insetWidth = Math.ceil(size * (inset / 100));
	} else {
		insetWidth = inset / resolution;
		if (insetWidth > width) {
			insetWidth = width;
		}
	}
	let padd = Math.max(paddingWidth, scatterPaddingWidth, flatSurfaceEdgeSmoothingWidth);
	if (properties.enhanceDepth) {
		padd += 4;
	}
	smoothness = clamp(Math.ceil(((paddingWidth + insetWidth) * smoothness) / 100), 0, 18) * properties.outputScale;
	let blurPadding = smoothness * 10;
	let blur = smoothness;
	let res = {
		bbox: bbox,
		originalProperties: properties,
		padding: padding,
		insetWidth: insetWidth,
		paddingWidth: paddingWidth,
		scatterInsetWidth: scatterInsetWidth,
		scatterPaddingWidth: scatterPaddingWidth,
		convexPadding: convexPadding,
		convexInset: convexInset,
		flatSurfaceEdgeSmoothing: flatSurfaceEdgeSmoothing,
		flatSurfaceEdgeSmoothingWidth: flatSurfaceEdgeSmoothingWidth,
		paddingGradientRotation: paddingGradientRotation,
		paddingGradientOpacity: paddingGradientOpacity,
		paddingGradientOffset: paddingGradientOffset,
		paddingGradient: paddingGradient,
		insetGradient: insetGradient,
		insetGradientRotation: insetGradientRotation,
		insetGradientOpacity: insetGradientOpacity,
		insetGradientOffset: insetGradientOffset,
		inset: inset,
		enhanceDepthInset: properties.enhanceDepthInset,
		textureOpacity: properties.textureOpacity ?? 1,
		initialWidth: width,
		initialHeight: height,
		width: width + padd + blurPadding,
		height: height + padd + blurPadding,
		blur: blur,
		path: properties.path,
		color: properties.color,
		pathString: properties.pathString,
		name: properties.name,
		smoothElevation: properties.smoothElevation,
		enhanceDepth: properties.enhanceDepth,
		strength: strength,
		coordinates: coordinates,
		x: bbox.minX - (padd / 2 + blurPadding / 2),
		y: bbox.minY - (padd / 2 + blurPadding / 2),
		translatedX: padd / 2 + blurPadding / 2,
		translatedY: padd / 2 + blurPadding / 2,
		translated: await translate(clampedCoordinates, padd / 2 + blurPadding / 2, padd / 2 + blurPadding / 2)
	};
	// timer.timeEnd(`processCoordinates-${properties.name}`)
	return res;
}
export async function outOfBounds(coordinates, size) {
	for (let c of coordinates) {
		if (c[0] >= 0 && c[1] >= 0 && c[0] <= size[0] && c[1] <= size[1]) {
			return false;
		}
	}
	return true;
}
export async function processLineCoordinates(coordinates, properties) {
	// timer.time(`processLineCoordinates-${properties.name}`)
	let padding = properties.padding === undefined ? 0 : properties.padding;
	let inset = properties.inset === undefined ? 0 : properties.inset;
	let smoothness = properties.smoothness === undefined ? 0 : properties.smoothness;
	let convexPadding = properties.convexPadding === undefined ? true : properties.convexPadding;
	let convexInset = properties.convexInset === undefined ? true : properties.convexInset;
	let paddingGradient = properties.paddingGradient === undefined ? false : properties.paddingGradient;
	let paddingGradientRotation = properties.paddingGradientRotation === undefined ? 0 : properties.paddingGradientRotation;
	let paddingGradientOpacity = properties.paddingGradientOpacity === undefined ? 0 : properties.paddingGradientOpacity;
	let paddingGradientOffset = properties.paddingGradientOffset === undefined ? 0 : properties.paddingGradientOffset;
	let insetGradient = properties.insetGradient === undefined ? false : properties.insetGradient;
	let insetGradientRotation = properties.insetGradientRotation === undefined ? 0 : properties.insetGradientRotation;
	let insetGradientOpacity = properties.insetGradientOpacity === undefined ? 0 : properties.insetGradientOpacity;
	let insetGradientOffset = properties.insetGradientOffset === undefined ? 0 : properties.insetGradientOffset;
	let strength = properties.strength === undefined ? 300 : properties.strength;
	let resolution = properties.resolution === undefined ? 1 : properties.resolution;
	let lineWidth = properties.lineWidth === undefined ? 10 : properties.lineWidth;
	let scatterInset = properties.scatterInset === undefined ? 0 : properties.scatterInset;
	let scatterPadding = properties.scatterPadding === undefined ? 0 : properties.scatterPadding;
	let bbox = await getBoundingBox(coordinates);
	let clampedCoordinates = await translate(coordinates, -bbox.minX, -bbox.minY);
	let width = bbox.width;
	let height = bbox.height;

	lineWidth = lineWidth / resolution;
	let paddingWidth = padding / resolution;
	let insetWidth = inset / resolution;
	let scatterInsetWidth = scatterInset / resolution;
	let scatterPaddingWidth = scatterPadding / resolution;
	smoothness = clamp(Math.ceil(((paddingWidth + insetWidth) * smoothness) / 100), 0, 10);
	let blurPadding = smoothness * 10;
	let blur = smoothness;
	let res = {
		bbox: bbox,
		padding: padding,
		originalProperties: properties,
		insetWidth: insetWidth,
		scatterInsetWidth: scatterInsetWidth,
		scatterPaddingWidth: scatterPaddingWidth,
		paddingGradientRotation: paddingGradientRotation,
		paddingGradientOpacity: paddingGradientOpacity,
		paddingGradientOffset: paddingGradientOffset,
		paddingGradient: paddingGradient,
		insetGradient: insetGradient,
		insetGradientRotation: insetGradientRotation,
		insetGradientOpacity: insetGradientOpacity,
		insetGradientOffset: insetGradientOffset,
		paddingWidth: paddingWidth,
		convexPadding: convexPadding,
		convexInset: convexInset,
		inset: inset,
		enhanceDepthInset: properties.enhanceDepthInset,
		lineWidth: lineWidth,
		initialWidth: width,
		initialHeight: height,
		width: width + paddingWidth + blurPadding + insetWidth + lineWidth,
		textureOpacity: properties.textureOpacity ?? 1,
		height: height + paddingWidth + blurPadding + insetWidth + lineWidth,
		enhanceDepth: properties.enhanceDepth,
		blur: blur,
		pathString: properties.pathString,
		name: properties.name,
		strength: strength,
		coordinates: coordinates,
		x: bbox.minX - (paddingWidth / 2 + blurPadding / 2 + insetWidth / 2 + lineWidth / 2),
		y: bbox.minY - (paddingWidth / 2 + blurPadding / 2 + insetWidth / 2 + lineWidth / 2),
		translated: await translate(clampedCoordinates, paddingWidth / 2 + blurPadding / 2 + insetWidth / 2 + lineWidth / 2, paddingWidth / 2 + blurPadding / 2 + insetWidth / 2 + lineWidth / 2)
	};
	// timer.timeEnd(`processLineCoordinates-${properties.name}`)
	return res;
}
export async function renderImages(images, width, height) {
	timer.time(`TextureMapShapeUtils.renderImages`);
	let canvas = createCanvas(width, height, 'TextureMapShapeUtils.renderImages');
	let context = getContext(canvas);
	for (let i of images) {
		let x = Math.ceil(i.x - i.image.width / 2);
		let y = Math.ceil(i.y - i.image.height / 2);
		context.drawImage(i.image, x, y);
	}
	timer.timeEnd(`TextureMapShapeUtils.renderImages`);
	return await createImageBitmap(canvas)
}
export async function renderShadows(images, width, height, resolution) {
	if (resolution === undefined) {
		resolution = 1;
	}
	timer.time(`TextureMapShapeUtils.renderShadows`);
	let imageCanvas = createCanvas(width, height, 'TextureMapShapeUtils.renderShadows');
	let imageContext = getContext(imageCanvas);
	for (let i of images) {
		let w = i.shadow.width;
		let h = i.shadow.height;
		if (i.textureImageSize) {
			/// magic number 5 is the padding of the image in contrast to a tree. The shadow image should be 1000w x 1000h but is actually 200w x 200h.
			let scale = i.textureImageSize * 5;
			w = scale;
			h = scale;
		}

		let x = i.x - w / 2;
		let y = i.y - h / 2;
		imageContext.drawImage(i.shadow, x, y, w, h);
	}
	let renderCanvas = createCanvas(width, height, 'TextureMapShapeUtils.renderShadows.renderCanvas');
	let blur = 20 * Math.abs(1 - resolution);
	let renderContext = getContext(renderCanvas);
	renderContext.filter = `blur(${blur}px)`;
	renderContext.globalCompositeOperation = 'soft-light';
	renderContext.drawImage(imageCanvas, 0, 0);
	timer.timeEnd(`TextureMapShapeUtils.renderShadows`);
	return await createImageBitmap(renderCanvas)
}
export async function hide(data, r, g, b) {
	for (let i = 0; i < data.length; i += 4) {
		if (data[i] == r && data[i + 1] == g && data[i + 2] == b) {
			data[i + 3] = 0;
		}
	}
}
export async function scatter(bitmap, m, min) {
	if (m === undefined) {
		m = 2
	}
	if (min === undefined) {
		min = 200
	}
	let canvas = createCanvas(bitmap.width, bitmap.height, 'TextureMapShapeUtils.scatter');
	let context = getContext(canvas);
	context.drawImage(bitmap, 0, 0);
	let data = context.getImageData(0, 0, bitmap.width, bitmap.height);
	for (let i = 0; i < data.data.length; i += 4) {
		data.data[i + 3] = Utils.getRandomInt(0, m) % m === 0 ? min : 255;
	}
	context.clearRect(0, 0, canvas.width, canvas.height);
	context.putImageData(data, 0, 0);
	return await createImageBitmap(canvas)
}
export async function applyNormalMap(textureData, normalData, lightPosition, options, enhanceDepth) {
	timer.time('applyNormalMap');
	let shininess = options.shininess || 0;
	let brightness = options.brightness === undefined ? 1 : options.brightness;
	let ambient = options.ambient === undefined ? 0.5 : options.ambient;
	enhanceDepth = enhanceDepth === undefined ? false : enhanceDepth;
	let normals = [];

	let width = textureData.width;
	let height = textureData.height;
	let imageDataLength = height * width * 4;
	let max = 255;

	// precalculate the normal vectors
	for (let i = 0; i < imageDataLength; i += 4) {
		// normalizes vector values across a -1, 1 scale
		let nx = (normalData[i] * 2 - max) / max;
		let ny = ((max - normalData[i + 1]) * 2 - max) / max;
		let nz = (normalData[i + 2] * 2 - max) / max;

		normals.push(nx, ny, nz);
	}
	let lx = lightPosition.x,
		ly = lightPosition.y,
		lz = lightPosition.z;

	let shine = shininess;
	let ipx = 0,
		inorm = 0;
	let x, y;
	let dx, dy, dz, inverseMagnitude, dot, intensity, channelValue;

	// adjust intensity for every pixel
	for (y = 0; y < height; y++) {
		for (x = 0; x < width; x++) {
			// calculate the light direction vector
			dx = lx - x;
			dy = ly - y;
			dz = lz; // texture is at 0

			// unit vector of direction (inverted to save on div calcs.)
			inverseMagnitude = 1 / Math.sqrt(dx * dx + dy * dy + dz * dz);
			dx *= inverseMagnitude;
			dy *= inverseMagnitude;
			dz *= inverseMagnitude;

			// dot product of the direction and the normal
			dot = dx * normals[inorm++] + dy * normals[inorm++] + dz * normals[inorm++];
			intensity = dot * brightness;
			//intensity += Math.pow(dot, 10) * shine;
			intensity += ambient;

			// inlined and unrolled for perf
			// if (normalData[ipx] == 128 && normalData[ipx + 1] == 128 && normalData[ipx + 2] == 255) {
			//     ipx += 4; // index of next pixel (skip alpha channel)
			//     continue
			// }
			// if(enhanceDepth) {
			//     if(intensity > 1) {
			//         intensity = 1 + (1 - intensity)
			//     }
			//     intensity += 1
			// }
			channelValue = textureData.data[ipx] * intensity;
			textureData.data[ipx] = ~~channelValue;
			channelValue = textureData.data[++ipx] * intensity;
			textureData.data[ipx] = ~~channelValue;
			channelValue = textureData.data[++ipx] * intensity;
			textureData.data[ipx] = ~~channelValue;
			ipx += 2; // index of next pixel (skip alpha channel)
		}
	}
	timer.timeEnd('applyNormalMap');
}

export function clamp(value, low, high) {
	low = low !== undefined ? low : Number.MIN_SAFE_INTEGER;
	high = high !== undefined ? high : Number.MAX_SAFE_INTEGER;
	if (value < low) {
		value = low;
	}
	if (value > high) {
		value = high;
	}
	return value;
}

function normalize(vec) {
	const mag = Math.sqrt(vec[0] ** 2 + vec[1] ** 2 + vec[2] ** 2);
	return [vec[0] / mag, vec[1] / mag, vec[2] / mag];
}
function getPx(imageData, col, row) {
	col = clamp(col, 0, imageData.width);
	row = clamp(row, 0, imageData.height);
	const offset = row * imageData.width * 4 + col * 4;
	return [imageData.data[offset + 0] / 255, imageData.data[offset + 1] / 255, imageData.data[offset + 2] / 255, imageData.data[offset + 3] / 255];
}
function setPx(imageData, col, row, val) {
	col = clamp(col, 0, imageData.width);
	row = clamp(row, 0, imageData.height);
	const offset = row * imageData.width * 4 + col * 4;
	imageData.data[offset + 0] = val[0] * 255;
	imageData.data[offset + 1] = val[1] * 255;
	imageData.data[offset + 2] = val[2] * 255;
	imageData.data[offset + 3] = val[3] * 255;
}
function reset(imageData, col, row) {
	const offset = row * imageData.width * 4 + col * 4;
	(imageData.data[offset + 0] = 128), (imageData.data[offset + 1] = 128), (imageData.data[offset + 2] = 255), (imageData.data[offset + 3] = 255);
}
function extractPathAndColorFromSVGString(string) {
	let regex = /\sd="([\w\d,\.\s]*)"/gm;
	let m = regex.exec(string);
	if (m == null || m.length < 2) {
		return null;
	}
	let path = m[1];
	regex = /\sfill="(#[\w\d]*)"/gm;
	m = regex.exec(string);
	if (m == null || m.length < 2) {
		return null;
	}
	let color = m[1];
	return [path, color];
}
export async function processSVGPolygon(string, rotation, size) {
	let [path, color] = extractPathAndColorFromSVGString(string);
	let scale = size / 150;
	const parser = new SVGPathDataParser();
	let pathCoordinates = parser.parse(path);
	let data = new SVGPathData(pathCoordinates);
	let bbox = data.getBounds();

	(bbox.width = Math.ceil(bbox.maxX - bbox.minX)), (bbox.height = Math.ceil(bbox.maxY - bbox.minY));

	if (rotation && rotation != 0 && rotation != 360) {
		let angle = Utils.degreesToRadians(rotation ?? 0);
		data.rotate(angle, 150 / 2, 150 / 2);
	}
	if (scale != 1) {
		data.scale(scale, scale);
	}
	bbox = data.getBounds();
	bbox.width = 150 * scale;
	bbox.height = 150 * scale;
	let obj = {
		color: color,
		bbox: bbox,
		size: size
	};
	obj = await processSVGShape(obj);
	obj.pathString = data.encode();
	obj.path = new Path2D(obj.pathString);
	return obj;
}
export function display(arr) {
	if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
		return
	}
	for (let a of arr) {
		body.appendChild(a);
	}
}
export function displayShape(options) {
	if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
		return
	}
	if (options['getContext']) {
		document.body.appendChild(options);
	} else if (options.canvas) {
		document.body.appendChild(options.canvas);
	} else if (options.imageData) {
		let canvas = createCanvas(options.imageData.width, options.imageData.height);
		let context = canvas.getContext('2d');
		context.putImageData(options.imageData, 0, 0);
		document.body.appendChild(canvas);
	}
}

// export async function createTextureWithScatteredEdges(size, width, height, shapeData, color) {
// 	let base = createScatterMap(shapeData)
// 	let shapeCanvas = createCanvas(width, height);
// 	let shapeContext = shapeCanvas.getContext('2d');
// 	shapeContext.strokeStyle = 'black';
// 	shapeContext.fillStyle = 'black';
// 	shapeContext.fill(shapeData.path);
// 	let alpha = 255 / size
// 	for (let i = size; i >= 0; i--) {
// 		let pattern = await createScatterPattern(50, i, 'black', alpha, 255)
// 		shapeContext.lineWidth = i;
// 		shapeContext.strokeStyle = pattern
// 		shapeContext.stroke(shapeData.path);
// 	}
// 	shapeContext.globalCompositeOperation = 'source-in';
// 	shapeContext.fillStyle = color ?? shapeData.pattern;
// 	shapeContext.fillRect(0, 0, width, height);
// 	return base;
// }
export async function createTextureWithBlurredEdges(size, shapeData, color) {
	let width = shapeData.width;
	let height = shapeData.height;
	let shapeCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createTextureWithBlurredEdges');
	let shapeContext = getContext(shapeCanvas);

	shapeContext.strokeStyle = 'black';
	shapeContext.fillStyle = 'black';
	shapeContext.fill(shapeData.path);
	let alpha = 1 / size
	for (let i = size; i >= 0; i--) {
		shapeContext.lineWidth = i;
		shapeContext.strokeStyle = `rgba(0,0,0,${alpha})`;
		shapeContext.stroke(shapeData.path);
	}
	shapeContext.globalCompositeOperation = 'source-in';
	shapeContext.fillStyle = color ?? shapeData.pattern;
	shapeContext.fillRect(0, 0, width, height);
	return await createImageBitmap(shapeCanvas);
}
export async function blurTextureEdges(context, blur, shapeData) {
	let width = context.canvas.width;
	let height = context.canvas.height;
	let coords2 = stringify(createRectanle(width, height));
	let p = new Path2D(coords2 + shapeData.pathString);

	let shapeCanvas = createCanvas(width, height, 'TextureMapShapeUtils.blurTextureEdges.shapeCanvas');

	let shapeContext = getContext(shapeCanvas);
	shapeContext.lineWidth = blur;
	shapeContext.strokeStyle = 'black';
	shapeContext.fillStyle = 'black';
	shapeContext.fill(p, 'evenodd');
	shapeContext.stroke(p);

	let invertCanvas = createCanvas(width, height, 'TextureMapShapeUtils.blurTextureEdges.invertCanvas');
	let invertContext = getContext(invertCanvas);
	invertContext.fillStyle = 'black';
	invertContext.fillRect(0, 0, width, height);
	invertContext.globalCompositeOperation = 'xor';
	invertContext.drawImage(shapeCanvas, 0, 0);

	let textureCanvas = createCanvas(width, height, 'TextureMapShapeUtils.blurTextureEdges.textureCanvas');
	let textureContext = getContext(textureCanvas);
	textureContext.filter = `blur(${blur}px)`;
	textureContext.drawImage(invertCanvas, 0, 0);
	textureContext.filter = 'none';
	textureContext.globalCompositeOperation = 'source-in';
	textureContext.drawImage(context.canvas, 0, 0);
	textureContext.save();
	return await createImageBitmap(textureCanvas);
}
export async function rotateAndScaleCanvas(canvas, angle, scale) {
	let rotatedCanvas = createCanvas(canvas.width * scale, canvas.height * scale, 'TextureMapShapeUtils.rotateAndScaleCanvas');
	let rotatedContext = getContext(rotatedCanvas);
	//rotatedContext.translate(canvas.width * scale, canvas.height * scale)
	if (angle) {
		rotatedContext.rotate(angle);
	}
	//rotatedContext.scale(scale, scale)
	rotatedContext.drawImage(canvas, 0, 0, canvas.width * scale, canvas.height * scale);
	return await createImageBitmap(rotatedCanvas);
}
export async function createTextureMap(obj, color, shadow, shadowOpacity, noisePattern) {
	let canvas = createCanvas(obj.width, obj.height, 'TextureMapShapeUtils.createTextureMap');
	let context = getContext(canvas);
	context.fillStyle = color;
	context.fill(obj.path);

	shadowOpacity = shadowOpacity === undefined ? 1 : shadowOpacity;
	if (noisePattern) {
		context.fillStyle = noisePattern;
		context.globalCompositeOperation = 'overlay';
		context.fill(obj.path);
	}
	if (shadow && shadowOpacity > 0) {
		let clippingCanvas = createCanvas(obj.width, obj.height, 'TextureMapShapeUtils.createTexture.clippingCanvas');
		let clippingContext = getContext(clippingCanvas);
		clippingContext.fillStyle = 'rgba(128,128,128,1)';
		clippingContext.fill(obj.path);
		clippingContext.globalCompositeOperation = 'source-in';
		clippingContext.drawImage(shadow, 0, 0, obj.width, obj.height);
		context.globalCompositeOperation = 'overlay';
		context.globalAlpha = shadowOpacity;
		context.drawImage(clippingCanvas, 0, 0);
		context.globalAlpha = 1;
	}
	return await blurTextureEdges(context, 1, obj);
}
export async function createCanvasFromImageData(data) {
	let canvas = createCanvas(data.width, data.height, 'TextureMapShapeUtils.createCanvasFromImageData');
	let context = getContext(canvas);
	context.putImageData(data, 0, 0);
	return canvas;
}
export async function addLine(context, coordinates) {
	if (coordinates.length < 1) {
		return;
	}
	context.moveTo(coordinates[0][0], coordinates[0][1]);
	for (let i = 1; i < coordinates.length; i++) {
		context.lineTo(coordinates[i][0], coordinates[i][1]);
	}
}
export async function createLineNormalMap(properties, displayResults) {
	timer.time(`generateLineNormalMap-${properties.name}`);
	let width = properties.width;
	let height = properties.height;

	let lineWidth = properties.lineWidth;
	let inner = properties.insetWidth;
	let outer = properties.paddingWidth;
	let shade = 128 * (1 / (inner + outer));
	let array = [];
	for (let i = 0; i <= outer + inner; i++) {
		let am = shade * i;
		let a = properties.convexInset ? 128 + am : 128 - am;
		array.push({ width: lineWidth + outer + inner - i, color: `rgba(${a},${a},${a},1)` });
	}
	array.push({ width: lineWidth, color: properties.convexInset ? 'white' : 'black' });

	let strokeCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createLineNormalMap.strokeCanvas');
	strokeCanvas.width = width;
	strokeCanvas.height = height;
	let strokeContext = getContext(strokeCanvas);
	strokeContext.lineCap = 'round';
	await addLine(strokeContext, properties.translated);
	for (let i of array) {
		strokeContext.strokeStyle = i.color;
		strokeContext.lineWidth = i.width;
		strokeContext.stroke();
	}

	let bumpMapCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createLineNormalMap.bumpMapCanvas');

	let bumpMapContext = getContext(bumpMapCanvas);
	bumpMapContext.fillStyle = 'rgba(128,128,128,1)';
	bumpMapContext.fillRect(0, 0, width, height);
	bumpMapContext.drawImage(strokeCanvas, 0, 0);

	let bumpmapData = bumpMapContext.getImageData(0, 0, bumpMapCanvas.width, bumpMapCanvas.height);
	let normalmapData = bumpMapContext.createImageData(bumpMapCanvas.width, bumpMapCanvas.height);

	await convertBumpmapToNormalMap(bumpmapData, normalmapData, properties.strength, properties.smoothElevation);
	await hide(normalmapData.data, 128, 128, 255);
	let normalCanvas = await createCanvasFromImageData(normalmapData);

	let renderCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createLineNormalMap.renderCanvas');

	let renderContext = getContext(renderCanvas);
	renderContext.fillStyle = 'rgba(128,128,255,1)';
	renderContext.fillRect(0, 0, width, height);
	if (properties.blur != 0) {
		renderContext.filter = `blur(${properties.blur}px)`;
	}
	renderContext.drawImage(normalCanvas, 0, 0);
	timer.timeEnd(`generateLineNormalMap-${properties.name}`);
	if (displayResults) {
		displayShape({ canvas: strokeCanvas });
		displayShape({ canvas: bumpMapCanvas });
		displayShape({ canvas: renderCanvas });
	}
	return renderContext.getImageData(0, 0, properties.width, properties.height);
}
export async function applyLineDepthMap(context, properties, blendMode, displayResults) {
	if (blendMode === undefined) {
		blendMode = 'overlay';
	}
	let o = properties.originalProperties;
	o.inset = 60;
	o.padding = 0;
	o.insetTye = false;
	properties = await processLineCoordinates(properties.coordinates, o);
	timer.time(`generateLineNormalMap-${properties.name}`);
	let width = properties.width;
	let height = properties.height;

	let lineWidth = properties.lineWidth;
	let inner = properties.insetWidth / 2;
	let shade = 128 * (1 / inner);
	let array = [];
	//let s = Math.sin(1)
	for (let i = 2; i <= inner; i++) {
		let am = shade * i;
		//let am = shade * i * Math.sin(shade * i/128) / s
		let a = properties.convexInset ? 128 + am : 128 - am;
		array.push({ width: lineWidth - i, color: `rgba(${a},${a},${a},1)` });
	}
	let strokeCanvas = createCanvas(width, height, 'TextureMapShapeUtils.applyLineDepthMap.strokeCanvas');

	let strokeContext = getContext(strokeCanvas);
	strokeContext.lineCap = 'round';
	await addLine(strokeContext, properties.translated);
	for (let i of array) {
		strokeContext.strokeStyle = i.color;
		strokeContext.lineWidth = i.width;
		strokeContext.stroke();
	}
	let bumpMapCanvas = createCanvas(width, height, 'TextureMapShapeUtils.applyLineDepthMap.bumpMapCanvas');

	let bumpMapContext = getContext(bumpMapCanvas);
	bumpMapContext.fillStyle = 'rgba(128,128,128,1)';
	bumpMapContext.fillRect(0, 0, width, height);
	bumpMapContext.filter = `blur(2px)`;
	bumpMapContext.drawImage(strokeCanvas, 0, 0);

	timer.timeEnd(`generateLineNormalMap-${properties.name}`);

	let gco = context.globalCompositeOperation;
	let filter = context.filter;
	context.globalCompositeOperation = blendMode;
	context.filter = `opacity(${properties.enhanceDepth * 100}%)`;
	context.drawImage(bumpMapCanvas, properties.x, properties.y);
	context.globalCompositeOperation = gco;
	context.filter = filter;
	if (displayResults) {
		displayShape({ canvas: bumpMapCanvas });
	}
}
export async function applyDepthMap(context, properties, blendMode, displayResults) {
	if (blendMode === undefined) {
		blendMode = 'overlay';
	}
	let o = properties.originalProperties;
	o.inset = properties.enhanceDepthInset ?? 25;
	o.padding = 0;
	o.insetTye = false;
	properties = await processCoordinates(properties.coordinates, o);
	timer.time(`applyDepthMap-${properties.name}`);
	let pathString = stringify(properties.translated);
	let width = properties.width;
	let height = properties.height;
	let innerPath = new Path2D(pathString);

	let inner = properties.insetWidth;
	let shade = 128 * (1 / inner);
	let innerArray = [];

	//let s = Math.sin(1)
	for (let i = inner; i > 0; i--) {
		//let am = shade * i * Math.sin(shade * i/128) / s

		let am = shade * i;
		let a = properties.convexInset ? 128 + am : 128 - am;
		innerArray.push({ width: i, color: `rgba(${a},${a},${a},1)` });
	}
	let innerCanvas = createCanvas(width, height, 'TextureMapShapeUtils.applyDepthMap.innerCanvas');
	let innerContext = getContext(innerCanvas);
	innerContext.clip(innerPath);
	innerContext.fillStyle = properties.convexInset ? 'white' : 'black';
	innerContext.fill(innerPath);
	for (let i of innerArray) {
		innerContext.strokeStyle = i.color;
		innerContext.lineWidth = i.width;
		innerContext.stroke(innerPath);
	}
	let renderCanvas = createCanvas(width, height, 'TextureMapShapeUtils.applyDepthMap.renderCanvas');
	let renderContext = getContext(renderCanvas);
	renderContext.fillStyle = 'rgba(128,128,128,1)';
	renderContext.fillRect(0, 0, width, height);
	renderContext.filter = `blur(2px)`;
	renderContext.drawImage(innerCanvas, 0, 0);

	timer.timeEnd(`applyDepthMap-${properties.name}`);
	if (displayResults) {
		displayShape({ canvas: innerCanvas });
		displayShape({ canvas: renderCanvas });
	}
	let gco = context.globalCompositeOperation;
	let filter = context.filter;
	context.globalCompositeOperation = blendMode;
	context.filter = `opacity(${properties.enhanceDepth * 100}%)`;
	context.drawImage(renderCanvas, properties.x, properties.y);
	context.globalCompositeOperation = gco;
	context.filter = filter;
}
export async function createScatterMap(properties) {
	timer.time(`createScatterMap-${properties.name}`);
	let pathString = properties.pathString ?? stringify(properties.translated);
	let path = properties.path ?? new Path2D(pathString);

	let width = properties.width;
	let height = properties.height;
	let inner = Math.floor(properties.scatterInsetWidth);
	let outer = Math.floor(properties.scatterPaddingWidth);
	let outerArray = [];
	let innerArray = [];
	for (let i = outer; i > 0; i--) {
		let am = (outer + i)
		let p = await createScatterPattern(50, am);
		outerArray.push({ width: i, color: p });
	}
	for (let i = inner; i > 0; i--) {
		let am = (inner + i)
		let p = await createScatterPattern(50, am);
		innerArray.push({ width: i, color: p });
	}
	innerArray.reverse();
	let outerCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createScatterMap.outerCanvas');
	let outerContext = getContext(outerCanvas);

	for (let i of outerArray) {
		outerContext.strokeStyle = i.color;
		outerContext.lineWidth = i.width;
		outerContext.stroke(path);
	}
	outerContext.globalCompositeOperation = 'destination-out';
	outerContext.fill(path);

	let innerCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createScatterMap.innerCanvas');
	let innerContext = getContext(innerCanvas);
	innerContext.clip(path);
	innerContext.fillStyle = 'black'
	innerContext.fill(path);
	innerContext.globalCompositeOperation = 'xor';
	for (let i of innerArray) {
		innerContext.strokeStyle = i.color;
		innerContext.lineWidth = i.width;
		innerContext.stroke(path);
	}

	let centerCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createScatterMap.centerCanvas');
	if (innerArray.length > 0) {
		let centerContext = getContext(centerCanvas);
		centerContext.strokeStyle = innerArray[0].color;
		centerContext.lineWidth = 2;
		centerContext.stroke(path);
	}

	let scatterCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createScatterMap.scatterCanvas');
	let scatterContext = getContext(scatterCanvas);
	scatterContext.drawImage(centerCanvas, 0, 0);
	scatterContext.drawImage(outerCanvas, 0, 0);
	scatterContext.drawImage(innerCanvas, 0, 0);
	timer.timeEnd(`createScatterMap-${properties.name}`);
	return await createImageBitmap(scatterCanvas);
}
export async function createBumpMap(properties, displayResults) {
	timer.time(`createBumpMap-${properties.name}`);
	let pathString = properties.pathString ?? stringify(properties.translated);
	let path = properties.path ?? new Path2D(pathString);

	let width = properties.width;
	let height = properties.height;
	let inner = properties.insetWidth;
	let outer = properties.paddingWidth;
	let shade = 128 * (1 / (inner + outer));
	let outerArray = [];
	let innerArray = [];
	if (!properties.smoothElevation) {
		for (let i = outer; i > 0; i--) {
			let am = shade * i;
			let am2 = parseInt(am * properties.paddingGradientOpacity, 10)
			let color1 = properties.convexPadding ? 128 + am2 : 128 - am2;
			let color2 = properties.convexPadding ? 128 + am : 128 - am;
			outerArray.push({ width: outer - i, color1: `rgba(${color1},${color1},${color1},1)`, color2: `rgba(${color2},${color2},${color2},1)` });
		}
		for (let i = inner; i > 0; i--) {
			let am = shade * (i + outer);
			let am2 = parseInt(am * properties.insetGradientOpacity, 10)
			let color1 = properties.convexInset ? 128 + am2 : 128 - am2;
			let color2 = properties.convexInset ? 128 + am : 128 - am;
			innerArray.push({ width: i, color1: `rgba(${color1},${color1},${color1},1)`, color2: `rgba(${color2},${color2},${color2},1)` });
		}
	} else {
		let s = Math.sin(1);
		for (let i = outer; i > 0; i--) {
			let am = (shade * i * Math.sin((shade * i) / 128)) / s;
			let am2 = parseInt(am * properties.paddingGradientOpacity, 10)
			let color1 = properties.convexPadding ? 128 + am2 : 128 - am2;
			let color2 = properties.convexPadding ? 128 + am : 128 - am;
			outerArray.push({ width: outer - i, color1: `rgba(${color1},${color1},${color1},1)`, color2: `rgba(${color2},${color2},${color2},1)` });
		}
		for (let i = inner; i > 0; i--) {
			let am = (shade * (i + outer) * Math.sin((shade * (i + outer)) / 128)) / s;
			let am2 = am * properties.insetGradientOpacity
			let color1 = properties.convexInset ? 128 + am2 : 128 - am2;
			let color2 = properties.convexInset ? 128 + am : 128 - am;
			innerArray.push({ width: i, color1: `rgba(${color1},${color1},${color1},1)`, color2: `rgba(${color2},${color2},${color2},1)` });
		}
	}

	outerArray.reverse();
	let outerCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createBumpMap.outerCanvas');
	let outerContext = getContext(outerCanvas);
	let innerCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createBumpMap.innerCanvas');
	let innerContext = getContext(innerCanvas);

	let radius = Math.max(width, height)
	radius = radius - radius * properties.paddingGradientOffset
	let innerStrokeStyle
	let outerStrokeStyle
	if (properties.insetGradient) {
		innerStrokeStyle = createRadialPerimeterGradient(innerContext, width, height, properties.insetGradientRotation, radius);
	}
	if (properties.paddingGradient) {
		outerStrokeStyle = createRadialPerimeterGradient(outerContext, width, height, properties.paddingGradientRotation, radius);
	}
	for (let i of outerArray) {
		if (properties.paddingGradient) {
			outerStrokeStyle.addColorStop(1, i.color1);
			outerStrokeStyle.addColorStop(0, i.color2);
			outerContext.strokeStyle = outerStrokeStyle;
		} else {
			outerContext.strokeStyle = i.color2;
		}
		outerContext.lineWidth = i.width;
		outerContext.stroke(path);
	}


	innerContext.clip(path);
	innerContext.fillStyle = properties.convexInset ? 'white' : 'black';
	innerContext.fill(path);

	for (let i of innerArray) {
		if (properties.insetGradient) {
			innerStrokeStyle.addColorStop(1, i.color1);
			innerStrokeStyle.addColorStop(0, i.color2);
			innerContext.strokeStyle = innerStrokeStyle;
		} else {
			innerContext.strokeStyle = i.color2;
		}
		innerContext.strokeStyle = innerStrokeStyle;
		innerContext.lineWidth = i.width;
		innerContext.stroke(path);
	}

	let centerCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createBumpMap.centerCanvas');
	let centerContext = getContext(centerCanvas);
	if (innerArray.length > 0) {
		if (properties.insetGradient) {
			innerStrokeStyle.addColorStop(1, innerArray[innerArray.length - 1].color1);
			innerStrokeStyle.addColorStop(0, innerArray[innerArray.length - 1].color2);
			centerContext.strokeStyle = innerStrokeStyle;
		} else {
			centerContext.strokeStyle = innerArray[innerArray.length - 1].color2;
		}
		centerContext.lineWidth = 2;
		centerContext.stroke(path);
	}
	let bumpMapCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createBumpMap.bumpMapCanvas');
	let bumpMapContext = getContext(bumpMapCanvas);
	bumpMapContext.fillStyle = 'rgba(128,128,128,1)';
	bumpMapContext.fillRect(0, 0, width, height);
	bumpMapContext.drawImage(centerCanvas, 0, 0);
	bumpMapContext.drawImage(outerCanvas, 0, 0);
	bumpMapContext.drawImage(innerCanvas, 0, 0);
	//displayShape({ canvas: bumpMapCanvas });
	timer.timeEnd(`createBumpMap-${properties.name}`);
	return bumpMapCanvas;
}
export async function createFlatSurfaceMap(properties) {
	timer.time(`createFlatSurfaceMap-${properties.name}`);
	let pathString = properties.pathString ?? stringify(properties.translated);
	let path = properties.path ?? new Path2D(pathString);
	let width = properties.width;
	let height = properties.height;

	let flatSurfaceMapCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createFlatSurfaceMap');
	let flatSurfaceMapContext = getContext(flatSurfaceMapCanvas);

	if (properties.flatSurfaceEdgeSmoothingWidth > 0) {
		let num = parseInt(properties.flatSurfaceEdgeSmoothingWidth, 10)
		let op = 1 / num;
		flatSurfaceMapContext.fillStyle = `rgba(${128},${128},${255},1)`

		let s = Math.sin(1);

		for (let i = num; i > 0; i--) {
			let o = 1 - i * op;
			let alpha = (o * Math.sin(o) / 1) / s
			flatSurfaceMapContext.strokeStyle = `rgba(${128},${128},${255},1)`
			flatSurfaceMapContext.lineWidth = i;
			flatSurfaceMapContext.globalCompositeOperation = 'destination-out'
			flatSurfaceMapContext.fill(path);
			flatSurfaceMapContext.stroke(path)
			flatSurfaceMapContext.strokeStyle = `rgba(${128},${128},${255},${alpha})`
			flatSurfaceMapContext.globalCompositeOperation = 'source-over'
			flatSurfaceMapContext.stroke(path)
		}
	}

	flatSurfaceMapContext.fillStyle = `rgba(${128},${128},${255},1)`
	flatSurfaceMapContext.fill(path);
	timer.timeEnd(`createFlatSurfaceMap-${properties.name}`);
	//displayShape(flatSurfaceMapCanvas);
	return await createImageBitmap(flatSurfaceMapCanvas);
}
function createLinearGradient(context, width, height, rotation) {
	let angleRad = Utils.degreesToRadians(rotation);
	let angleRad2 = Utils.degreesToRadians(rotation + 180);
	let x = width / 2
	let y = height / 2;
	let x2 = x + Math.sin(angleRad) * x
	let y2 = y + Math.cos(angleRad) * y
	let x1 = x + Math.sin(angleRad2) * x
	let y1 = y + Math.cos(angleRad2) * y
	return context.createLinearGradient(x1, y1, x2, y2);
}
function createRadialPerimeterGradient(context, width, height, rotation, radius) {
	let length = width * 2 + height * 2
	let abc = rotation / 360
	let l1 = Math.floor(length * abc)
	function createCoordinate(l) {
		if (l <= height) {
			return { x: 0, y: l }
		} else if (l <= height + width) {
			return { x: l - height, y: height }
		} else if (l <= height + width + height) {
			return { x: width, y: height - (l - height - width) }
		} else {
			return { x: width - (l - height - width - height), y: 0 }
		}
	}
	let p1 = createCoordinate(l1)
	return context.createRadialGradient(p1.x, p1.y, 0, p1.x, p1.y, radius)
}
function createLinearPerimeterGradient(context, width, height, rotation) {
	let length = width * 2 + height * 2
	let l1 = Math.floor(length * rotation / 360)
	let l2 = Math.floor(l1 + length / 2)
	if (l2 > length) {
		l2 = l2 - length
	}
	function createCoordinate(l) {
		if (l <= height) {
			return { x: 0, y: l }
		} else if (l <= height + width) {
			return { x: l - height, y: height }
		} else if (l <= height + width + height) {
			return { x: width, y: height - (l - height - width) }
		} else {
			return { x: width - (l - height - width - height), y: 0 }
		}
	}
	let p1 = createCoordinate(l1)
	let p2 = createCoordinate(l2)
	return context.createLinearGradient(p1.x, p1.y, p2.x, p2.y);
}
export async function createNormalMapWithDownScaling(properties, displayResults) {
	timer.time(`createNormalMap-${properties.name}`);
	let bumpmapData;
	let width = properties.width;
	let height = properties.height;

	if (!properties.bumpmapCanvas) {
		properties.bumpmapCanvas = await createBumpMap(properties, displayResults);
	}
	let downScale = 0.2
	let upscale = 1 / downScale
	let bumpmapCanvas = createCanvas(width * downScale, height * downScale, 'TextureMapShapeUtils.createNormalMapWithDownScaling.bumpmapCanvas');
	let bumpmapContext = getContext(bumpmapCanvas);
	bumpmapContext.drawImage(properties.bumpmapCanvas, 0, 0, width * downScale, height * downScale);
	bumpmapData = getContext(bumpmapCanvas).getImageData(0, 0, width * downScale, height * downScale);

	let renderCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createNormalMapWithDownScaling.renderCanvas');
	let renderContext = getContext(renderCanvas);
	renderContext.filter = `blur(${properties.blur}px)`;

	let normalmapData = renderContext.createImageData(bumpmapData.width, bumpmapData.height);
	convertBumpmapToNormalMap(bumpmapData, normalmapData, properties.strength, properties.smoothElevation);
	await hide(normalmapData.data, 128, 128, 255);
	let normalCanvas = await createCanvasFromImageData(normalmapData);
	renderContext.scale(upscale, upscale);
	renderContext.drawImage(normalCanvas, 0, 0, width * downScale, height * downScale);
	timer.timeEnd(`createNormalMap-${properties.name}`);
	return await createImageBitmap(renderCanvas)
}
export async function createNormalMap(properties, displayResults) {
	timer.time(`createNormalMap-${properties.name}`);
	let width = properties.width;
	let height = properties.height;

	if (!properties.bumpmapCanvas) {
		properties.bumpmapCanvas = await createBumpMap(properties, displayResults);
	}
	let bumpmapCanvas = properties.bumpmapCanvas
	let bumpmapData = getContext(bumpmapCanvas).getImageData(0, 0, width, height);
	let renderCanvas = createCanvas(width, height, 'TextureMapShapeUtils.createNormalMap.renderCanvas');
	let renderContext = getContext(renderCanvas);
	renderContext.filter = `blur(${properties.blur}px)`;

	let normalmapData = renderContext.createImageData(bumpmapData.width, bumpmapData.height);
	convertBumpmapToNormalMap(bumpmapData, normalmapData, properties.strength, properties.smoothElevation);
	await hide(normalmapData.data, 128, 128, 255);

	let normalCanvas = await createCanvasFromImageData(normalmapData);
	renderContext.drawImage(normalCanvas, 0, 0, width, height);
	timer.timeEnd(`createNormalMap-${properties.name}`);
	return await createImageBitmap(renderCanvas)
}
export function convertBumpmapToNormalMap(bumpMapData, normalMapData, strength, smoothElevation) {
	let tst = true;
	let s = Math.sin(1);
	for (let row = 0; row < bumpMapData.height; row++) {
		for (let col = 0; col < bumpMapData.width; col++) {
			const positiveX = getPx(bumpMapData, col + 1, row)[0];
			const negativeX = getPx(bumpMapData, col - 1, row)[0];
			const positiveY = getPx(bumpMapData, col, row - 1)[0];
			const negativeY = getPx(bumpMapData, col, row + 1)[0];
			let alpha = 1;
			if (smoothElevation) {
				const px = getPx(bumpMapData, col, row)[0] * 255;
				alpha -= Math.abs(px - 128) / 128;
				alpha = Math.sin(alpha) / s;
			}
			const changeX = negativeX - positiveX;
			const changeY = negativeY - positiveY;
			const tangentSpaceNormal = normalize([changeX, changeY, 1.0 / strength]);

			setPx(normalMapData, col, row, [(tangentSpaceNormal[0] + 1) / 2, (tangentSpaceNormal[1] + 1) / 2, (tangentSpaceNormal[2] + 1) / 2, alpha]);
		}
	}
	for (let col = 0; col < bumpMapData.width; col++) {
		reset(normalMapData, col, bumpMapData.height - 1);
		reset(normalMapData, col, bumpMapData.height - 2);
	}
	return normalMapData;
}
export async function displace(mapData, sourceData, outputData, width, height, dx, dy) {
	for (let y = 0; y < height; y++) {
		for (let x = 0; x < width; x++) {
			let pix = y * width + x;
			let arrayPos = pix * 4;
			let depth = ((mapData.data[arrayPos] - 127.5) / 255) * 2;

			let ofs_x = Math.round(x + dx * depth);
			let ofs_y = Math.round(y + dy * depth);

			if (ofs_x < 0) ofs_x = 0;
			if (ofs_x > width - 1) ofs_x = width - 1;
			if (ofs_y < 0) ofs_y = 0;
			if (ofs_y > height - 1) ofs_y = height - 1;

			let targetPix = ofs_y * width + ofs_x;
			let targetPos = targetPix * 4;
			let d = 1 - Math.abs(depth);
			outputData.data[arrayPos] = sourceData.data[targetPos];
			outputData.data[arrayPos + 1] = sourceData.data[targetPos + 1];
			outputData.data[arrayPos + 2] = sourceData.data[targetPos + 2];
			outputData.data[arrayPos + 3] = sourceData.data[targetPos + 3];
		}
	}
}
let scatterCache = {}
export async function createScatterPattern(size, m, color, min, max) {
	if (size === undefined) {
		size = 50
	}
	if (m === undefined) {
		m = 2
	}
	if (min === undefined) {
		min = 0
	}
	if (max === undefined) {
		max = 255
	}
	if (color === undefined) {
		color = 'black'
	}
	let id = `${size}-${color}-${m}-${min}-${max}`
	if (scatterCache[id] !== undefined) {
		return scatterCache[id]
	}
	let canvas = createCanvas(size, size, 'TextureMapShapeUtils.createScatterPattern.canvas');
	let context = getContext(canvas);
	context.fillStyle = color;
	context.fillRect(0, 0, size, size);
	let data = context.getImageData(0, 0, size, size);
	if (min === max) {
		for (let i = 0; i < data.data.length; i += 4) {
			data.data[i + 3] = min
		}
	} else {
		for (let i = 0; i < data.data.length; i += 4) {
			data.data[i + 3] = Utils.getRandomInt(0, m) % m === 0 ? max : min;
		}
	}
	context.putImageData(data, 0, 0);
	let patternCanvas = createCanvas(size, size, 'TextureMapShapeUtils.createScatterPattern.patternCanvas');
	let patternContext = getContext(patternCanvas);
	scatterCache[id] = patternContext.createPattern(canvas, 'repeat');
	return scatterCache[id]
}
export async function createNoisePattern(level) {
	let canvas = createCanvas(50, 50, 'TextureMapShapeUtils.createNoisePattern');
	let context = getContext(canvas);
	let data = context.getImageData(0, 0, 50, 50);
	await createNoise(data, level);
	context.putImageData(data, 0, 0);

	let patternCanvas = createCanvas(50, 50, 'TextureMapShapeUtils.createNoisePattern.patternCanvas');
	let patternContext = getContext(patternCanvas);
	return patternContext.createPattern(canvas, 'repeat');
}
export async function addNoiseToContext(context, level) {
	timer.time(`addNoiseToContext`);
	let gco = context.globalCompositeOperation;
	context.globalCompositeOperation = 'overlay';
	context.fillStyle = await createNoisePattern(level);
	context.fillRect(0, 0, context.canvas.width, context.canvas.height);
	context.globalCompositeOperation = gco;
	timer.timeEnd(`addNoiseToContext`);
}
export async function createNoise(imageData, level) {
	let data = imageData.data;
	if (!level) {
		level = 1;
	}
	for (let i = 0; i < data.length; i += 4) {
		let color = Utils.getRandomBoolean() ? 255 : 0;
		data[i] = color;
		data[i + 1] = color;
		data[i + 2] = color;
		data[i + 3] = 255 * (level / 100);
	}
}
