<script>
	import { getContext, onMount } from 'svelte';
	import chooseMap from '@Help/guide/choose-map.html?raw';
	import addOutline from '@Help/guide/add-outline.html?raw';
	import addHoleFocus from '@Help/guide/add-hole-focus.html?raw';
	import placeTeeCut from '@Help/guide/place-tee-cut.html?raw';
	import clickHole from '@Help/guide/click-hole.html?raw';
	import placeTee from '@Help/guide/place-tee.html?raw';
	import placeGreen from '@Help/guide/place-green.html?raw';
	import mapElements from '@Help/guide/map-elements.html?raw';
	import findWiki from '@Help/guide/find-wiki.html?raw';
	import { scale } from 'svelte/transition';
	import { backInOut } from 'svelte/easing';
	import { fade } from 'svelte/transition';

	export let controller;
	let root;
	$: visible = false;
	$: content = '';
	$: isDarkMode = window.isDarkMode;
	$: currentStep = controller.currentStep();
	$: currentStepCompleted = controller.currentStepCompleted;
	controller.on('progressUpdated', function (progress, step, showGuide) {
		currentStepCompleted = controller.currentStepCompleted;
		currentStep = step;
		if (showGuide) {
			visible = true;
			setContent(step);
		} else {
			visible = false;
		}
	});
	window.addEventListener('colorSchemeChanged', (e) => {
		isDarkMode = e.isDarkMode;
	});
	function setContent(step) {
		switch (step) {
			case 'chooseMap':
				content = chooseMap;
				break;
			case 'addOutline':
				content = addOutline;
				break;
			case 'addHoleFocus':
				content = addHoleFocus;
				break;
			case 'clickHole':
				content = clickHole;
				break;
			case 'placeTeeCut':
				content = placeTeeCut;
				break;
			case 'placeTee':
				content = placeTee;
				break;
			case 'placeGreen':
				content = placeGreen;
				break;
			case 'mapElements':
				content = mapElements;
				break;
			case 'findWiki':
				content = findWiki;
				break;
		}
	}
	function next() {
		controller.goForward();
	}
	function previous() {
		controller.goBack();
	}
	function skip() {
		controller.skip();
	}
	function done() {
		controller.completeGuide();
	}
	onMount(() => {
		if (controller && controller.showGuide()) {
			visible = true;
			setContent(controller.currentStep());
		}
	});
</script>

{#if visible}
	<div bind:this={root} class="guide box box-shadow" completed={currentStepCompleted} transition:scale={{ duration: 250, delay: 0, opacity: 0, start: 0.5, easing: backInOut }}>
		{#key currentStep}
			<div class="markup" in:fade>{@html content}</div>
		{/key}
		<div class="footer bg-bigsteel-50 dark:bg-bigsteel-700/30">
			{#if controller.canGoBack}
				<button class:btn-solid-white={isDarkMode} class:btn-outline-blue={!isDarkMode} class="btn btn-sm" on:click={previous}>Previous</button>
			{/if}
			<div style="flex:1"></div>

			{#if controller.canGoForward}
				<button class:btn-solid-white={isDarkMode} class:btn-outline-blue={!isDarkMode} class="btn btn-sm" on:click={skip}>Skip</button>
				<button class="btn btn-sm btn-solid-blue" on:click={next}>Next</button>
			{:else}
				<button class="btn btn-sm btn-solid-blue" on:click={done}>Done</button>
			{/if}
		</div>
	</div>
{/if}

<style lang="postcss">
	.guide {
		transition:
			outline 0.15s,
			border 0.15s;
		position: absolute;
		z-index: 500;
		bottom: 40px;
		right: 10px;
		width: 350px;
		gap: 8px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		border-radius: 6px;
		text-align: left;
	}
	.markup {
		padding: 16px;
	}
	.footer {
		transition:
			border 0.3s,
			background-color 0.3s;
		padding: 16px;
		gap: 10px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
		padding-top: 15px;
		border-radius: 0px 0px 6px 6px;
	}
	.guide[completed='true'] .footer {
		border-top: 1px solid theme('colors.blue.500/40%');
		background-color: theme('colors.blue.500/10%');
	}
	.dark .guide[completed='true'] .footer {
		border-top: 1px solid theme('colors.blue.500/40%');
		background-color: theme('colors.blue.500/10%');
	}
</style>
