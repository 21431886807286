import { getElement } from '@Utils/Elements.js';
import { GeometryUtils } from '@Utils/Utils.js';
import Options from '@Assets/ElementOptions.json';

export class ElementFeature {
	feature;
	constructor(feature) {
		this.feature = feature;
	}
	setPointsPerSeg(value) {
		this.feature.set('pointsPerSeg', value);
	}

	setTension(value) {
		this.feature.set('tension', value);
	}
	setNormalize(value) {
		this.feature.set('normalize', value);
	}
	setTextureRotation(value) {
		this.feature.set('textureRotation', value);
	}
	setTextureSize(value) {
		this.feature.set('textureSize', value);
	}
	setTextureRef(value) {
		this.feature.set('textureRef', value);
	}
	simplify(value) {
		GeometryUtils.simplifyFeature(this.feature, value);
	}
	setRounded(value) {
		this.feature.set('rounded', value);
	}
	setElementStyle(value) {
		let el = getElement(value);
		if (el) {
			this.feature.set('elementName', value);
		}
	}
	close() {
		GeometryUtils.close(this.feature);
	}
	setOption(name, value) {
		// if (!['pointsPerSeg', 'tension', 'shadeoffset', 'shadeamount', 'normalize', 'rounded', 'elementName', 'hidden', 'textureRotation', 'textureSize', 'textureRef'].includes(name)) {
		// 	return;

		this.feature.set('clearCache', true)
		let element = this.getElement()
		if (name === 'placeabove') {
			let index = element.zIndex
			if (value) {
				let placeover = getElement(value);

				if (placeover) {
					index = placeover.zIndex + 1
				}
			}
			this.feature.set('order', index);
		}
		if (value === undefined || value === null) {
			this.feature.unset(name);
		} else {
			this.feature.set(name, value);
		}
	}
	getOption(name, def) {
		if (def === undefined) {
			let el = this.getElement();
			if (el && el.defaultValues && el.defaultValues[name] !== undefined) {
				def = el.defaultValues[name]
			}
		}
		let v = this.feature.get(name)
		if (v !== undefined) {
			return v
		}
		return def;
	}
	getPointsPerSeg() {
		return this.feature.get('pointsPerSeg') ? this.feature.get('pointsPerSeg') : 50;
	}
	getTension() {
		return this.feature.get('tension') ? this.feature.get('tension') : 0.5;
	}
	getShadeoffset() {
		return this.feature.get('shadeoffset') ? this.feature.get('shadeoffset') : 8;
	}
	getShadeamount() {
		return this.feature.get('shadeamount') ? this.feature.get('shadeamount') : 8;
	}
	getNormalize() {
		return this.feature.get('normalize') ? true : false;
	}
	getRounded() {
		return this.feature.get('rounded') ? true : false;
	}
	getElementName() {
		return this.feature.get('elementName');
	}
	getElement() {
		return getElement(this.getElementName());
	}
	getId() {
		return this.feature.ol_uid;
	}
	getTextureRotation() {
		return this.feature.get('textureRotation') ?? 0;
	}
	getTextureSize() {
		return this.feature.get('textureSize') ?? 50;
	}
	getTextureRef() {
		return this.feature.get('textureRef') ?? this.getElementName();
	}
	setHidden(value) {
		return this.feature.set('hidden', value);
	}
	getType() {
		return this.feature.getGeometry().getType();
	}
	getOptions() {
		let el = this.getElement();
		if (!el.options) {
			return null;
		}
		return Options.filter((o) => o.targets.includes(el.tooltype) || o.targets.includes(el.options) || o.targets.includes(el.name));
	}
	resetToDefaults() {
		let options = this.getOptions();
		if (!options) {
			return;
		}
		for (let option of options) {
			for (let field of option.fields) {
				if (field.enabledBy !== "advanced") {
					this.setOption(field.name, field.default);
				} else {
					this.setOption(field.name, null);
				}
			}
		}

	}
	getValues(advanced) {
		let options = this.getOptions();
		if (!options) {
			return null;
		}
		let el = this.getElement();
		options = JSON.parse(JSON.stringify(options));
		for (let option of options) {
			for (let field of option.fields) {
				let name = field.name
				let _default = field.default
				if (el.defaultValues && el.defaultValues[name] !== undefined) {
					_default = el.defaultValues[name]
				}
				field.value = this.getOption(field.name, _default);
			}
			if (advanced !== true) {
				option.fields = option.fields.filter((f) => f.enabledBy !== "advanced");
			}
		}
		return options;
	}
	static createDefaultValues(element) {
		let options = ElementFeature.getOptions(element);
		let values = {};
		for (let option of options) {
			for (let field of option.fields) {
				values[field.name] = field.default;
			}
		}
		return values;
	}
}
