<script>
	import '../../style.css';
	// https://github.com/markedjs/marked?
	import welcome from '@Help/wiki/welcome.html?raw';
	import holeFocus from '@Help/wiki/hole-focus.html?raw';
	import holeOutline from '@Help/wiki/hole-outline.html?raw';
	import holeTees from '@Help/wiki/hole-tees.html?raw';
	import holeGreenPoints from '@Help/wiki/hole-green-points.html?raw';
	import cuts from '@Help/wiki/cuts.html?raw';
	import hazards from '@Help/wiki/hazards.html?raw';
	import vegetation from '@Help/wiki/vegetation.html?raw';
	import otherElements from '@Help/wiki/other-elements.html?raw';
	import shortcuts from '@Help/wiki/shortcuts.html?raw';
	import resetGuide from '@Help/wiki/reset-guide.html?raw';
	import theme from '@Help/wiki/theme.html?raw';

	import deleteElement from '@Help/wiki/delete-element.html?raw';
	import editElement from '@Help/wiki/edit-element.html?raw';
	import deselectingElement from '@Help/wiki/deselecting-element.html?raw';

	import { scale } from 'svelte/transition';
	import { backInOut } from 'svelte/easing';
	import { fade } from 'svelte/transition';
	import { getIconHtml } from '@Utils/Utils.js';

	export let visible = false;
	export let title = 'Golf map tool Wiki/Guide/Help center';
	import { createEventDispatcher, onMount, getContext } from 'svelte';
	const urlParams = new URLSearchParams(window.location.search);
	const { getGuide, getEditorCanvas, isAdmin, isDev } = getContext('editor');
	let showAdvancedOptions = localStorage.getItem('showAdvancedOptions') === 'true';
	let showIntersectingElements = localStorage.getItem('showIntersectingElements') === 'true';
	let showOverlappingElements = localStorage.getItem('showOverlappingElements') === 'true';
	const dispatch = createEventDispatcher();
	$: menu = [
		{
			title: 'General',
			content: welcome,
			items: [
				{
					title: 'Helpful shortcuts',
					content: shortcuts
				},
				{
					title: 'Reset guide',
					content: resetGuide
				},
				{
					title: 'Theme',
					content: theme
				},
				{
					id: 'settings',
					title: 'Settings',
					enabled: isAdmin
				}
			]
		},
		{
			title: 'Cuts and elements',
			items: [
				{ title: 'Outline', content: holeOutline, required: true },
				{ title: 'Focus', content: holeFocus },
				{ title: 'Tees', content: holeTees, required: true },
				{
					title: 'Cuts',
					icon: 'Cuts',
					required: true,
					content: cuts
				},
				{ title: 'Green points', content: holeGreenPoints, required: true },
				{
					title: 'Hazards',
					icon: 'Hazards',
					content: hazards
				},
				{
					title: 'Vegetation',
					icon: 'Vegetation',
					content: vegetation
				},
				{
					title: 'Other elements',
					icon: 'Other',
					content: otherElements
				}
			]
		},
		{
			title: 'Mapping tool',
			items: [
				{ title: 'Deselecting an element', content: deselectingElement },
				{ title: 'Editing elements', content: editElement },
				{ title: 'Deleting elements', content: deleteElement }
			]
		}
	];

	$: selected = menu[0];
	$: content = menu[0].content;
	$: contentElement = null;
	$: showAdvancedOptions, toggleShowAdvancedOptions();
	$: showIntersectingElements, toggleShowIntersectingElements();
	$: showOverlappingElements, toggleShowOverlappingElements();

	let guideController = getGuide();
	let canvas = getEditorCanvas();
	function equal(i, s) {
		return i.title === s.title;
	}
	function toggleShowAdvancedOptions() {
		localStorage.setItem('showAdvancedOptions', showAdvancedOptions);
	}
	function toggleShowIntersectingElements() {
		localStorage.setItem('showIntersectingElements', showIntersectingElements);
		if (showIntersectingElements) {
			canvas.enableShowIntersectingElements();
		} else {
			canvas.disableShowIntersectingElements();
		}
	}
	function toggleShowOverlappingElements() {
		localStorage.setItem('showOverlappingElements', showOverlappingElements);
		if (showOverlappingElements) {
			canvas.enableShowOverlappingElements();
		} else {
			canvas.disableShowOverlappingElements();
		}
	}
	function setSelected(i) {
		selected = i;
		content = i.content;
		setTimeout(() => {
			addButtonListerners();
		}, 100);
	}
	function close() {
		visible = false;
		dispatch('close');
	}
	function addButtonListerners() {
		if (!contentElement) {
			return;
		}
		contentElement.querySelectorAll('button').forEach((el) => {
			el.addEventListener('click', function () {
				if (el.name == 'resetUserTips') {
					localStorage.removeItem('hideUserTips');
					close();
				} else if (el.name === 'resetGuide') {
					guideController.reset();
					close();
				} else if (el.name === 'setLightTheme') {
					localStorage.setItem('theme', 'light');
					let e = new Event('storage');
					e.key = 'theme';
					e.newValue = 'light';
					window.dispatchEvent(e);
				} else if (el.name === 'setDarkTheme') {
					localStorage.setItem('theme', 'dark');
					let e = new Event('storage');
					e.key = 'theme';
					e.newValue = 'dark';
					window.dispatchEvent(e);
				} else if (el.name === 'setAdvancedOptions') {
					localStorage.setItem('showAdvancedOptions', true);
					let e = new Event('storage');
					e.key = 'showAdvancedOptions';
					e.newValue = false;
					window.dispatchEvent(e);
				} else if (el.name === 'unsetAdvancedOptions') {
					localStorage.setItem('showAdvancedOptions', false);
					let e = new Event('storage');
					e.key = 'showAdvancedOptions';
					e.newValue = false;
					window.dispatchEvent(e);
				}
			});
		});
	}
	$: {
		if (contentElement) {
			addButtonListerners();
		}
	}
	// onMount(() => {
	// 	contentElement.addEventListener('DOMNodeInserted', function () {
	// 		contentElement.querySelectorAll('button').forEach((el) => {
	// 			el.addEventListener('click',function(){
	// 				console.log('click')
	// 			})
	// 		});
	// 	});
	// })
</script>

{#if visible}
	<div class="modal-wrapper font-thin font-sans" transition:fade>
		<div class="modal box box-shadow" transition:scale={{ duration: 250, delay: 0, opacity: 0, start: 0.5, easing: backInOut }}>
			<div class="title">
				{title}
				<button class="close-button" on:click={close}>{@html getIconHtml('Close', 'dark:bg-white')}</button>
			</div>
			<div class="main">
				<div class="menu-wrapper dark:bg-bigsteel-750/30 bg-neutral-200">
					<div class="menu">
						{#each menu.filter((i) => i.enabled !== false) as item}
							{#if item.content}
								<button on:click={() => setSelected(item)} class="l1 menu-item menu-item-btn" class:selected={equal(item, selected)}>
									{@html getIconHtml(item.icon, 'menu-item-icon')}
									{item.title}
								</button>
							{:else}
								<div class="menu-item l1" class:selected={equal(item, selected)}>
									{@html getIconHtml(item.icon, 'menu-item-icon')}
									{item.title}
								</div>
							{/if}
							{#if item.items}
								{#each item.items.filter((i) => i.enabled !== false) as subitem}
									<button on:click={() => setSelected(subitem)} class="l2 menu-item menu-item-btn" class:selected={equal(subitem, selected)}>{subitem.title}</button>
								{/each}
							{/if}
						{/each}
						<!--button on:click={() => GuideController.reset()} class="menu-item menu-item-btn l2">Reset guide</button-->
					</div>
				</div>

				<div class="content" bind:this={contentElement}>
					{#key selected}
						{#if selected.id == 'settings'}
							<div in:fade class="markup">
								<h1>Settings</h1>
								<p>Here you can turn on or off a different settings (only available to admins)</p>
								<table class="w-full">
									<thead>
										<tr>
											<th>Option</th>
											<th class="text-right"></th>
										</tr>
									</thead><tbody>
										<tr>
											<td>Show advanced options (experimental)</td>
											<td class="text-right align-top">
												<label class="switch">
													<input type="checkbox" checked={showAdvancedOptions} on:change={(e) => (showAdvancedOptions = e.target.checked)} />
													<span class="slider round"></span>
												</label>
											</td>
										</tr>
										<tr>
											<td>Show intersecting elements of the same kind when selecting an element (marked in orange).</td>
											<td class="text-right align-top">
												<label class="switch">
													<input type="checkbox" checked={showIntersectingElements} on:change={(e) => (showIntersectingElements = e.target.checked)} />
													<span class="slider round"></span>
												</label>
											</td>
										</tr>
										<tr>
											<td>Show overlapping elements that can be deleted (marked in red)</td>
											<td class="text-right align-top">
												<label class="switch">
													<input type="checkbox" checked={showOverlappingElements} on:change={(e) => (showOverlappingElements = e.target.checked)} />
													<span class="slider round"></span>
												</label>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						{:else}
							<div in:fade class="markup">{@html content}</div>
						{/if}
					{/key}
				</div>
			</div>
		</div>
	</div>
{/if}

<style lang="postcss">
	.modal-wrapper {
		font-family: 'Tietoevry sans 1';
		position: absolute;
		z-index: 600;
		top: 0px;
		left: 0px;
		transition: all 0.3s ease-in-out;

		display: flex;
		flex: 1;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		justify-content: center;
		justify-items: center;
	}

	.modal {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		margin: 100px auto;
		box-sizing: border-box;
		max-width: 923px;
		min-width: 700px;
		width: 90%;
		border-radius: 16px;
	}
	.modal .title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px;
		line-height: 1;
		font-size: 18px;
		font-weight: 500;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}
	.modal .menu-wrapper {
		display: block;
		overflow-y: auto;
		border-right: 1px solid rgba(0, 0, 0, 0.2);
	}
	.modal .main {
		display: flex;
		flex: 1;
		flex-direction: row;
		min-height: 1px;
	}
	.modal .main .menu {
		display: flex;
		flex-direction: column;
		min-width: 150px;
		/*border-right: 1px solid rgba(0, 0, 0, 0.2);*/
		gap: 0;
		margin-bottom: 20px;
	}
	.menu-item {
		display: flex;
		flex-direction: row;
		text-align: left;
		gap: 5px;
	}
	:global(.svg-mask) {
		background-color: theme('colors.steel.800');
	}
	.menu-item-btn:hover {
		background-color: theme('colors.blue.100');
		color: theme('colors.blue.500');
	}
	.menu-item-btn.selected {
		background-color: theme('colors.blue.100');
		color: theme('colors.blue.500');
	}
	.menu-item-btn.selected :global(.svg-mask),
	.menu-item-btn:hover :global(.svg-mask) {
		background-color: theme('colors.blue.500');
	}
	.modal .main .menu .l1 {
		font-size: 16px;
		font-weight: 500;
		padding: 10px 14px;
		border-left: 2px solid transparent;
	}
	.modal .main .menu .l2 {
		font-size: 14px;
		font-weight: 400;
		border-left: 2px solid #cbcccd;
		padding: 4px 14px;
		margin-left: 16px;
	}
	.modal .main .content {
		flex: 1;
		font-size: 16px;
		padding: 20px;
		overflow-y: auto;
		padding-bottom: 20px;
	}
	.dark .modal .main .menu .l2 {
		border-left: 2px solid theme('colors.bigsteel.700');
	}
	.menu-item-btn:hover {
		background-color: theme('colors.blue.500/50%');
		border-left: 2px solid theme('colors.blue.500/50%') !important;
		color: white;
	}
	.menu-item-btn.selected {
		background-color: theme('colors.blue.500');
		color: white;
		border-left: 2px solid theme('colors.blue.500') !important;
	}
</style>
