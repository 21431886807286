import EditorCanvas from '@EditorCanvas/EditorCanvas.js';
import { DataController } from '@Utils/DataController.js';
import './style.css';
import EditorUI from '@Views/EditorUI.svelte';
import { GuideController } from '@Views/Guide/GuideController.js';
import { ColorSchemeManager } from '@Utils/ColorSchemeManager.js';
import * as jose from 'jose'

let os = 'Unknown OS';
let userAgent = window.navigator.userAgent;
let colorSchemeManager = new ColorSchemeManager(true, 'dark', true);

if (userAgent.indexOf('Win') != -1) os = 'win';
if (userAgent.indexOf('Mac') != -1) os = 'mac';
if (userAgent.indexOf('X11') != -1) os = 'win';
if (userAgent.indexOf('Linux') != -1) os = 'win';

document.body.classList.add(`os-${os}`);
const urlParams = new URLSearchParams(window.location.search);
let dev = urlParams.get('dev') === 'true' ?? false;

function getUserRole(token) {
	if (!token) {
		return 'User'
	}
	try {
		let obj = jose.decodeJwt(token)
		if (obj['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']) {
			return obj['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
		}
		return 'User'
	} catch (error) {
		console.log(error)
		return 'User'
	}
}
function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}
function getToken() {
	let token = getCookie('token');

	const queryToken = urlParams.get('token');
	if (!token) {
		token = queryToken;
	}

	return token
}
function init(configuration, token) {
	if (!token) {
		token = getCookie('token');
	}
	if (!token && dev !== true) {
		console.warn('No token found');
	}
	let role = getUserRole(token)
	dev = dev && configuration.MODE === 'dev'
	if (dev) {
		role = 'GolfOfficeAdministrator'
	}

	let canvas = new EditorCanvas('map', {
		showIntersectingElements: localStorage.getItem('showIntersectingElements') === 'true',
		showOverlappingElements: localStorage.getItem('showOverlappingElements') === 'true',
		serviceKeys: {
			azureMaps: configuration.VITE_AZURE_MAPS_KEY,
			bing: configuration.VITE_BING_API_KEY,
			google: configuration.VITE_GOOGLE_API_KEY
		}
	});
	let backendUrl = dev === true ? "http://localhost:4033" : configuration.VITE_GOLF_OFFICE_API_URL
	const controller = new DataController(canvas, backendUrl, token, {
		localTest: dev === true,
		googleKey: configuration.VITE_GOOGLE_API_KEY,
		azureMapsKey: configuration.VITE_AZURE_MAPS_KEY,
		golfOfficeFrontendURL: configuration.VITE_GOLF_OFFICE_FRONTEND_URL
	});
	controller.on('authorizationStatus', function (unauthorized) {
		if (unauthorized) {
			document.body.classList.add('unauthorized');
		} else {
			document.body.classList.remove('unauthorized');
		}
	})
	controller.start()
	let guide = new GuideController(canvas, controller);
	let prop = {
		isAdmin: role === 'GolfOfficeAdministrator',
		isDev: dev,
		canvas: canvas,
		controller: controller,
		guideController: guide,
		colorSchemeManager: colorSchemeManager,
		config: {
			golfOfficeFrontendURL: configuration.VITE_GOLF_OFFICE_FRONTEND_URL
		}
	};
	new EditorUI({ target: document.querySelector('[data-gui]'), props: prop });
	let tooltipEl = document.getElementById('guitooltip');
	document.addEventListener('keydown', (e) => {
		if (document.activeElement) {
			document.activeElement.blur();
		}
		if (e.key !== 'Escape') {
			return;
		}
		canvas.escape();
	});
	// document.addEventListener('mousedown', (e) => {
	// 	let el = document.createElement('div');
	// 	el.style.left = `${e.clientX - 25}px`;
	// 	el.style.top = `${e.clientY - 25}px`;
	// 	el.classList.add('click-impact');
	// 	el.addEventListener('animationend', () => {
	// 		el.remove();
	// 	});
	// 	document.body.appendChild(el);
	// })
	// document.addEventListener('keydown', (e) => {
	// 	// let el = document.createElement('div');
	// 	// el.style.left = `${e.clientX}px`;
	// 	// el.style.top = `${e.clientY}px`;
	// 	// el.innerText = e.key;
	// 	// el.classList.add('key-down-effect');
	// 	// el.addEventListener('animationend', () => {
	// 	// 	el.remove();
	// 	// });
	// 	// document.body.appendChild(el);
	// 	window.dispatchEvent(new CustomEvent('info', { detail: { message: `${e.key} key pressed` } }))
	// });
	document.addEventListener('mouseover', (e) => {
		let tooltip = e.target.getAttribute('tooltip');
		let tooltipBackground = e.target.getAttribute('tooltip-background');
		if (!tooltip) {
			tooltipEl.style.opacity = '0';
			tooltipEl.style.visibility = 'hidden';
			tooltipEl.innerHTML = '';
		} else {
			tooltipEl.innerHTML = tooltip;
			tooltipEl.style.visibility = 'visible';
			tooltipEl.style.opacity = '1';
			let targetRect = e.target.getBoundingClientRect();
			let tooltipElRect = tooltipEl.getBoundingClientRect();
			let left = targetRect.left - tooltipElRect.width / 2 + targetRect.width / 2;
			let margin = 10;
			let top = targetRect.top - tooltipElRect.height - margin;
			let bottom = targetRect.top + targetRect.height + margin;
			if (left + tooltipElRect.width + margin > document.body.clientWidth) {
				left = document.body.clientWidth - tooltipElRect.width - margin;
			}
			tooltipEl.style.top = `${top - tooltipElRect.height > margin ? top : bottom}px`;
			tooltipEl.style.left = `${left >= margin ? left : margin}px`;
			if (tooltipBackground) {
				tooltipEl.style.backgroundColor = tooltipBackground;
			} else {
				tooltipEl.style.backgroundColor = null;
			}
		}
	});
}
window.addEventListener(
	'message',
	function (ev) {
		if (ev.data.action === 'getCacheConfiguration') {
			window.postMessage({
				id: ev.data.id,
				action: 'setCacheConfiguration',
				payload: {
					precacheName: import.meta.env.VITE_PRECACHE_NAME,
					runtimeCacheName: import.meta.env.VITE_RUNTIME_CACHE_NAME
				}
			});
		}
	},
	false
);
init(import.meta.env, getToken());
if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register(`/CacheServiceWorker.js?v=5`)
		.then((registration) => {
			registration.addEventListener('updatefound', () => {
				const installingWorker = registration.installing;
				console.log('A new service worker is being installed:', installingWorker);
			});
		})
		.catch((error) => {
			console.error(`Service worker registration failed: ${error}`);
		});
} else {
	console.error('Service workers are not supported.');
}
