import { Fill, Stroke } from 'ol/style.js';
import Elements from '@Assets/Elements.json';
import { Pack1, Pack2 } from '@Renderer/TexturePack.js';
import { createCanvas } from '@Utils/Utils.js';
import { scatter } from '@Renderer/TextureMapShapeUtils.js';

const patternCanvas = createCanvas(1, 1, 'Texture.patternCanvas');
const patternContext = patternCanvas.getContext('2d');
const dictionary = {};
const elementsArray = Elements;

for (let e of Elements) {
	dictionary[e.name] = e;
}
export function getElementsArray() {
	return elementsArray;
}
export function getElementsDictionary() {
	return dictionary;
}
export function getElement(name) {
	return dictionary[name];
}

function dataURLToBlob(dataURL) {
	let BASE64_MARKER = ';base64,';
	if (dataURL.indexOf(BASE64_MARKER) == -1) {
		let parts = dataURL.split(',');
		let contentType = parts[0].split(':')[1];
		let raw = parts[1];

		return new Blob([raw], { type: contentType });
	}

	let parts = dataURL.split(BASE64_MARKER);
	let contentType = parts[0].split(':')[1];
	let raw = window.atob(parts[1]);
	let rawLength = raw.length;

	let uInt8Array = new Uint8Array(rawLength);

	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}

	return new Blob([uInt8Array], { type: contentType });
}
async function scaleImage(image, targetWidth, targetHeight) {
	const canvas = createCanvas(targetWidth, targetHeight, 'Texture.scaleImage');
	canvas.getContext('2d').drawImage(image, 0, 0, targetWidth, targetHeight);
	let res = await createImageBitmap(canvas);
	let scattered = await scatter(res);
	return [res, scattered]
}

export class Texture {
	name;
	url;
	size;
	original;
	scaledImage;
	ready;
	fill;
	stroke;
	onChange;
	resolution = 1;
	constructor(name, url, originalSize, size) {
		this.name = name;
		this.url = url;
		this.originalSize = originalSize;
		this.size = size;
		this.height = size;
		this.width = size;
		this.ready = false;
	}
	async loadTexture() {
		const response = await fetch(this.url);
		if (!response.ok) {
			throw new Error(`${response.status} ${response.statusText}`);
		}
		let res = await response.blob();
		this.bitmap = await createImageBitmap(res);
		this.ready = true
	}
	async scale() {
		let s = this.getSize();
		let t = this;
		if (this.width == s.width && this.height == s.height) {
			t.finalize();
			return;
		}
		let [res, scattered] = await scaleImage(this.bitmap, s, s)
		t.scaledImage = res;
		t.scattered = scattered
		t.finalize();
	}
	getSize() {
		return parseInt(this.size / this.resolution, 10);
	}
	invalidate(resolution) {
		resolution = !resolution ? 1 : resolution;
		if (this.resolution == resolution) {
			return;
		}
		this.resolution = resolution;
		this.canvasImageData = null;
		this.ready = false;
	}
	getImage() {
		if (this.scaledImage) {
			return this.scaledImage;
		}
		return this.bitmap;
	}
	async getCanvasImageData() {
		if (this.canvasImageData) {
			return this.canvasImageData;
		}
		let canvas = await createCanvas(image.width, image.height, 'Texture.getCanvasImageData');
		let image = this.getImage();

		let context = canvas.getContext('2d');
		context.drawImage(image, 0, 0);
		this.canvasImageData = context.getImageData(0, 0, image.width, image.height);
		return this.canvasImageData;
	}
	async finalize() {
		let image = this.getImage();
		if (this.scattered) {
			this.scatterPattern = patternContext.createPattern(this.scattered, 'repeat');
		}
		let pattern = patternContext.createPattern(image, 'repeat');
		this.pattern = pattern;
		this.fill = new Fill({ color: pattern });
		this.stroke = new Stroke({ color: pattern });
		this.ready = true;
	}
}

export class TextureProvider {
	ready = false;
	textures = {};
	resolution = 1;
	static defaultSize = {
		green: 20,
		tee: 20,
		fairway: 40,
		mountain: 20,
		hill: 20,
		rough: 12,
		water: 15,
		stream: 15,
		bunker: 30,
		forest: 12,
		treeCrownShaadow: 150,
		background: 12,
		shadow: 150,
		wastearea: 12,
		grassbunker: 20,
		path: 20,
		road: 20,
		tree: 130
	};
	static originalSize = {
		green: 60,
		tee: 60,
		fairway: 60,
		mountain: 60,
		hill: 60,
		rough: 60,
		water: 60,
		stream: 60,
		bunker: 60,
		forest: 60,
		treeCrownShaadow: 150,
		background: 60,
		shadow: 150,
		wastearea: 60,
		grassbunker: 60,
		path: 60,
		road: 60,
		tree: 150
	};
	constructor(pack) {
		if (pack === 'pack1') {
			pack = Pack1;
		} else if (pack === 'pack2') {
			pack = Pack2;
		}
		if (pack === undefined) {
			pack = Pack2;
		}
		this.pack = pack
		this.texturesLoaded = false
	}
	async loadTextures() {
		if(this.texturesLoaded === true) {
			return
		}
		let pack = this.pack
		await this.addTexture('green', TextureProvider.originalSize.green, TextureProvider.defaultSize.green, pack.greenUrl);
		await this.addTexture('tee', TextureProvider.originalSize.tee, TextureProvider.defaultSize.tee, pack.teeUrl);
		await this.addTexture('fairway', TextureProvider.originalSize.fairway, TextureProvider.defaultSize.fairway, pack.fairwayUrl);
		await this.addTexture('mountain', TextureProvider.originalSize.mountain, TextureProvider.defaultSize.mountain, pack.mountainUrl);
		await this.addTexture('hill', TextureProvider.originalSize.hill, TextureProvider.defaultSize.hill, pack.hillUrl);
		await this.addTexture('rough', TextureProvider.originalSize.rough, TextureProvider.defaultSize.rough, pack.roughUrl);
		await this.addTexture('water', TextureProvider.originalSize.water, TextureProvider.defaultSize.water, pack.waterUrl);
		await this.addTexture('stream', TextureProvider.originalSize.stream, TextureProvider.defaultSize.stream, pack.streamUrl);
		await this.addTexture('bunker', TextureProvider.originalSize.bunker, TextureProvider.defaultSize.bunker, pack.bunkerUrl);
		await this.addTexture('forest', TextureProvider.originalSize.forest, TextureProvider.defaultSize.forest, pack.forestUrl);
		await this.addTexture('treeCrownShaadow', TextureProvider.originalSize.treeCrownShaadow, TextureProvider.defaultSize.treeCrownShaadow, pack.treeCrownShadowUrl);
		await this.addTexture('background', TextureProvider.originalSize.background, TextureProvider.defaultSize.background, pack.backgroundUrl);
		await this.addTexture('shadow', TextureProvider.originalSize.shadow, TextureProvider.defaultSize.shadow, pack.shadowUrl);
		await this.addTexture('wastearea', TextureProvider.originalSize.wastearea, TextureProvider.defaultSize.wastearea, pack.wasteAreaUrl);
		await this.addTexture('grassbunker', TextureProvider.originalSize.grassbunker, TextureProvider.defaultSize.grassbunker, pack.grassBunkerUrl);
		await this.addTexture('path', TextureProvider.originalSize.path, TextureProvider.defaultSize.path, pack.pathUrl);
		await this.addTexture('road', TextureProvider.originalSize.road, TextureProvider.defaultSize.road, pack.roadUrl);
		await this.addTexture('tree1', TextureProvider.originalSize.tree, TextureProvider.defaultSize.tree, pack.tree1Url);
		await this.addTexture('tree2', TextureProvider.originalSize.tree, TextureProvider.defaultSize.tree, pack.tree2Url);
		await this.addTexture('tree3', TextureProvider.originalSize.tree, TextureProvider.defaultSize.tree, pack.tree3Url);
		await this.addTexture('tree4', TextureProvider.originalSize.tree, TextureProvider.defaultSize.tree, pack.tree4Url);
		await this.addTexture('tree5', TextureProvider.originalSize.tree, TextureProvider.defaultSize.tree, pack.tree5Url);
		await this.addTexture('tree6', TextureProvider.originalSize.tree, TextureProvider.defaultSize.tree, pack.tree6Url);
		await this.addTexture('tree7', TextureProvider.originalSize.tree, TextureProvider.defaultSize.tree, pack.tree7Url);
		this.trees = pack.trees;
		this.texturesLoaded = true
	}
	getTexture(name) {
		return this.textures[name];
	}

	async invalidate(resolution) {
		resolution = !resolution ? 1 : resolution;
		if (this.resolution == resolution) {
			return;
		}
		this.ready = false;
		this.resolution = resolution;
		for (let t in this.textures) {
			let tex = this.textures[t];
			await tex.invalidate(resolution);
		}
		await this.scale();
		this.ready = true
	}
	async scale() {
		for (let t in this.textures) {
			let tex = this.textures[t];
			await tex.scale();
		}
	}
	async addTexture(name, originalSize, size, url) {
		let tex = new Texture(name, url, originalSize, size)
		this.textures[name] = tex;
		await tex.loadTexture();
	}
	getScatterPattern(name) {
		let tex = this.textures[name];
		if (!tex || !tex.ready) {
			return 'rgba(255,255,255,1)';
		}
		return this.textures[name].scatterPattern
	}

	getPattern(name) {
		let tex = this.textures[name];
		if (!tex || !tex.ready) {
			return 'rgba(255,255,255,1)';
		}
		return this.textures[name].pattern;
	}

	getFill(name) {
		let tex = this.textures[name];
		if (!tex || !tex.ready) {
			return new Fill({ color: '#ffffff' });
		}
		return this.textures[name].fill;
	}

	has(name) {
		return this.textures[name] != null;
	}
	getSVGTree(name) {
		return this.trees[name];
	}
}