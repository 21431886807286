<script>
	import { onMount } from 'svelte';
	import { scale } from 'svelte/transition';
	import { backInOut } from 'svelte/easing';
	import { getIconHtml } from '@Utils/Utils.js';
	import { nanoid } from 'nanoid';
	let topMessages = [];
	let bottomMessages = [];
	let timeOuts = {};
	$: isDarkMode = window.isDarkMode;
	window.addEventListener('colorSchemeChanged', (e) => {
		isDarkMode = e.isDarkMode;
	});
	onMount(() => {
		window.addEventListener('success', (event) => {
			let message = event.data || event.detail;
			if (!message) {
				console.error(event);
				return;
			}
			message.class = 'success';
			pushTop(message);
		});
		window.addEventListener('warning', (event) => {
			let message = event.data || event.detail;
			if (!message) {
				console.error(event);
				return;
			}
			message.class = 'warning';
			pushTop(message);
		});
		window.addEventListener('error', (event) => {
			let message = event.data || event.detail;
			if (!message) {
				console.error(event);
				return;
			}
			if (message.error) {
				message.details = message.error?.message;
			} else {
				message.details = 'An unknown error occurred.';
			}
			message.class = 'error';
			pushTop(message);
		});
		window.addEventListener('info', (event) => {
			let message = event.data || event.detail;
			if (!message) {
				console.error(event);
				return;
			}
			message.class = 'info';
			pushTop(message);
		});
		window.addEventListener('user-tip', (event) => {
			if (localStorage.getItem('hideUserTips') === 'true') return;
			let message = event.data || event.detail;
			if (!message) {
				console.error(event);
				return;
			}
			message.class = 'user-tip';
			pushBottom(message);
		});
	});
	function pushBottom(message) {
		if (!message.id) message.id = nanoid();
		let index = bottomMessages.findIndex((e) => e.id === message.id);
		if (index !== -1) {
			clearTimeout(timeOuts[message.id]);
			bottomMessages.splice(index, 1);
		}
		bottomMessages = [message, ...bottomMessages];
		let visibleFor = message.visibleFor || 5000;
		timeOuts[message.id] = setTimeout(() => {
			bottomMessages = bottomMessages.filter((e) => e.id !== message.id);
		}, visibleFor);
	}
	function pushTop(message) {
		if (!message.id) message.id = nanoid();
		let index = topMessages.findIndex((e) => e.id === message.id);
		if (index !== -1) {
			clearTimeout(timeOuts[message.id]);
			topMessages.splice(index, 1);
		}
		topMessages = [message, ...topMessages];
		let visibleFor = message.visibleFor || 5000;
		timeOuts[message.id] = setTimeout(() => {
			topMessages = topMessages.filter((e) => e.id !== message.id);
		}, visibleFor);
	}
	function hover(message) {
		clearTimeout(timeOuts[message.id]);
	}
	function close(message) {
		topMessages = topMessages.filter((e) => e.id !== message.id);
		bottomMessages = bottomMessages.filter((e) => e.id !== message.id);
	}
	function messageAction(message) {
		if (message.action && message.action.handler) {
			message.action.handler();
		}
		close(message);
	}
</script>

<div class="messages">
	<div class="top">
		{#each topMessages as message, i (message.id)}
			<div role="none" class="message box-shadow {message.class}" id={message.id} transition:scale={{ duration: 250, delay: 0, opacity: 0, start: 0.5, easing: backInOut }} on:mouseover={() => hover(message)} on:focus={() => hover(message)}>
				<h3>{message.message} <button class="close-button" on:click={() => close(message)}>{@html getIconHtml('Close', 'dark:bg-white')}</button></h3>
				{#if Array.isArray(message.details)}
					{#each message.details as detail}
						<p>{detail}</p>
					{/each}
				{:else if message.details}
					<p>{message.details}</p>
				{/if}
			</div>
		{/each}
	</div>
	<div class="bottom">
		{#each bottomMessages as message, i (message.id)}
			<div role="none" class="message box-shadow {message.class}" id={message.id} transition:scale={{ duration: 250, delay: 0, opacity: 0, start: 0.5, easing: backInOut }} on:mouseover={() => hover(message)} on:focus={() => hover(message)}>
				<h3>{message.message} <button class="close-button" on:click={() => close(message)}>{@html getIconHtml('Close', 'dark:bg-white')}</button></h3>
				{#if Array.isArray(message.details)}
					{#each message.details as detail}
						<p>{detail}</p>
					{/each}
				{:else if message.details}
					<p>{message.details}</p>
				{/if}
				{#if message.action}
					<button class="btn btn-sm btn-solid-blue" on:click={() => messageAction(message)}>{message.action.label || 'Ok'}</button>
				{/if}
			</div>
		{/each}
	</div>
</div>

<style lang="postcss">
	.messages {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0px;
		right: 10px;
		z-index: 800;
		width: 300px;
		gap: 10px;
		pointer-events: none;
		font-size: 13px;
		height: calc(100vh);
	}
	.messages .top {
		display: flex;
		flex-direction: column;
		padding: 10px;
		width: 280px;
		flex: 1;
		gap: 10px;
		font-size: 13px;
		overflow-y: auto;
	}
	.messages .bottom {
		display: flex;
		padding: 10px;
		width: 280px;
		flex-direction: column;
		gap: 10px;
		font-size: 13px;
		overflow-y: auto;
	}
	.message {
		pointer-events: all;
		position: relative;
		text-align: left;
		border-radius: 10px;
		padding: 10px;
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
	}
	.close-button {
		opacity: 0.6;
		transform: scale(0.8);
	}
	.close-button:hover {
		opacity: 1;
	}
	.message p {
		padding-bottom: 10px;
	}
	.message p:last-child {
		padding-bottom: 0px;
	}
	.message h3 {
		display: flex;
		font-size: 15px;
		font-weight: 900;
		justify-content: space-between;
	}
	.message.error {
		background-color: theme('colors.red.600/80%');
		color: white;
	}
	.message.warning {
		background-color: theme('colors.peach.600/80%');
		color: white;
	}
	.message.success {
		background-color: theme('colors.green.600/80%');
		color: white;
	}
	.message.info {
		background-color: theme('colors.neutral.100/80%');
		color: theme('colors.steel.800');
	}
	.message.user-tip {
		border: 1px solid theme('colors.bigsteel.200/30%');
		background-color: theme('colors.bigsteel.800/70%');
		color: white;
	}
	/* .message.user-tip-dark {
		border: 1px solid theme('colors.bigsteel.200/30%');
		background-color: theme('colors.bigsteel.800/70%');
		color: white;
	} */
</style>
