import Feature from 'ol/Feature.js';
import Polygon from 'ol/geom/Polygon';
import { Pointer as PointerInteraction } from 'ol/interaction.js';
import { noModifierKeys } from 'ol/events/condition.js';
import { RectangleUtils, PointUtils } from '@Utils/Utils.js';
import { nanoid } from 'nanoid';

export class AddFrameInteraction extends PointerInteraction {
	coordinates = [];
	source;
	attached = false;
	fixedRatio;
	frameProperties;
	constructor(fixedRatio) {
		super();
		this.fixedRatio = fixedRatio;
		if (this.fixedRatio) {
			this.numerOfPoints = 2;
		} else {
			this.numerOfPoints = 3;
		}
		this.coordinate_ = null;
		this.feature_ = null;
	}
	reset() {
		this.feature_ = null;
		this.coordinates = [];
	}
	cancel() {
		if (this.feature_) {
			this.source.removeFeature(this.feature_);
		}
		this.reset();
	}
	done() {
		let f = this.feature_
		if (this.feature_) {
			this.feature_.unset('unfinished');
		}
		let p = this.frameProperties;
		this.coordinate_ = null;
		this.feature_ = null;
		this.frameProperties = null;
		if (this.frameAdded) {
			this.frameAdded(p, f);
		}
		this.reset();
	}
	attach(map, source, properties) {
		if (!properties) {
			console.warn('missing properties in attatch.');
			return;
		}
		this.frameProperties = properties;
		this.source = source;
		map.addInteraction(this);
		this.attached = true;
		this.dispatchEvent('changed');
	}
	detach() {
		if (this.map_) {
			this.map_.removeInteraction(this);

			this.attached = false;
			this.source = null;
			this.frameProperties = null;
			this.dispatchEvent('changed');
		}
	}
	handleDownEvent(evt) {
		if (noModifierKeys(evt)) {
			this.coordinates.push(evt.coordinate);
			this.coordinates = geometryFunction(this.coordinates, this.fixedRatio);
			if (this.coordinates.length > this.numerOfPoints) {
				let coords = this.coordinates;
				coords = RectangleUtils.addMidpointsToRectangle(this.coordinates);
				this.feature_.setGeometry(new Polygon([coords]));
				this.done();
			}
		}
	}
	handleDragEvent(evt) {
		const deltaX = evt.coordinate[0] - this.coordinate_[0];
		const deltaY = evt.coordinate[1] - this.coordinate_[1];

		const geometry = this.feature_.getGeometry();
		geometry.translate(deltaX, deltaY);

		this.coordinate_[0] = evt.coordinate[0];
		this.coordinate_[1] = evt.coordinate[1];
	}
	handleMoveEvent(evt) {
		let newCoordinates = Array.from(this.coordinates);
		newCoordinates.push(evt.coordinate);
		newCoordinates = geometryFunction(newCoordinates, this.fixedRatio);
		if (this.feature_ == null) {
			this.feature_ = new Feature(new Polygon([newCoordinates]));
			this.feature_.set('objectType', 'frame');
			this.feature_.set('frameId', nanoid(50));
			this.feature_.set('unfinished', true);
			this.feature_.set('fixedRatio', this.fixedRatio);
			if (this.frameProperties) {
				for (let p in this.frameProperties) {
					this.feature_.set(p, this.frameProperties[p]);
				}
			}
			this.source.addFeature(this.feature_);
		} else {
			this.feature_.setGeometry(new Polygon([newCoordinates]));
		}
	}
	handleUpEvent() {
		let p = this.frameProperties;
		this.coordinate_ = null;
		this.feature_ = null;
		this.frameProperties = null;
		return false;
	}
}
function customRatioGeometryFunction(coordinates) {
	let newCoordinates = coordinates;

	if (newCoordinates.length > 2) {
		let start = newCoordinates[0];
		let end = newCoordinates[1];
		let width = newCoordinates[2];
		let distance = Math.hypot(end[0] - width[0], end[1] - width[1]);
		let angle = Math.atan2(start[1] - end[1], start[0] - end[0]);
		let p1 = PointUtils.movePointAtAngle(start, angle, distance * -1);
		let p2 = PointUtils.movePointAtAngle(end, angle, distance * -1);
		let p3 = PointUtils.movePointAtAngle(end, angle, distance);
		let p4 = PointUtils.movePointAtAngle(start, angle, distance);
		newCoordinates = [p1, p2, p3, p4, p1];
	}
	return newCoordinates;
}
function fixedRatioGeometryFunction(coordinates, ratio) {
	let newCoordinates = coordinates;

	if (newCoordinates.length > 1) {
		let start = newCoordinates[0];
		let end = newCoordinates[1];
		let distance = Math.hypot(end[0] - start[0], end[1] - start[1]) / ratio / 2;
		let angle = Math.atan2(start[1] - end[1], start[0] - end[0]);
		let p1 = PointUtils.movePointAtAngle(start, angle, distance * -1);
		let p2 = PointUtils.movePointAtAngle(end, angle, distance * -1);
		let p3 = PointUtils.movePointAtAngle(end, angle, distance);
		let p4 = PointUtils.movePointAtAngle(start, angle, distance);
		newCoordinates = [p1, p2, p3, p4, p1];
	}
	return newCoordinates;
}
function geometryFunction(coordinates, ratio) {
	if (ratio) {
		return fixedRatioGeometryFunction(coordinates, ratio);
	}
	return customRatioGeometryFunction(coordinates);
}
