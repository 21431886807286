<script>
	import { getContext } from 'svelte';
	import DraggableComponent from '@Components/DraggableComponent.svelte';
	import { Pack2 } from '@Renderer/TexturePack.js';
	import { deconstructHoleId } from '@Utils/DataController.js';
	const { getEditorCanvas } = getContext('editor');
	const editorCanvas = getEditorCanvas();
	let showAdvanced = import.meta.env.VITE_ADVANCED_TOOLING_ENABLED === 'true' && localStorage.getItem('showAdvancedOptions') === 'true';
	$: selectedOptions = null;
	$: currentFrame = null;
	$: title = 'Options';
	$: currentElementFeature = null;
	$: values = null;
	$: isHidden = true;
	$: isDarkMode = window.isDarkMode;
	$: showAdvanced;
	$: simplifyEnabled = false;
	$: editEnabled = false;
	window.addEventListener('colorSchemeChanged', (e) => {
		isDarkMode = e.isDarkMode;
	});
	function reset() {
		selectedOptions = null;
		currentFrame = null;
		currentElementFeature = null;
		values = null;
		isHidden = true;
		simplifyEnabled = false;
		editEnabled = false;
		editorCanvas.deselectFeature();
	}
	function activated(field) {
		if (!selectedOptions) {
			return false;
		}
		if (!field.activatedBy) {
			return true;
		}
		if (field.name === field.activatedBy) {
			return true;
		}
		for (let option of selectedOptions) {
			let f = option.fields.find((f) => f.name === field.activatedBy);
			if (f && f.value) {
				return true;
			}
		}
		return false;
	}
	function loadOptions(elementFeature) {
		if (elementFeature === undefined) {
			elementFeature = currentElementFeature;
		}
		if (!elementFeature) {
			reset();
			return;
		}
		simplifyEnabled = elementFeature.getType() === 'Polygon' || elementFeature.getType() === 'LineString';
		editEnabled = elementFeature.getType() === 'Polygon';
		showAdvanced = import.meta.env.VITE_ADVANCED_TOOLING_ENABLED === 'true' && localStorage.getItem('showAdvancedOptions') === 'true';
		let opt = elementFeature.getValues(showAdvanced);

		if (opt) {
			title = elementFeature.getElement().title;
			selectedOptions = opt;
			values = {};
			for (let option of selectedOptions) {
				for (let field of option.fields) {
					if (field.name === 'lineWidth') {
						console.log('stop');
					}
					values[field.name] = field.value;
				}
			}
			isHidden = false;
		}
	}
	editorCanvas.on('frameSelected', function (frame) {
		currentFrame = frame;
		if (!frame) {
			reset();
			return;
		}
		if (frame.get('holeId')) {
			title = `Hole outline (${deconstructHoleId(frame.get('holeId')).displayName})`;
		}
		isHidden = false;
	});

	editorCanvas.on('elementSelected', function (elementFeature) {
		if (!elementFeature) {
			reset();
			return;
		}
		loadOptions(elementFeature);
		currentElementFeature = elementFeature;
	});
	editorCanvas.on('frameFocused', function (frame) {
		if (!frame) {
			// clear and hide
			return;
		}
	});
</script>

<DraggableComponent additionStyles={isHidden ? 'hidden' : ''} initialX="10">
	<div class="flex text-sm flex-col text-left pt-0" class:w-52={!showAdvanced} class:w-64={showAdvanced}>
		<div class="text-left w-full font-bold p-2 pt-0">{title}</div>

		{#if selectedOptions && selectedOptions.length > 0 && Object.keys(values).length > 0}
			<div class="flex text-sm flex-col gap-2 bg-neutral-200 dark:bg-bigsteel-900/20 p-2 options">
				{#each selectedOptions as optionGroup}
					{#each optionGroup.fields.filter((f) => f.hidden !== true) as field}
						{#if field.type == 'select'}
							<label tooltip={field.tooltip} for={field.name}>{field.title}</label>
							<select
								bind:value={values[field.name]}
								name={field.name}
								class:dark-select={isDarkMode}
								on:change={(e) => {
									field.value = e.target.value;
									currentElementFeature.setOption(field.name, e.target.value);
								}}
							>
								{#each field.options.filter((f) => f.value !== currentElementFeature.getElementName()) as option}
									<option value={option.value}>{option.title}</option>
								{/each}
							</select>
						{:else if field.type == 'grid'}
							<div class="text-left w-full">{field.title}</div>
							<div class="grid grid-cols-5 gap-2">
								{#each field.options as option}
									<button
										class="image-button"
										class:image-button-lighter={isDarkMode}
										selected={option.value == field.value}
										on:click={() => {
											values[field.name] = option.value;
											field.value = option.value;
											currentElementFeature.setOption(field.name, option.value);
										}}><img src={Pack2[option.value + 'SvgUrl']} alt={option.title} /></button
									>
								{/each}
							</div>
						{:else if field.type == 'range'}
							<label for={field.name}>{field.title}</label>
							<div class="flex justify-between">
								<input
									name={field.name}
									on:change={(e) => {
										field.value = e.target.value;
										currentElementFeature.setOption(field.name, Number(e.target.value));
									}}
									class="flex-1"
									disabled={!activated(field)}
									type="range"
									min={field.min}
									max={field.max}
									step={field.step}
									bind:value={values[field.name]}
								/>
								<div class:hidden={!showAdvanced} class="w-10 text-right">{values[field.name]}</div>
							</div>
						{:else if field.type == 'checkbox'}
							<div class="flex flex-row gap-2">
								<input
									name={field.name}
									disabled={!activated(field)}
									on:change={(e) => {
										values[field.name] = e.target.checked;
										field.value = e.target.checked;
										currentElementFeature.setOption(field.name, e.target.checked);
									}}
									title={values[field.name]}
									type="checkbox"
									bind:checked={values[field.name]}
								/>
								<label for={field.name}>{field.title}</label>
							</div>
						{/if}
					{/each}
				{/each}
			</div>
		{/if}
		{#if !currentFrame}
			<div class="flex gap-2 p-2 pb-0">
				{#if simplifyEnabled}
					<button
						name="simplify"
						class="btn btn-sm flex-1"
						tooltip="Simplify a shape to reduce the number of points while keeping the shape relatively intact."
						class:btn-solid-blue={!isDarkMode}
						class:btn-solid-white={isDarkMode}
						on:click={() => {
							currentElementFeature.simplify();
						}}>Simplify</button
					>
				{/if}
				<button
					name="reset"
					class="btn btn-sm flex-1"
					tooltip="Reset settings to default values. Will not impact the underlying shape of the element."
					class:btn-solid-blue={!isDarkMode}
					class:btn-solid-white={isDarkMode}
					on:click={() => {
						currentElementFeature.resetToDefaults();
						loadOptions(currentElementFeature);
					}}>Reset</button
				>
				{#if editEnabled}
					<button
						name="edit"
						class="btn btn-sm flex-1"
						tooltip="Open and edit element, escape to cancel."
						class:btn-solid-blue={!isDarkMode}
						class:btn-solid-white={isDarkMode}
						on:click={() => {
							editorCanvas.editSelectedFeature();
						}}>Edit</button
					>
				{/if}
			</div>
		{/if}
		<div class="flex gap-2 p-2">
			{#if currentFrame && !currentFrame.get('focused')}
				<button
					name="edit"
					tooltip="Press to add tee, green, and focus"
					class="btn btn-sm flex-1"
					class:btn-solid-blue={!isDarkMode}
					class:btn-solid-white={isDarkMode}
					on:click={() => {
						editorCanvas.focus(currentFrame);
					}}>Edit hole</button
				>
			{/if}
			<button
				name="delete"
				class="btn btn-sm btn-solid-red flex-1"
				on:click={() => {
					editorCanvas.deleteSelected(true);
					reset();
				}}>Delete</button
			>
		</div>
	</div></DraggableComponent
>

<style lang="postcss">
	.options {
		max-height: calc(100vh - 200px);
		overflow-y: auto;
	}
	.image-button:not([selected='true']) img {
		filter: grayscale(50%) opacity(0.7);
	}
	input {
		accent-color: theme('colors.blue.500');
	}
	.dark .image-button:not([selected='true']) img {
		filter: grayscale(60%) opacity(0.5);
	}
	select {
		all: unset;
		box-sizing: border-box;
		flex: 1;
		width: 100%;
		padding: 4px;
		overflow: hidden;
		color: #fff;
		font-family: 'Tietoevry sans 1';
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
		border-radius: 4px;
		border: 1px solid theme('colors.neutral.400');
		background-color: theme('colors.neutral.100');
		color: theme('colors.steel.800');
	}
	.dark-select {
		border: 1px solid theme('colors.bigsteel.200/30%');
		background-color: theme('colors.bigsteel.800/70%');
		color: #ffffff;
	}
	select::after {
		font-family: 'tietoevry-icons';
		content: '\ea22';
		color: #1e2224;

		width: 20px;
		height: 20px;
	}
	select::placeholder {
		color: var(--text-text-tertiary, #64676a);
	}
	select:hover:not([disabled]):not(:invalid),
	select:focus-visible:not([disabled]):not(:invalid) {
		outline-color: var(--dividers-borders-border-selector-hover, #1861f2);
	}
	select:focus-visible:not([disabled]):not(:invalid) {
		box-shadow: 0px 0px 0px 4px rgba(24, 97, 242, 0.4);
	}
	select:active:not([disabled]) {
		outline-color: var(--blue-blue-2, #0c4fd4);
	}
	select:disabled {
		opacity: 0.4;
	}
	select:invalid {
		outline-color: var(--surface-surface-form-warning, rgba(216, 47, 51, 1));
	}
	select:focus-visible:invalid:not([disabled]) {
		box-shadow: 0px 0px 0px 4px rgba(216, 47, 51, 0.4);
	}
</style>
