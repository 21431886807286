<script>
	import { onMount } from 'svelte';
	export let xAlignment = 'left';
	export let aspectRatio = 1 / 2;
	let element;
	let x = 0;
	let y = 0;
	let w = 0;
	let h = 0;
	let mouseDown = false;
	let rect;
	onMount(() => {
		w = element.clientWidth;
		h = element.clientHeight;
		if (aspectRatio != 0) {
			if (aspectRatio > 1) {
				w = parseInt(w * aspectRatio, 10);
				h = parseInt(h, 10);
			} else {
				w = parseInt(w, 10);
				h = parseInt(h / aspectRatio, 10);
			}
		} else {
			w = parseInt(w, 10);
			h = parseInt(h, 10);
		}
		element.style.width = `${w}px`;
		element.style.height = `${h}px`;
	});

	// Handle the mousedown event
	// that's triggered when user drags the resizer
	const mouseDownHandler = function (e) {
		if (!element) return;
		mouseDown = true;
		// Get the current mouse position
		x = e.clientX;
		y = e.clientY;
		rect = element.getBoundingClientRect();
		const styles = getComputedStyle(element);
		w = parseInt(styles.width, 10);
		h = parseInt(styles.height, 10);
	};

	const mouseMoveHandler = function (e) {
		if (mouseDown) {
			if (aspectRatio != 0) {
				const dx = xAlignment === 'left' ? e.clientX - x : x - e.clientX;
				const dy = e.clientY - y;

				let _w = (h + dy) * aspectRatio;
				let _h = h + dy;
				if (_h < window.innerHeight - rect.y) {
					element.style.width = `${_w}px`;
					element.style.height = `${_h}px`;
				}
			} else {
				const dx = xAlignment === 'left' ? e.clientX - x : x - e.clientX;
				const dy = e.clientY - y;
				element.style.width = `${w + dx}px`;
				element.style.height = `${h + dy}px`;
			}
		}
	};
	const mouseUpHandler = function () {
		mouseDown = false;
	};
	document.addEventListener('mouseup', mouseUpHandler);
</script>

<svelte:window on:mousemove={mouseMoveHandler} />

<div class="resizable z-50" id="resizeMe" bind:this={element}>
	<slot />
	<div class={`resizer ${xAlignment !== 'none' ? (xAlignment === 'left' ? 'resizer-r' : 'resizer-l') : ''}`} on:mouseup={mouseUpHandler} on:mousedown={mouseDownHandler} role="none" />
	<div class="resizer resizer-b" on:mouseup={mouseUpHandler} on:mousedown={mouseDownHandler} role="none" />
</div>

<style lang="postcss">
	.resizable {
		position: relative;
		align-items: center;
		display: flex;
		justify-content: center;
		height: 16rem;
		width: 16rem;
	}
	.resizer {
		position: absolute;
	}

	/* Placed at the right side */
	.resizer-r {
		cursor: col-resize;
		height: 100%;
		right: 0;
		top: 0;
		width: 25px;
	}

	/* Placed at the right side */
	.resizer-l {
		cursor: col-resize;
		height: 100%;
		left: 0;
		width: 25px;
	}

	/* Placed at the bottom side */
	.resizer-b {
		bottom: 0;
		cursor: row-resize;
		height: 25px;
		left: 0;
		width: 100%;
	}

	/* Optional styles */
	.container {
		min-height: 32rem;
		min-width: 32rem;
		padding: 1rem;
	}
</style>
