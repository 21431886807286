import { Modify } from 'ol/interaction.js';
import { Polygon } from 'ol/geom.js';
import { RectangleUtils, PointUtils, GeometryUtils, Utils } from '@Utils/Utils.js';
import { never, platformModifierKeyOnly, primaryAction } from 'ol/events/condition.js';

export class EditFrameTool {
	translate;
	modify;
	map;
	source;
	vector;
	shiftKeyPressed;
	constructor(map, source, vector) {
		this.map = map;
		this.vector = vector;
		let _this = this;
		document.addEventListener('keydown', (e) => {
			_this.shiftKeyPressed = e.shiftKey;
		});
		document.addEventListener('keyup', (e) => {
			_this.shiftKeyPressed = e.shiftKey;
		});
		this.source = source;
		const defaultStyle = new Modify({ source: source }).getOverlay().getStyleFunction();

		let modify = new Modify({
			source: source,
			condition: function (event) {
				return primaryAction(event) && !platformModifierKeyOnly(event);
			},
			deleteCondition: never,
			insertVertexCondition: never,
			style: function (feature) {
				feature.get('features').forEach(function (modifyFeature) {
					const modifyGeometry = modifyFeature.get('modifyGeometry');
					if (!modifyGeometry) {
						return;
					}
					let fixedRatio = modifyFeature.get('fixedRatio');
					const point = feature.getGeometry().getCoordinates();
					let modifyPoint = modifyGeometry.point;
					if (!modifyPoint) {
						// save the initial geometry and vertex position
						modifyPoint = point;
						modifyGeometry.point = modifyPoint;
						modifyGeometry.geometry0 = modifyGeometry.geometry;
						// get anchor and minimum radius of vertices to be used
						const result = GeometryUtils.calculateCenter(modifyGeometry.geometry0);
						modifyGeometry.center = result.center;
						modifyGeometry.minRadius = result.minRadius;
					}
					let i = 0;
					let midPoint = false;
					let points = [];
					let currPoint = i;
					const allPoints = modifyFeature.getGeometry().getCoordinates()[0];
					for (let p of allPoints) {
						if ((p[0] == point[0], p[1] == point[1])) {
							midPoint = i % 2 != 0;
							currPoint = i;
							if (midPoint) {
								points.push(allPoints[i - 1]);
								points.push(allPoints[i + 1]);
							}
							break;
						}
						i++;
					}
					if (!midPoint || fixedRatio) {
						let centerIndex;
						let center;
						if (!_this.shiftKeyPressed) {
							centerIndex = (currPoint + (allPoints.length - 1) / 2) % (allPoints.length - 1);
							center = allPoints[centerIndex];
						} else {
							center = modifyGeometry.center;
						}
						const minRadius = modifyGeometry.minRadius;
						let dx, dy;
						dx = modifyPoint[0] - center[0];
						dy = modifyPoint[1] - center[1];
						const initialRadius = Math.sqrt(dx * dx + dy * dy);
						if (initialRadius > minRadius) {
							const initialAngle = Math.atan2(dy, dx);
							dx = point[0] - center[0];
							dy = point[1] - center[1];
							const currentRadius = Math.sqrt(dx * dx + dy * dy);
							if (currentRadius > 0) {
								const currentAngle = Math.atan2(dy, dx);
								const geometry = modifyGeometry.geometry0.clone();
								geometry.scale(currentRadius / initialRadius, undefined, center);
								geometry.rotate(currentAngle - initialAngle, center);
								modifyGeometry.geometry = geometry;
							}
						}
						return;
					}
					if (midPoint) {
						const center = modifyGeometry.center;
						let dx, dy;
						dx = modifyPoint[0] - center[0];
						dy = modifyPoint[1] - center[1];
						dx = allPoints[i - 1][0] - allPoints[i + 1][0];
						dy = allPoints[i - 1][1] - allPoints[i + 1][1];
						const initialAngle = Math.atan2(dy, dx);
						let c = PointUtils.getDistance(point[0], point[1], modifyPoint[0], modifyPoint[1]);
						let distance = c;
						let increasing = false;
						let coords = [];
						let temp = [];

						for (let gp in allPoints) {
							if (gp % 2 === 0 || gp == allPoints.length - 1) {
								temp.push(allPoints[gp]);
							}
						}
						if (i == 1) {
							let mp = PointUtils.getMidpoint(temp[2], temp[3]);
							let distanceToOtherSideCurrent = PointUtils.getDistance(point[0], point[1], mp[0], mp[1]);
							let distanceToOtherSideInitial = PointUtils.getDistance(modifyPoint[0], modifyPoint[1], mp[0], mp[1]);
							if (distanceToOtherSideInitial > distanceToOtherSideCurrent) {
								increasing = false;
							} else {
								increasing = true;
							}
							distance = distance * (increasing === true ? 1 : -1);
							coords.push(PointUtils.movePointAtAngle(temp[0], initialAngle - Utils.degreesToRadians(180), distance));
							coords.push(PointUtils.movePointAtAngle(temp[1], initialAngle - Utils.degreesToRadians(180), distance));
							coords.push(temp[2]);
							coords.push(temp[3]);
							coords.push(PointUtils.movePointAtAngle(temp[4], initialAngle - Utils.degreesToRadians(180), distance));
						} else if (i == 3) {
							let mp = PointUtils.getMidpoint(temp[3], temp[4]);
							let distanceToOtherSideCurrent = PointUtils.getDistance(point[0], point[1], mp[0], mp[1]);
							let distanceToOtherSideInitial = PointUtils.getDistance(modifyPoint[0], modifyPoint[1], mp[0], mp[1]);
							if (distanceToOtherSideInitial > distanceToOtherSideCurrent) {
								increasing = false;
							} else {
								increasing = true;
							}
							distance = distance * (increasing === true ? -1 : 1);
							coords.push(temp[0]);
							coords.push(PointUtils.movePointAtAngle(temp[1], initialAngle, distance));
							coords.push(PointUtils.movePointAtAngle(temp[2], initialAngle, distance));
							coords.push(temp[3]);
							coords.push(temp[4]);
						} else if (i == 5) {
							let mp = PointUtils.getMidpoint(temp[0], temp[1]);
							let distanceToOtherSideCurrent = PointUtils.getDistance(point[0], point[1], mp[0], mp[1]);
							let distanceToOtherSideInitial = PointUtils.getDistance(modifyPoint[0], modifyPoint[1], mp[0], mp[1]);
							if (distanceToOtherSideInitial > distanceToOtherSideCurrent) {
								increasing = false;
							} else {
								increasing = true;
							}
							distance = distance * (increasing === true ? 1 : -1);
							coords.push(temp[0]);
							coords.push(temp[1]);
							coords.push(PointUtils.movePointAtAngle(temp[2], initialAngle - Utils.degreesToRadians(180), distance));
							coords.push(PointUtils.movePointAtAngle(temp[3], initialAngle - Utils.degreesToRadians(180), distance));
							coords.push(temp[4]);
						} else if (i == 7) {
							let mp = PointUtils.getMidpoint(temp[1], temp[2]);
							let distanceToOtherSideCurrent = PointUtils.getDistance(point[0], point[1], mp[0], mp[1]);
							let distanceToOtherSideInitial = PointUtils.getDistance(modifyPoint[0], modifyPoint[1], mp[0], mp[1]);
							if (distanceToOtherSideInitial > distanceToOtherSideCurrent) {
								increasing = false;
							} else {
								increasing = true;
							}
							distance = distance * (increasing === true ? -1 : 1);
							coords.push(PointUtils.movePointAtAngle(temp[0], initialAngle, distance));
							coords.push(temp[1]);
							coords.push(temp[2]);
							coords.push(PointUtils.movePointAtAngle(temp[3], initialAngle, distance));
							coords.push(PointUtils.movePointAtAngle(temp[4], initialAngle, distance));
						}
						modifyGeometry.geometry = new Polygon([coords]);
					}
				});
				return defaultStyle(feature);
			}
		});

		modify.on('modifystart', function (event) {
			event.features.forEach(function (feature) {
				feature.set('modifyGeometry', { geometry: feature.getGeometry().clone() }, true);
				feature.set('originalGeometry', { geometry: feature.getGeometry().clone() }, true);
			});
		});

		modify.on('modifyend', function (event) {
			event.features.forEach(function (feature) {
				const modifyGeometry = feature.get('modifyGeometry');
				if (modifyGeometry) {
					let coords = modifyGeometry.geometry.getCoordinates()[0];
					if (coords.length != 9) {
						let p1 = new Polygon([RectangleUtils.addMidpointsToRectangle(coords)]);
						feature.setGeometry(p1);
						feature.unset('modifyGeometry', true);
					} else {
						feature.setGeometry(modifyGeometry.geometry);
						feature.unset('modifyGeometry', true);
					}
				}
			});
		});
		this.modify = modify;
		this.map.addInteraction(this.modify);
	}
	remove() {
		this.map.removeInteraction(this.modify);
	}
}
