import { Draw } from 'ol/interaction';
import { Circle, Fill, Stroke, Style } from 'ol/style.js';
import { MultiPoint } from 'ol/geom';
import { Polygon } from 'ol/geom.js';
import { GeometryUtils, Utils } from '@Utils/Utils.js';
import { getElement } from '@Utils/Elements.js';
import { nanoid } from 'nanoid';

export default class DrawElement extends Draw {
	element;
	holeId;
	shiftKeyPressed;
	constructor(elementName, source, props) {
		let holeId = props ? props.holeId : null;
		let alwaysOnTop = props ? props.alwaysOnTop : false;
		let freehand = props ? props.freehand : false;
		let simplify = props ? props.simplify : true;
		simplify = simplify === undefined ? true : simplify;
		let element = getElement(elementName);
		super({
			source: source,
			type: element.tooltype,
			freehand: freehand,
			style: [
				new Style({
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.1)'
					}),
					stroke: new Stroke({
						color: 'rgba(255, 255, 255, 0.4)',
						width: 2
					})
				}),
				new Style({
					geometry: function (feature) {
						let g = feature.getGeometry();
						if (Array.isArray(g.getCoordinates()[0])) {
							return new MultiPoint(g.getCoordinates()[0]);
						}
						return new MultiPoint(g.getCoordinates());
					},
					image: new Circle({
						radius: 3,
						fill: new Fill({
							color: 'white'
						}),
						stroke: new Stroke({
							color: 'rgba(255,255,255,0.5)',
							width: 2
						})
					})
				}),
				new Style({
					geometry: function (feature) {
						let g = feature.getGeometry();
						if (Array.isArray(g.getCoordinates()[0])) {
							return new MultiPoint([g.getCoordinates()[0][0]]);
						}
						return new MultiPoint([g.getCoordinates()[0]]);
					},
					image: new Circle({
						radius: 5,
						fill: new Fill({
							color: 'rgba(49,171,37,1)'
						}),
						stroke: new Stroke({
							color: 'rgba(49,171,37,0.5)',
							width: 2
						})
					})
				})
			]
		});
		let _this = this;
		document.addEventListener('keydown', (e) => {
			_this.shiftKeyPressed = e.shiftKey;
		});
		document.addEventListener('keyup', (e) => {
			_this.shiftKeyPressed = e.shiftKey;
		});
		this.holeId = holeId;
		this.on(
			'drawstart',
			function (evt) {
				evt.feature.set('unfinished', true);
				evt.feature.set('elementName', element.name);
				evt.feature.set('uid', nanoid())
				if (this.holeId) {
					evt.feature.set('holeId', this.holeId);
				}
			}.bind(this)
		);
		this.on(
			'drawend',
			function (evt) {
				if (this._modifyFeature) {
					let props = this._modifyFeature.getProperties();
					delete props.geometry
					evt.feature.setProperties(props, true);
					this._modifyFeature = null
					this._modifyFeatureSource = null
				} else if (element.defaultValues) {
					for (let o in element.defaultValues) {
						evt.feature.set(o, element.defaultValues[o]);
					}
				}
				evt.feature.unset('unfinished');
				let coords = evt.feature.getGeometry().getCoordinates();
				if (element.tooltype == 'Polygon') {
					coords = coords[0]
				}
				if (simplify && _this.shiftKeyPressed || simplify && coords.length > 50) {
					GeometryUtils.simplifyFeature(evt.feature);
				}
				if (alwaysOnTop) {
					let largestIndex = 1;
					for (let f of source.getFeatures()) {
						let i = f.get('order');
						if (i > largestIndex) {
							largestIndex = i;
						}
					}
					evt.feature.set('order', largestIndex + 1);
				} else {
					let placeover = evt.feature.get('placeabove')
					let index = element.zIndex
					if (placeover) {
						let el = getElement(placeover)
						index = el.zIndex + 1
					}
					evt.feature.set('order', index);
				}
				if (element.tooltype == 'Polygon') {
					if (!GeometryUtils.isClosed(evt.feature.getGeometry().getCoordinates())) {
						let coords = evt.feature.getGeometry().getCoordinates()[0];
						coords.push(coords[0]);
						evt.feature.setGeometry(new Polygon([coords]));
					}
				} else if (element.tooltype == 'LineString') {

				} else if (element.name.includes('tree')) {
					//let randomTree = Utils.getRandomInt(1, 5)
					let randomSize = Utils.getRandomInt(20, 30);
					if (element.name.includes('small')) {
						randomSize = Utils.getRandomInt(5, 10);
					}
					let randomRotation = Utils.getRandomInt(0, 359);
					evt.feature.set('textureSize', randomSize);
					evt.feature.set('textureRotation', randomRotation);
					evt.feature.set('textureRef', 'tree' + 1);
				} else if (element.name.includes('icon')) {
					if (props.iconName) {
						evt.feature.set('iconName', props.iconName);
					}
				}
			}.bind(this)
		);
	}
}
