<script>
	import { createEventDispatcher, getContext } from 'svelte';
	import DraggableComponent from '@Components/DraggableComponent.svelte';
	import { getElement } from '@Utils/Elements.js';
	import { getIconHtml } from '@Utils/Utils.js';

	const { getEditorCanvas } = getContext('editor');
	const editorCanvas = getEditorCanvas();
	const dispatch = createEventDispatcher();

	/** @type {VectorSource} */
	let featureSource = null;
	$: frame = null;
	$: features = null;
	$: selected = null;
	$: isHidden = true;

	const onElementSelected = (feature) => {
		if (feature && feature.feature) {
			selected = feature.feature;
		} else {
			selected = null;
		}
	};

	const scrollFeatureIntoView = (id) => {
		const el = document.getElementById(id);
		if (!el) return;
		el.scrollIntoView({
			behavior: 'smooth'
		});
	};

	const onAddFeature = ({ feature }) => {
		features = [...[feature].concat(features).sort((a, b) => (a.get('elementName') > b.get('elementName') ? 1 : -1))];
		scrollFeatureIntoView(features[0].ol_uid);
		dispatch('update');
	};

	const onRemoveFeature = ({ feature }) => {
		features = features.filter((f) => f.ol_uid !== feature.ol_uid);
		dispatch('update');
	};
	const onFrameFocused = (data) => {
		if (featureSource) {
			featureSource.un('addfeature', onAddFeature);
			featureSource.un('removefeature', onRemoveFeature);
		}
		if (data) {
			frame = data.feature;
			featureSource = data.source;
			features = [];
			features = data.source.getFeatures().sort((a, b) => (a.get('elementName') > b.get('elementName') ? 1 : -1));
			isHidden = false;
			featureSource.on('addfeature', onAddFeature);
			featureSource.on('removefeature', onRemoveFeature);
		} else {
			frame = null;
			isHidden = true;
		}
	};
	editorCanvas.on('elementSelected', onElementSelected);
	editorCanvas.on('frameFocused', onFrameFocused);
	function getTitle(item) {
		let el = getElement(item.get('elementName'));
		if (el) {
			return el.title;
		}
		return 'Undefined??';
	}
	function hasIcon(item) {
		let el = getElement(item.get('elementName'));
		return el && el.icon;
	}
	function editFrame() {
		if (frame) {
			editorCanvas.selectFeature(frame, true);
			selected = frame;
		}
	}
	const onSelectFeature = (item) => {
		editorCanvas.selectFeature(item);
	};
</script>

<DraggableComponent yAlignment="bottom" additionStyles={isHidden ? 'hidden' : ''} initialX="10" initialY="40">
	<div class="text-left w-full font-bold p-2 pt-0 text-sm">Hole elements</div>
	<ul class="text-xs w-40 max-h-400 min-h-[200px] bg-neutral-200 dark:bg-bigsteel-900/20">
		{#if frame}
			<li class="flex justify-between items-center cursor-move overflow-hidden menu-item">
				<div class="pl-2 pr-2 pt-1 pb-1 w-full flex flex-row justify-between" class:item-selected={selected && frame.ol_uid === selected.ol_uid} on:click={editFrame} role="button" tabindex="0" on:keydown on:keypress>
					<span class="flex items-center">
						<span class={`flex-grow ${isHidden && 'line-through'}`}>Outline</span>
					</span>
					<span class="flex {selected && frame.ol_uid === selected.ol_uid ? 'default-icon-mask-color-selected' : 'default-icon-mask-color'}">
						{@html getIconHtml('EditFrame')}
					</span>
				</div>
			</li>
		{/if}
		{#if features && features.length > 0}
			{#each features as item}
				<li class="flex justify-between items-center cursor-move overflow-hidden menu-item">
					<div class="pl-2 pr-2 pt-1 pb-1 w-full flex flex-row justify-between" class:item-selected={selected && item.ol_uid === selected.ol_uid} on:click={onSelectFeature(item)} role="button" tabindex="0" on:keydown on:keypress>
						<span class="flex items-center">
							<span class={`flex-grow ${isHidden && 'line-through'}`}>{getTitle(item)}</span>
						</span>
						{#if hasIcon(item)}
							<span class="flex {selected && item.ol_uid === selected.ol_uid ? 'default-icon-mask-color-selected' : 'default-icon-mask-color'}">
								{@html getIconHtml(getElement(item.get('elementName')).icon)}
							</span>
						{/if}
					</div>
				</li>
			{/each}
		{/if}
	</ul>
</DraggableComponent>

<style class="postcss">
	.item-selected {
		background-color: theme('colors.blue.500');
		color: white;
		font-weight: bold;
	}
	.menu-item {
		transition: background-color 0.2s;
	}
	.menu-item:hover {
		background-color: theme('colors.blue.200/30%');
	}
</style>
