export class GuideController {
	listeners = {};
	constructor(editorCanvas, controller) {
		this.editorCanvas = editorCanvas;
		let g = localStorage.getItem('guide');
		if (g) {
			this.progress = JSON.parse(g);
			if (!localStorage.getItem('guide-current-step')) {
				localStorage.setItem('guide-current-step', this.getFirstUnfinished());
			} else if (!this.progress.addOutline && this.currentStep() != 'chooseMap') {
				localStorage.setItem('guide-current-step', 'addOutline');
			}
		} else {
			this.reset();
		}
		this.controller = controller;
		let _this = this;
		controller.on('fetchCompleted', function (data) {
			_this.update(data.holes);
		});
		controller.on('alertsUpdated', function (holes) {
			_this.update(holes);
		});
		editorCanvas.on('sourceUpdated', function () {
			_this.update(controller.data.holes);
		});
		editorCanvas.on('frameFocused', function (data) {
			if (data) {
				_this.done('clickHole');
			}
		});
		this.canGoBack = this.getCanGoBack();
		this.canGoForward = this.getCanGoForward();
		this.currentStepCompleted = this.isDone();
	}
	on(event, cb) {
		if (!this.listeners[event]) {
			this.listeners[event] = [];
		}
		this.listeners[event].push(cb);
	}
	off(event, cb) {
		if (!this.listeners[event]) {
			return;
		}
		this.listeners[event] = this.listeners[event].filter((c) => c != cb);
	}
	getFirstUnfinished() {
		for (let p in this.progress) {
			if (!this.progress[p]) {
				return p;
			}
		}
		return null;
	}
	showGuide() {
		if (localStorage.getItem('guide-skipped')) {
			return false;
		}
		let c = this.currentStep();
		return !c || c === 'null' ? false : true;
	}
	trigger(event, val1, val2, val3) {
		if (this.haltEvents) {
			return;
		}
		if (!this.listeners[event]) {
			return;
		}
		for (let l of this.listeners[event]) {
			l(val1, val2, val3);
		}
	}
	equal(p1, p2) {
		for (let p in p1) {
			if (p1[p] !== p2[p]) {
				return false;
			}
		}
		return true;
	}
	isCurrentStep(key) {
		return key == this.currentStep();
	}
	currentStep() {
		return localStorage.getItem('guide-current-step');
	}
	getNextStep(key) {
		if (!key) {
			key = this.currentStep();
		}
		let keys = Object.keys(this.progress);
		let i = keys.indexOf(key);
		if (i == -1) {
			return keys[0];
		}
		if (i == keys.length - 1) {
			return null;
		}
		return keys[i + 1];
	}
	isDone(key) {
		if (!key) {
			key = this.currentStep();
		}
		if (!key) {
			return true;
		}
		return this.progress[key];
	}
	goBack() {
		let c = this.currentStep();
		let keys = Object.keys(this.progress);
		let i = keys.indexOf(c);
		if (i <= 0) {
			return;
		}
		localStorage.setItem('guide-current-step', keys[i - 1]);
		this.postUpdate();
	}
	goForward() {
		let c = this.currentStep();
		let keys = Object.keys(this.progress);
		let i = keys.indexOf(c);
		if (i == keys.length - 1) {
			return;
		}
		localStorage.setItem('guide-current-step', keys[i + 1]);
		this.postUpdate();
	}
	done(key) {
		if (!key) {
			key = this.currentStep();
			if (!key) {
				return;
			}
		}
		this.progress[key] = true;
		localStorage.setItem('guide', JSON.stringify(this.progress));
		this.postUpdate();
	}
	completeGuide() {
		this.skip();
	}
	gotIt(key) {
		if (!key) {
			key = this.currentStep();
			if (!key) {
				return;
			}
		}
		this.progress[key] = true;
		localStorage.setItem('guide-current-step', this.getNextStep(key));
		localStorage.setItem('guide', JSON.stringify(this.progress));
		this.postUpdate();
	}
	skip() {
		localStorage.setItem('guide-skipped', true);
		this.postUpdate();
	}
	postUpdate() {
		this.currentStepCompleted = this.isDone();
		this.canGoBack = this.getCanGoBack();
		this.canGoForward = this.getCanGoForward();
		this.trigger('progressUpdated', this.progress, this.currentStep(), this.showGuide());
	}
	getCanGoBack() {
		return this.currentStep() != 'chooseMap';
	}
	getCanGoForward() {
		return this.currentStep() != 'findWiki';
	}
	update(holes) {
		let p = this.getCurrentProgress(holes);
		if (!this.equal(p, this.progress)) {
			this.progress = p;
			if (this.isDone()) {
				this.done();
			} else {
				localStorage.setItem('guide', JSON.stringify(this.progress));
				this.postUpdate();
			}
		}
	}
	reset() {
		this.progress = {
			chooseMap: false,
			addOutline: false,
			clickHole: false,
			addHoleFocus: false,
			placeTeeCut: false,
			placeTee: false,
			placeGreen: false,
			mapElements: false,
			findWiki: false
		};
		localStorage.setItem('guide-current-step', 'chooseMap');
		localStorage.setItem('guide', JSON.stringify(this.progress));
		localStorage.removeItem('guide-skipped');
		this.postUpdate();
	}
	getCurrentProgress(holes) {
		let progress = {
			chooseMap: this.progress.chooseMap,
			addOutline: false,
			clickHole: this.progress.clickHole,
			addHoleFocus: false,
			placeTeeCut: false,
			placeTee: false,
			placeGreen: false,
			mapElements: false,
			findWiki: this.progress.findWiki
		};
		for (let h of holes) {
			if (h.mapInfo) {
				if (!progress.addHoleFocus && h.mapInfo.hasFocus) {
					progress.addHoleFocus = true;
				}
				if (!progress.addOutline && h.mapInfo.hasOutline) {
					progress.addOutline = true;
				}
				if (!progress.placeTeeCut && h.mapInfo.hasTee) {
					progress.placeTeeCut = true;
				}
				if (!progress.placeGreen && h.mapInfo.hasGreen) {
					progress.placeGreen = true;
				}
				if (!progress.placeTee) {
					for (let t in h.mapInfo.tee) {
						if (h.mapInfo.tee[t]) {
							progress.placeTee = true;
						}
					}
				}
			}
		}
		progress.mapElements = this.editorCanvas.hasSurroundings();
		return progress;
	}
}
