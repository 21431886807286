<script>
	import { guiDimensionsStore } from '@Stores/stores.js';

	export let visible = true;
	export let additionStyles = '';
	export let xAlignment = 'left';
	export let yAlignment = 'top';
	export let initialX = 40;
	export let initialY = 10;
	let holder;
	let x = initialX;
	let y = initialY;
	let offsetX = 0;
	let offsetY = 0;

	let translateX = 0;
	let translateY = 0;
	let handleHeight = 0;
	$: {
		let t = holder ? getComputedStyle(holder).transform : null;
		if (holder) {
			holder.style.visibility = visible ? 'visible' : 'hidden';
			holder.style.opacity = visible ? 1 : 0;
		}
		if (t) {
			const match = t.match(/matrix\(([^)]+)\)/);
			if (match) {
				const matrix = match[1].split(', ').map(parseFloat);
				translateX = matrix[4];
				translateY = matrix[5];
			}
		}
	}

	const onMouseDown = (e) => {
		if (!isMouseDown) {
			let boundingRect = e.target.getBoundingClientRect();
			handleHeight = boundingRect.height;
			offsetX = xAlignment === 'left' ? e.clientX - boundingRect.left : boundingRect.right - e.clientX;
			offsetY = yAlignment === 'top' ? e.clientY - boundingRect.top : boundingRect.bottom - e.clientY;
		}
		isMouseDown = true;
	};
	const onMouseUp = () => {
		isMouseDown = false;
	};

	let isMouseDown = false;
	const updatePosition = (e) => {
		if (isMouseDown) {
			let boundingRect = holder.getBoundingClientRect();
			const currX = xAlignment === 'left' ? e.clientX || e.touches[0].clientX : $guiDimensionsStore.w - e.clientX;
			const currY = yAlignment === 'top' ? e.clientY || e.touches[0].clientY : $guiDimensionsStore.h - e.clientY - boundingRect.height + handleHeight;
			x = currX - offsetX - translateX;
			y = currY - offsetY - translateY;
		}
	};

	const onResize = (e) => {
		let boundingRect = element.getBoundingClientRect();
		offsetX = e.clientX - boundingRect.left;
		offsetY = e.clientY - boundingRect.top;
		const currX = xAlignment === 'left' ? e.clientX || e.touches[0].clientX : e.clientX - $guiDimensionsStore.w;
		const currY = yAlignment === 'top' ? e.clientY || e.touches[0].clientY : e.clientY - $guiDimensionsStore.h;
		x = currX - offsetX - translateX;
		y = currY - offsetY - translateY;
	};
</script>

<svelte:window on:mousemove={updatePosition} on:on:resize={onResize} />

<div bind:this={holder} style="{yAlignment}: {y}px; {xAlignment}: {x}px;" class={`draggable-component overflow-hidden w-fit h-fit rounded-xl box-shadow box text-center ${additionStyles}`}>
	<div style.user-select="none" class="p-2 flex flex-row w-full h-8 pointer-events-auto cursor-grab justify-center items-center" on:mousedown|preventDefault={onMouseDown} on:mouseup|preventDefault={onMouseUp} role="none">
		<div class="w-14 h-1 bg-bigsteel-300 dark:bg-bigsteel-600 rounded-full pointer-events-none" />
	</div>
	<div class="rounded-b-xl overflow-hidden">
		<slot />
	</div>
</div>

<style lang="postcss">
	.draggable-component {
		z-index: 50;
		position: absolute;
	}
</style>
