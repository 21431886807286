import pack1_backgroundUrl from '@Assets/TexturePack1/Background.png';
import pack1_bunkerUrl from '@Assets/TexturePack1/Bunker.png';
import pack1_fairwayUrl from '@Assets/TexturePack1/Fairway.png';
import pack1_forestUrl from '@Assets/TexturePack1/Forest.png';
import pack1_grassBunkerUrl from '@Assets/TexturePack1/GrassBunker.png';
import pack1_greenUrl from '@Assets/TexturePack1/Green.png';
import pack1_hillUrl from '@Assets/TexturePack1/Hill.png';
import pack1_mountainUrl from '@Assets/TexturePack1/Mountain.png';
import pack1_pathUrl from '@Assets/TexturePack1/Path.png';
import pack1_roadUrl from '@Assets/TexturePack1/Road.png';
import pack1_roughUrl from '@Assets/TexturePack1/Rough.png';
import pack1_shadowUrl from '@Assets/TexturePack1/Shadow.png';
import pack1_streamUrl from '@Assets/TexturePack1/Stream.png';
import pack1_teeUrl from '@Assets/TexturePack1/Tee.png';
import pack1_tree1Url from '@Assets/TexturePack1/Tree1.png';
import pack1_Tree1 from '@Assets/TexturePack1/Tree1.svg?raw';
import pack1_tree2Url from '@Assets/TexturePack1/Tree2.png';
import pack1_Tree2 from '@Assets/TexturePack1/Tree2.svg?raw';
import pack1_tree3Url from '@Assets/TexturePack1/Tree3.png';
import pack1_Tree3 from '@Assets/TexturePack1/Tree3.svg?raw';
import pack1_tree4Url from '@Assets/TexturePack1/Tree4.png';
import pack1_Tree4 from '@Assets/TexturePack1/Tree4.svg?raw';
import pack1_tree5Url from '@Assets/TexturePack1/Tree5.png';
import pack1_Tree5 from '@Assets/TexturePack1/Tree5.svg?raw';
import pack1_tree6Url from '@Assets/TexturePack1/Tree6.png';
import pack1_Tree6 from '@Assets/TexturePack1/Tree6.svg?raw';
import pack1_tree7Url from '@Assets/TexturePack1/Tree7.png';
import pack1_Tree7 from '@Assets/TexturePack1/Tree7.svg?raw';
import pack1_treeCrownShadowUrl from '@Assets/TexturePack1/TreeCrownShadow.png';
import pack1_wasteAreaUrl from '@Assets/TexturePack1/WasteArea.png';
import pack1_waterUrl from '@Assets/TexturePack1/Water.png';
import pack1_Tree1_svg from '@Assets/TexturePack1/Tree1.svg';
import pack1_Tree2_svg from '@Assets/TexturePack1/Tree2.svg';
import pack1_Tree3_svg from '@Assets/TexturePack1/Tree3.svg';
import pack1_Tree4_svg from '@Assets/TexturePack1/Tree4.svg';
import pack1_Tree5_svg from '@Assets/TexturePack1/Tree5.svg';
import pack1_Tree6_svg from '@Assets/TexturePack1/Tree6.svg';
import pack1_Tree7_svg from '@Assets/TexturePack1/Tree7.svg';

import pack2_backgroundUrl from '@Assets/TexturePack2/Background.png';
import pack2_bunkerUrl from '@Assets/TexturePack2/Bunker.png';
import pack2_fairwayUrl from '@Assets/TexturePack2/Fairway.png';
import pack2_forestUrl from '@Assets/TexturePack2/Forest.png';
import pack2_grassBunkerUrl from '@Assets/TexturePack2/GrassBunker.png';
import pack2_greenUrl from '@Assets/TexturePack2/Green.png';
import pack2_hillUrl from '@Assets/TexturePack2/Hill.png';
import pack2_mountainUrl from '@Assets/TexturePack2/Mountain.png';
import pack2_pathUrl from '@Assets/TexturePack2/Path.png';
import pack2_roadUrl from '@Assets/TexturePack2/Road.png';
import pack2_roughUrl from '@Assets/TexturePack2/Rough.png';
import pack2_shadowUrl from '@Assets/TexturePack2/Shadow.png';
import pack2_streamUrl from '@Assets/TexturePack2/Stream.png';
import pack2_teeUrl from '@Assets/TexturePack2/Tee.png';
import pack2_tree1Url from '@Assets/TexturePack2/Tree1.png';
import pack2_Tree1 from '@Assets/TexturePack2/Tree1.svg?raw';
import pack2_tree2Url from '@Assets/TexturePack2/Tree2.png';
import pack2_Tree2 from '@Assets/TexturePack2/Tree2.svg?raw';
import pack2_tree3Url from '@Assets/TexturePack2/Tree3.png';
import pack2_Tree3 from '@Assets/TexturePack2/Tree3.svg?raw';
import pack2_tree4Url from '@Assets/TexturePack2/Tree4.png';
import pack2_Tree4 from '@Assets/TexturePack2/Tree4.svg?raw';
import pack2_tree5Url from '@Assets/TexturePack2/Tree5.png';
import pack2_Tree5 from '@Assets/TexturePack2/Tree5.svg?raw';
import pack2_tree6Url from '@Assets/TexturePack2/Tree6.png';
import pack2_Tree6 from '@Assets/TexturePack2/Tree6.svg?raw';
import pack2_tree7Url from '@Assets/TexturePack2/Tree7.png';
import pack2_Tree7 from '@Assets/TexturePack2/Tree7.svg?raw';
import pack2_Tree1_svg from '@Assets/TexturePack2/Tree1.svg';
import pack2_Tree2_svg from '@Assets/TexturePack2/Tree2.svg';
import pack2_Tree3_svg from '@Assets/TexturePack2/Tree3.svg';
import pack2_Tree4_svg from '@Assets/TexturePack2/Tree4.svg';
import pack2_Tree5_svg from '@Assets/TexturePack2/Tree5.svg';
import pack2_Tree6_svg from '@Assets/TexturePack2/Tree6.svg';
import pack2_Tree7_svg from '@Assets/TexturePack2/Tree7.svg';
import pack2_treeCrownShadowUrl from '@Assets/TexturePack2/TreeCrownShadow.png';
import pack2_wasteAreaUrl from '@Assets/TexturePack2/WasteArea.png';
import pack2_waterUrl from '@Assets/TexturePack2/Water.png';

export const Pack1 = {
	greenUrl: pack1_greenUrl,
	teeUrl: pack1_teeUrl,
	fairwayUrl: pack1_fairwayUrl,
	roughUrl: pack1_roughUrl,
	waterUrl: pack1_waterUrl,
	bunkerUrl: pack1_bunkerUrl,
	forestUrl: pack1_forestUrl,
	treeCrownShadowUrl: pack1_treeCrownShadowUrl,
	shadowUrl: pack1_shadowUrl,
	streamUrl: pack1_streamUrl,
	pathUrl: pack1_pathUrl,
	roadUrl: pack1_roadUrl,
	mountainUrl: pack1_mountainUrl,
	hillUrl: pack1_hillUrl,
	wasteAreaUrl: pack1_wasteAreaUrl,
	backgroundUrl: pack1_backgroundUrl,
	grassBunkerUrl: pack1_grassBunkerUrl,
	tree1Url: pack1_tree1Url,
	tree2Url: pack1_tree2Url,
	tree3Url: pack1_tree3Url,
	tree4Url: pack1_tree4Url,
	tree5Url: pack1_tree5Url,
	tree6Url: pack1_tree6Url,
	tree7Url: pack1_tree7Url,
	tree1SvgUrl: pack1_Tree1_svg,
	tree2SvgUrl: pack1_Tree2_svg,
	tree3SvgUrl: pack1_Tree3_svg,
	tree4SvgUrl: pack1_Tree4_svg,
	tree5SvgUrl: pack1_Tree5_svg,
	tree6SvgUrl: pack1_Tree6_svg,
	tree7SvgUrl: pack1_Tree7_svg,
	trees: {
		tree1: pack1_Tree1,
		tree2: pack1_Tree2,
		tree3: pack1_Tree3,
		tree4: pack1_Tree4,
		tree5: pack1_Tree5,
		tree6: pack1_Tree6,
		tree7: pack1_Tree7
	},
	svgTrees: {
		tree1: pack1_Tree1_svg,
		tree2: pack1_Tree2_svg,
		tree3: pack1_Tree3_svg,
		tree4: pack1_Tree4_svg,
		tree5: pack1_Tree5_svg,
		tree6: pack1_Tree6_svg,
		tree7: pack1_Tree7_svg
	}
};
export const Pack2 = {
	greenUrl: pack2_greenUrl,
	teeUrl: pack2_teeUrl,
	fairwayUrl: pack2_fairwayUrl,
	roughUrl: pack2_roughUrl,
	waterUrl: pack2_waterUrl,
	bunkerUrl: pack2_bunkerUrl,
	forestUrl: pack2_forestUrl,
	treeCrownShadowUrl: pack2_treeCrownShadowUrl,
	shadowUrl: pack2_shadowUrl,
	streamUrl: pack2_streamUrl,
	pathUrl: pack2_pathUrl,
	roadUrl: pack2_roadUrl,
	mountainUrl: pack2_mountainUrl,
	hillUrl: pack2_hillUrl,
	wasteAreaUrl: pack2_wasteAreaUrl,
	backgroundUrl: pack2_backgroundUrl,
	grassBunkerUrl: pack2_grassBunkerUrl,
	tree1Url: pack2_tree1Url,
	tree2Url: pack2_tree2Url,
	tree3Url: pack2_tree3Url,
	tree4Url: pack2_tree4Url,
	tree5Url: pack2_tree5Url,
	tree6Url: pack2_tree6Url,
	tree7Url: pack2_tree7Url,
	tree1SvgUrl: pack2_Tree1_svg,
	tree2SvgUrl: pack2_Tree2_svg,
	tree3SvgUrl: pack2_Tree3_svg,
	tree4SvgUrl: pack2_Tree4_svg,
	tree5SvgUrl: pack2_Tree5_svg,
	tree6SvgUrl: pack2_Tree6_svg,
	tree7SvgUrl: pack2_Tree7_svg,
	trees: {
		tree1: pack2_Tree1,
		tree2: pack2_Tree2,
		tree3: pack2_Tree3,
		tree4: pack2_Tree4,
		tree5: pack2_Tree5,
		tree6: pack2_Tree6,
		tree7: pack2_Tree7
	}
};
