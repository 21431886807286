let cursors = {}
cursors['greenback'] = "/cursors/GreenBack.svg";
cursors['greencenter'] = "/cursors/GreenCenter.svg";
cursors['greenfront'] = "/cursors/GreenFront.svg";
cursors['teeblack'] = "/cursors/TeeBlack.svg";
cursors['teeblue'] = "/cursors/TeeBlue.svg";
cursors['teegreenwhite'] = "/cursors/TeeGreenWhite.svg";
cursors['teeorange'] = "/cursors/TeeOrange.svg";
cursors['teered'] = "/cursors/TeeRed.svg";
cursors['teewhite'] = "/cursors/TeeWhite.svg";
cursors['teeyellow'] = "/cursors/TeeYellow.svg";
cursors['teeyellowblack'] = "/cursors/TeeYellowBlack.svg";
cursors['addframe'] = "/cursors/AddFrame.svg";
cursors['bigtree'] = "/cursors/BigTree.svg";
cursors['bunker'] = "/cursors/Bunker.svg";
cursors['denseforest'] = "/cursors/DenseForest.svg";
cursors['fairway'] = "/cursors/Fairway.svg";
cursors['focus'] = "/cursors/Focus.svg";
cursors['grassbunker'] = "/cursors/GrassBunker.svg";
cursors['green'] = "/cursors/Green.svg";
cursors['mountain'] = "/cursors/Mountain.svg";
cursors['path'] = "/cursors/Path.svg";
cursors['road'] = "/cursors/Road.svg";
cursors['rough'] = "/cursors/Rough.svg";
cursors['smalltree'] = "/cursors/SmallTree.svg";
cursors['sparseforest'] = "/cursors/SparseForest.svg";
cursors['stream'] = "/cursors/Stream.svg";
cursors['tee'] = "/cursors/Tee.svg";
cursors['wastearea'] = "/cursors/Wastearea.svg";
cursors['water'] = "/cursors/Water.svg";

export default function getIconUrl(name) {
	if (!name) {
		return '';
	}
	return cursors[name.toLowerCase()];
}
