import { getCenter, getHeight, getWidth } from 'ol/extent.js';
import { LineString } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';
import cspline from 'ol-ext/render/Cspline';
import { getElement } from '@Utils/Elements.js';
import { simplify } from './SimplifyShape.js';
import DefaultRenderProperties from '@Assets/RenderProperties.json';

let icons = {};
icons['GreenBack'] = '/icons/GreenBack.svg';
icons['GreenCenter'] = '/icons/GreenCenter.svg';
icons['GreenFront'] = '/icons/GreenFront.svg';
icons['TeeBlack'] = '/icons/TeeBlack.svg';
icons['TeeBlue'] = '/icons/TeeBlue.svg';
icons['TeeGreenWhite'] = '/icons/TeeGreenWhite.svg';
icons['TeeOrange'] = '/icons/TeeOrange.svg';
icons['TeeRed'] = '/icons/TeeRed.svg';
icons['TeeWhite'] = '/icons/TeeWhite.svg';
icons['TeeYellow'] = '/icons/TeeYellow.svg';
icons['TeeYellowBlack'] = '/icons/TeeYellowBlack.svg';
icons['AddFrame'] = '/icons/addFrame.svg';
icons['Bigtree'] = '/icons/bigtree.svg';
icons['Bunker'] = '/icons/bunker.svg';
icons['Course'] = '/icons/course.svg';
icons['Cuts'] = '/icons/cuts.svg';
icons['Denseforest'] = '/icons/denseforest.svg';
icons['Draghandle'] = '/icons/drag-handle.svg';
icons['EditFrame'] = '/icons/editFrame.svg';
icons['Eye'] = '/icons/eye.svg';
icons['Fairway'] = '/icons/fairway.svg';
icons['Focus'] = '/icons/focus.svg';
icons['FocusFrame'] = '/icons/focusFrame.svg';
icons['Game'] = '/icons/game.svg';
icons['Grassbunker'] = '/icons/grassbunker.svg';
icons['Green'] = '/icons/green.svg';
icons['Hazards'] = '/icons/hazards.svg';
icons['Hide'] = '/icons/hide.svg';
icons['Map'] = '/icons/map.svg';
icons['Mountain'] = '/icons/mountain.svg';
icons['Other'] = '/icons/other.svg';
icons['Path'] = '/icons/path.svg';
icons['Road'] = '/icons/road.svg';
icons['Rough'] = '/icons/rough.svg';
icons['Save'] = '/icons/save.svg';
icons['Smalltree'] = '/icons/smalltree.svg';
icons['Sparseforest'] = '/icons/sparseforest.svg';
icons['Stream'] = '/icons/stream.svg';
icons['Tee'] = '/icons/tee.svg';
icons['Teepoint'] = '/icons/teepoint.svg';
icons['Trash'] = '/icons/trash.svg';
icons['Triangle'] = '/icons/triangle.svg';
icons['Unlock'] = '/icons/unlock.svg';
icons['Vegetation'] = '/icons/vegetation.svg';
icons['Wastearea'] = '/icons/wastearea.svg';
icons['Water'] = '/icons/water.svg';
icons['Ruler'] = '/icons/Ruler.svg';
icons['Close'] = '/icons/Close.svg';
icons['Help'] = '/icons/Help.svg';
icons['Export'] = '/icons/Export.svg';
icons['ArrowUp'] = '/icons/arrowUp.svg';
icons['GoogleMapsLogo'] = '/icons/GoogleMapsLogo.svg';
icons['BingMapsLogo'] = '/icons/BingMapsLogo.svg';
icons['AzureMapsLogo'] = '/icons/AzureMapsLogo.svg';
icons['Union'] = '/icons/Union.svg';
icons['ArrowUpTail'] = "/icons/ArrowUpTail.svg"
icons['WC'] = '/icons/WC.svg';
icons['NextTee'] = "/icons/NextTee.svg"
icons['MenuWC'] = "/icons/MenuWC.svg"
icons['MenuNextTee'] = "/icons/MenuNextTee.svg"
icons['DirectionalCircle'] = "/icons/DirectionalCircle.svg"
icons['DirectionalChevron'] = "/icons/DirectionalChevron.svg"
icons['DirectionalChevronRounded'] = "/icons/DirectionalChevronRounded.svg"
icons['DropdownChevronDown'] = "/icons/DropdownChevronDown.svg"
icons['FrameDirectionArrow'] = '/icons/FrameDirectionArrow.svg';
icons['Simplify'] = '/icons/Simplify.svg';

let renderPropertyKeys = Object.keys(DefaultRenderProperties);
export function setRenderProperties(properties) {
	if (localStorage) {
		localStorage.setItem('renderProperties', JSON.stringify(properties));
	}
}

export function getRenderProperties(props, useDefault) {
	if (import.meta.env.VITE_ADVANCED_TOOLING_ENABLED === 'false') {
		useDefault = true;
	}
	if (useDefault !== true && localStorage) {
		let properties = localStorage.getItem('renderProperties');
		if (properties) {
			let p = JSON.parse(properties);
			p = mergeProperties(p, DefaultRenderProperties)
			p = mergeProperties(props, p);
			if (p.width) {
				p.width = parseInt(p.width);
			}
			if (p.height) {
				p.height = parseInt(p.height);
			}
			return p;
		}
	}
	let p = JSON.parse(JSON.stringify(DefaultRenderProperties));
	p = mergeProperties(props, p);
	if (p.width) {
		p.width = parseInt(p.width);
	}
	if (p.height) {
		p.height = parseInt(p.height);
	}
	return p;
}
export function mergeProperties(n, o) {
	let result = {};
	for (let k of renderPropertyKeys) {
		result[k] = n[k] ?? o[k];
	}
	return result;
}
export function createCanvas(width, height, tag) {
	if (typeof document === 'undefined') {
		let c =  new OffscreenCanvas(width, height);
		c.oncontextlost = function (event) {
			console.error(`${tag}: context lost`);
		}
		c.oncontextrestored = function(event) {
			console.info(`${tag}: context restored`);
		}
		return c
	}
	let c = document.createElement('canvas');
	c.width = width;
	c.height = height;
	return c;
}

export class RectangleUtils {
	static addMidpointsToRectangle(coordinates) {
		let m1 = PointUtils.getMidpoint(coordinates[0], coordinates[1]);
		let m2 = PointUtils.getMidpoint(coordinates[1], coordinates[2]);
		let m3 = PointUtils.getMidpoint(coordinates[2], coordinates[3]);
		let m4 = PointUtils.getMidpoint(coordinates[3], coordinates[0]);
		let newCoordinates = [];
		newCoordinates.push(coordinates[0]);
		newCoordinates.push(m1);
		newCoordinates.push(coordinates[1]);
		newCoordinates.push(m2);
		newCoordinates.push(coordinates[2]);
		newCoordinates.push(m3);
		newCoordinates.push(coordinates[3]);
		newCoordinates.push(m4);
		newCoordinates.push(coordinates[4]);
		return newCoordinates;
	}
}
export class PointUtils {
	static getPointDistance(p1, p2) {
		let y = p2[1] - p1[1];
		let x = p2[0] - p1[0];
		return Math.sqrt(x * x + y * y);
	}
	static getAngle(p1, p2) {
		let cx = p1[0]
		let cy = p1[1];
		let ex = p2[0];
		let ey = p2[1];
		var dy = ey - cy;
		var dx = ex - cx;
		return Math.atan2(dy, dx);
	}
	static getDistance(x1, y1, x2, y2) {
		let y = y2 - y1;
		let x = x2 - x1;
		return Math.sqrt(x * x + y * y);
	}
	static getMidpoint(p1, p2) {
		return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2];
	}
	static movePointAtAngle(point, angle, distance) {
		return [point[0] + Math.sin(angle) * distance, point[1] - Math.cos(angle) * distance];
	}
}
function lengthSquare(X, Y) {
	let xDiff = X[0] - Y[0];
	let yDiff = X[1] - Y[1];
	return xDiff * xDiff + yDiff * yDiff;
}
export class GeometryUtils {
	static getAngles(vertices1, vertices2, vertices3) {
		let a2 = lengthSquare(vertices2, vertices3);
		let b2 = lengthSquare(vertices1, vertices3);
		let c2 = lengthSquare(vertices1, vertices2);

		// Length of sides be a, b, c
		let a = Math.sqrt(a2);
		let b = Math.sqrt(b2);
		let c = Math.sqrt(c2);
		// From Cosine law
		let alpha = Math.acos((b2 + c2 - a2) / (2 * b * c));
		let beta = Math.acos((a2 + c2 - b2) / (2 * a * c));
		let gamma = Math.acos((a2 + b2 - c2) / (2 * a * b));
		return {
			a: a,
			b: b,
			c: c,
			alpha: alpha,
			beta: beta,
			gamma: gamma
		};
	}
	static calculateCenter(geometry) {
		let center, coordinates, minRadius;
		const type = geometry.getType();
		if (type === 'Polygon') {
			let x = 0;
			let y = 0;
			let i = 0;
			coordinates = geometry.getCoordinates()[0].slice(1);
			coordinates.forEach(function (coordinate) {
				x += coordinate[0];
				y += coordinate[1];
				i++;
			});
			center = [x / i, y / i];
		} else if (type === 'LineString') {
			center = geometry.getCoordinateAt(0.5);
			coordinates = geometry.getCoordinates();
		} else {
			center = getCenter(geometry.getExtent());
		}
		let sqDistances;
		if (coordinates) {
			sqDistances = coordinates.map(function (coordinate) {
				const dx = coordinate[0] - center[0];
				const dy = coordinate[1] - center[1];
				return dx * dx + dy * dy;
			});
			minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
		} else {
			minRadius = Math.max(getWidth(geometry.getExtent()), getHeight(geometry.getExtent())) / 3;
		}
		return {
			center: center,
			coordinates: coordinates,
			minRadius: minRadius,
			sqDistances: sqDistances
		};
	}
	static getPerimeter(coordinates) {
		let perimeter = 0;
		for (let i = 0; i < coordinates.length - 1; i++) {
			let p1 = coordinates[i];
			let p2 = coordinates[i + 1];
			perimeter += PointUtils.getPointDistance(p1, p2);
		}
		return perimeter;
	}
	static cleanCoordinateDuplicates(coordinates, i) {
		if (i + 2 > coordinates.length) {
			return coordinates;
		}
		let p1 = coordinates[i];
		let p2 = coordinates[i + 1];
		if (p1[0] == p2[0] && p1[1] == p2[1]) {
			coordinates.splice(i + 1, 1);
			return GeometryUtils.cleanCoordinateDuplicates(coordinates, i);
		}
		return GeometryUtils.cleanCoordinateDuplicates(coordinates, i + 1);
	}
	static cleanCoordinatesByAngle(angle, coordinates, i) {
		if (i + 3 > coordinates.length) {
			return coordinates;
		}
		let p1 = coordinates[i];
		let p2 = coordinates[i + 1];
		let p3 = coordinates[i + 2];
		let angles = GeometryUtils.getAngles(p1, p2, p3);
		if (angles.gamma < angle) {
			coordinates.splice(i + 1, 1);
			return GeometryUtils.cleanCoordinatesByAngle(angle, coordinates, i);
		}
		return GeometryUtils.cleanCoordinatesByAngle(angle, coordinates, i + 1);
	}
	static cleanCoordinatesByDistance(dist, coordinates, i) {
		if (i + 2 > coordinates.length) {
			return coordinates;
		}
		let p1 = coordinates[i];
		let p2 = coordinates[i + 1];
		if (PointUtils.getPointDistance(p1, p2) < dist) {
			coordinates.splice(i + 1, 1);
			return GeometryUtils.cleanCoordinatesByDistance(dist, coordinates, i);
		}
		return GeometryUtils.cleanCoordinatesByDistance(dist, coordinates, i + 1);
	}
	static cleanCoordinates(coordinates, amount) {
		amount = amount === undefined ? 100 : amount;
		amount = amount / 100;
		let a = Utils.degreesToRadians(20 * amount);
		let perimeter = GeometryUtils.getPerimeter(coordinates);
		let d = (perimeter / 35) * amount; // 30 for more effect

		coordinates = GeometryUtils.cleanCoordinateDuplicates(coordinates, 0);
		coordinates = GeometryUtils.cleanCoordinatesByAngle(a, coordinates, 0);
		coordinates = GeometryUtils.cleanCoordinatesByDistance(d, coordinates, 0);
		return coordinates;
	}
	static simplifyFeatureByReducingPoints(feature) {
		let geom = feature.getGeometry();
		let type = geom.getType();
		let coordinates = geom.getCoordinates();

		if (type == 'Polygon') {
			if (coordinates[0].length <= 4) {
				return;
			}
			let coords = coordinates.filter((v, i) => i % 2);
			if (!this.isClosed(coords)) {
				coords.push(coords[0]);
			}
			feature.setGeometry(new Polygon([coords]));
		} else if (type == 'LineString') {
			if (coordinates.length <= 3) {
				return;
			}
			let coords = coordinates.filter((v, i) => i % 2);
			feature.setGeometry(new LineString(coords));
		}
	}
	static simplifyFeature(feature, amount) {
		amount = 1;
		let geom = feature.getGeometry();
		let type = geom.getType();
		let coordinates = geom.getCoordinates();

		if (type == 'Polygon') {
			if (coordinates[0].length <= 4) {
				return;
			}
			let coords = coordinates[0]
			coords = GeometryUtils.cleanCoordinateDuplicates(coords, 0);
			coords = simplify(coords, amount, true);
			// let perimeter = GeometryUtils.getPerimeter(coords);
			// let d = (perimeter / 35) * amount; // 30 for more effect
			// coords = GeometryUtils.cleanCoordinatesByDistance(d, coords, 0);
			//coords = GeometryUtils.cleanCoordinates(coordinates[0], 60)
			if (!this.isClosed(coords)) {
				coords.push(coords[0]);
			}
			feature.setGeometry(new Polygon([coords]));
		} else if (type == 'LineString') {
			if (coordinates.length <= 3) {
				return;
			}
			let coords = coordinates
			coords = GeometryUtils.cleanCoordinateDuplicates(coords, 0);
			coords = simplify(coords, amount, true);
			// let perimeter = GeometryUtils.getPerimeter(coords);
			// let d = (perimeter / 35) * amount; // 30 for more effect
			// coords = GeometryUtils.cleanCoordinatesByDistance(d, coords, 0);
			//let coords = coordinates.filter((v, i) => i % 2)
			feature.setGeometry(new LineString(coords));
		}
	}
	static removeDuplicateAdjacentCoordinates(arr, index) {
		if (index === undefined) {
			index = 0;
		}
		let stopped = false;
		for (let a = index; a < arr.length; a++) {
			let b;
			if (a + 1 < arr.length) {
				b = a + 1;
			} else {
				return arr;
			}
			if (arr[a][0] === arr[b][0] && arr[a][1] === arr[b][1]) {
				arr.splice(a, 1);
				stopped = true;
				break;
			}
		}
		if (stopped) {
			return GeometryUtils.removeDuplicateAdjacentCoordinates(arr, index);
		}
		return arr;
	}
	static close(feature) {
		let coords = feature.getGeometry().getCoordinates()[0];
		if (this.isClosed(coords)) {
			return;
		}
		coords.push(coords[0]);
		feature.setGeometry(new Polygon([coords]));
	}
	static closePath(coordinates) {
		if (this.isClosed(coordinates)) {
			return coordinates;
		}
		coordinates.push(coordinates[0]);
		return coordinates;
	}
	static isClosed(coords) {
		return coords[0][0] == coords[coords.length - 1][0] && coords[0][1] == coords[coords.length - 1][1];
	}
	static getCSplineGeometry(feature) {
		let el = getElement(feature.get('elementName'));
		let rounded = feature.get('rounded');
		if (rounded === undefined) {
			rounded = el.defaultValues.rounded;
		}
		if (!rounded) {
			return feature.getGeometry();
		}
		let g = feature.getGeometry()
		let type = g.getType();
		if (type === 'Polygon') {
			let coordinates = this.getCSplineCoordinates(feature);
			return new Polygon([coordinates]);
		} else if (type === 'LineString') {
			let coordinates = this.getCSplineCoordinates(feature);
			return new LineString(coordinates);
		} else {
			return feature.getGeometry();
		}
	}
	static getCSplineCoordinates(feature, coordinates) {
		if (!coordinates) {
			let g = feature.getGeometry();
			let type = g.getType();
			if (type === 'Polygon') {
				coordinates = g.getCoordinates()[0];
			} else if (type === 'LineString') {
				coordinates = g.getCoordinates();
			} else {
				return coordinates;
			}
		}
		let element = getElement(feature.get('elementName'));
		let defaultValues = {
			tension: 0.5,
			normalize: false,
			pointsPerSeg: 50
		};
		if (element && element.defaultValues) {
			defaultValues = element.defaultValues;
		}
		let rounded = feature.get('rounded');
		if (rounded === undefined) {
			rounded = defaultValues.rounded;
		}
		if (rounded) {
			let tension = Number(feature.get('tension'));
			let normalize = feature.get('normalize');
			let pointsPerSeg = feature.get('pointsPerSeg');
			if (!tension) {
				tension = defaultValues.tension;
			}
			if (!normalize) {
				normalize = defaultValues.normalize;
			}
			if (!pointsPerSeg) {
				pointsPerSeg = defaultValues.pointsPerSeg;
			}
			return cspline(coordinates, { tension: tension, pointsPerSeg: pointsPerSeg, normalize: normalize });
		}
		return coordinates;
	}
	static doesPolygonsIntersect(feature1, feature2) {
		if (feature1.getGeometry().getType() !== 'Polygon' || feature2.getGeometry().getType() !== 'Polygon') {
			return false
		}
		let coordinatesFeature1 = feature1.getGeometry().getCoordinates()[0];
		let coordinatesFeature2 = feature2.getGeometry().getCoordinates()[0];
		for (let c of coordinatesFeature1) {
			if (feature2.getGeometry().intersectsCoordinate(c)) {
				return true
			}
		}
		for (let c of coordinatesFeature2) {
			if (feature1.getGeometry().intersectsCoordinate(c)) {
				return true
			}
		}
		return false;
	}
	static isContainedInPolygon(feature1, feature2) {
		let geometry = feature1.getGeometry()
		for (let c of feature2.getGeometry().getCoordinates()[0]) {
			if (!geometry.intersectsCoordinate(c)) {
				return false
			}
		}
		return true
	}
	static getIntersectingPolygons(feature, allElements, options = { checkOrder: true, ignoreFocus: true, matchElementName: false }) {
		if (feature.getGeometry().getType() !== 'Polygon') {
			return []
		}
		let arr = []
		for (let f of allElements) {
			if (f === feature) {
				continue;
			}
			if (f.get('uid') === feature.get('uid')) {
				continue
			}
			if (f.getGeometry().getType() !== 'Polygon') {
				continue
			}
			if (options.checkOrder && f.get('order') < feature.get('order')) {
				continue;
			}
			if (options.ignoreFocus && f.get('elementName') === 'focus') {
				continue
			}
			if (options.matchElementName && f.get('elementName') !== feature.get('elementName')) {
				continue
			}
			if (GeometryUtils.doesPolygonsIntersect(f, feature) === false) {
				continue;
			}
			arr.push(f);
		}
		return arr
	}
}
export class Utils {
	static degreesToRadians(degrees) {
		return (degrees * Math.PI) / 180;
	}
	static radiansToDegrees(radians) {
		return (radians * 180) / Math.PI;
	}
	static getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min);
	}
	static getRandomBoolean() {
		return Math.random() < 0.5;
	}
}

export const debounce = function (func, timeout = 100) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
};

function useMask(name) {
	let names = ['TeeBlack', 'TeeBlue', 'TeeGreenWhite', 'TeeOrange', 'TeeRed', 'TeeWhite', 'TeeYellow', 'TeeYellowBlack', 'GreenBack', 'GreenCenter', 'GreenFront', "WC", 'NextTee'];
	return !names.includes(name);
}
export function getPlaceableIconList() {
	return ['NextTee', 'WC']
}
export function getDirectionalIconList() {
	return ['DirectionalCircle', 'DirectionalChevron', 'DirectionalChevronRounded'];
}
export function getIconUrl(name) {
	return icons[name];
}
export function getIconHtml(name, classes, width, height) {
	if (!name) {
		return '';
	}
	let mw = "20px"
	if (classes === undefined) {
		classes = '';
	}
	if (width === undefined) {
		width = 'auto';
	} else {
		width = width + 'px';
		mw = width
	}
	if (height === undefined) {
		height = '20px';
	} else {
		height = height + 'px';
	}
	if (useMask(name) === true) {
		return `<div style='height: ${height}; width: ${width}; min-width: ${mw}; mask-image: url(${getIconUrl(name)})' class="svg-mask ${classes}"></div>`;
	}
	return `<div style='height: ${height}; width: ${width}; min-width: ${mw}; background-image: url(${getIconUrl(name)})' class="svg-background ${classes}"></div>`;
}
