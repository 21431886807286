import { nanoid } from 'nanoid';

export default class AsyncHoleRenderer {
	worker;
	constructor() {
		if (window.Worker) {
			this.worker = new Worker(new URL('./HoleRendererWorker.js', import.meta.url), {
				type: 'module'
			});
		} else {
			console.error("Your browser doesn't support web workers.");
		}
	}
	async cancel() {
		let id = nanoid(50);
		let message = {
			action: 'cancel',
			id: id
		};
		let m = JSON.stringify(message);
		this.worker.postMessage(m);
		return new Promise((resolve, reject) => {
			let func = (e) => {
				if (e.data.id === id) {
					this.worker.removeEventListener('message', func);
					resolve();
				}
			};
			this.worker.addEventListener('message', func);
		});
	
	}
	async updateProperties(properties) {
		let id = nanoid(50);
		let message = {
			action: 'updateProperties',
			id: id,
			payload: properties
		};
		let m = JSON.stringify(message);
		this.worker.postMessage(m);
		return new Promise((resolve, reject) => {
			let func = (e) => {
				if (e.data.id === id) {
					this.worker.removeEventListener('message', func);
					if (e.data.cancelled) {
						reject('cancelled');
					} else {
						resolve(e.data.payload);
					}
				}
			};
			this.worker.addEventListener('message', func);
		});
	}
	async update(tasks) {
		let id = nanoid(50);
		let message = {
			action: 'update',
			id: id,
			payload: tasks
		};
		let m = JSON.stringify(message);
		this.worker.postMessage(m);
		return new Promise((resolve, reject) => {
			let func = (e) => {
				if (e.data.id === id) {
					this.worker.removeEventListener('message', func);
					if (e.data.cancelled) {
						reject('cancelled');
					} else {
						resolve(e.data.payload);
					}
				}
			};
			this.worker.addEventListener('message', func);
		});
	}
	async render(data, properties, reset) {
		let id = nanoid(50);
		let message = {
			action: 'render',
			id: id,
			payload: data,
			properties: properties,
			reset: reset
		};
		let m = JSON.stringify(message);
		this.worker.postMessage(m);
		return new Promise((resolve, reject) => {
			let func = (e) => {
				if (e.data.id === id) {
					this.worker.removeEventListener('message', func);
					if (e.data.cancelled) {
						reject('cancelled');
					} else {
						resolve(e.data.payload);
					}
				}
			};
			this.worker.addEventListener('message', func);
		});
	}
}
